import React from 'react'
import { useRedirection } from '../../../hooks';

const LeftPanelDashboard = () => {
  const { redirectToDashboard } = useRedirection();
  return (
    <div onClick={()=>redirectToDashboard()}>Dashboard</div>
  )
}

export default LeftPanelDashboard
