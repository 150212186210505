import { LeftPanel } from '../LeftNavPanel'
import DashboardContent from './DashboardContent'

const Dashboard = () => {
  document.title = 'Dashboard - BranchLab';
  return (
    <>
      <LeftPanel defaultSelectedKeys={['2']}/>
      <DashboardContent/>
    </>
  )
}

export default Dashboard