import { FC } from 'react';
import { Table, TableProps, type TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';
import {} from '../../../../hooks';
import { IPixelListingParam } from '../../../../models/interfaces';
import { SortOrder } from 'antd/es/table/interface';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

interface DataType {
  key: number;
  title: string;
  pixel_domain: string;
  created_on: string;
  updated_on: string;
}

const PixelListTable: FC<{
  pixelData: any;
  isLoading: boolean;
  checkedList: string[];
  paginationAndSortingData: IPixelListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IPixelListingParam>
  >;
}> = ({
  pixelData,
  isLoading,
  checkedList,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'key',
      key: 'key',
      render: (text) => <>{'#' + text}</>,
      className: 'genericTableID',
      width: '100px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink to={'/update-pixel/' + record.key} title={text}>
          {text}
        </NavLink>
      ),
      width: 'auto',
      sorter: true,
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Domain',
      dataIndex: 'pixel_domain',
      key: 'pixel_domain',
      width: 'auto',
      sorter: true,
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Created',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY'),
      width: '150px',
      sorter: true,
      // defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
    {
      title: 'Modified',
      dataIndex: 'updated_on',
      key: 'updated_on',
      render: (text) => (
        <>{text ? dayjs.utc(text).local().format('M/D/YYYY') : '---'}</>
      ),
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;
    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter ? getSortOrder(sorter.order) : sortOrder,
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    else return order
  };

  function CreateNewColumns() {
    let newColumns = columns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
      defaultSortOrder:
        item.key === paginationAndSortingData.sortColumn
          ? (paginationAndSortingData.sortOrder as SortOrder)
          : undefined,
    }));
    return checkedList.length > 0 ? newColumns : columns;
  }

  return (
    <Table
      columns={CreateNewColumns()}
      dataSource={pixelData?.rows ?? []}
      loading={isLoading}
      onChange={handleChange}
      pagination={{
        current: paginationAndSortingData.pageNumber,
        total: pixelData?.total_row_count ?? 0,
        showSizeChanger: true,
        pageSize: paginationAndSortingData.rowsPerPage,
        locale: {
          items_per_page: 'per page',
        },
      }}
      className={`antTableElement pixelListingTable audienceListTable ${
        isLoading ? ' hideNoDataLabel' : ''
      }`}
      sortDirections={['ascend', 'descend', 'ascend']}
    />
  );
};

export default PixelListTable;
