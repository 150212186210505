import { useNavigate, useLocation } from 'react-router-dom';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfAllMessageAreCreatedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useCheckIfDataModifiedStore,
  useMasterTargetingElementsStore,
} from '../stores';
import {
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  ICheckIfAllMessageAreCreatedState,
  ICheckIfDataModifiedState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IGlobalFeatureState,
  IHcpFeatureState,
  IMasterTargetingDDState,
} from '../models/interfaces';
import {
  useAudienceSetUp,
  useAudienceSets,
  useCareGiverFeatureSet,
  useGlobalFeatureSet,
  useHcpFeatureSet,
} from '../stores/audienceSets.store';

const useRedirection = () => {
  const navigate = useNavigate();

  const { setIsInitiatedSaving, setIfUpdated, setIfUpdatedDataPopulated } =
      useFeatureSetDataCheckStore((state: IFeatureSetDataCheckState) => state),
    { setIsEnableSaveForEachSet } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    { setSavingDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    {
      setTargetingDDElementsForFs,
    } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { setIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { setIfAllMessageAreCreated } = useCheckIfAllMessageAreCreatedStore(
      (state: ICheckIfAllMessageAreCreatedState) => state
    ),
    { setAudiencePageNumber } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    ),
    { setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    {
      setDefineFeatureSet,
      setDefineFeatureCount,
      setDefineFeatureData,
      setFirstFeatureNumber,
      setSelectedSavingOpt
    } = useAudienceSets((state: IAudienceState) => state),
    { setGlobalFeatureSet, setGlobalTargeting } = useGlobalFeatureSet(
      (state: IGlobalFeatureState) => state
    ),
    { setHcpFeatureSet, setHcpTargeting} = useHcpFeatureSet(
      (state: IHcpFeatureState) => state
    ),
    { setCaregiverFeatureSet, setCaregiverTargeting } = useCareGiverFeatureSet(
      (state: ICaregiverFeatureState) => state
    );

    const location = useLocation();

  const resetSavingStates = () => {
    setUpdatedDataSet({});
    setAudiencePageNumber(1);
    setIfDataModified(false);
    setIfAllMessageAreCreated({});
    setIsEnableSaveForEachSet({});
    setSavingDataSet({});
    setDefineFeatureData([]);
    setDefineFeatureSet({});
    setCaregiverTargeting([])
    setSelectedSavingOpt('')
    setCaregiverFeatureSet({
      caregiverGender: null,
      caregiverAge: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
    })
    setHcpTargeting([]);
    setHcpFeatureSet({
      selectedProviderSpecialty: [],
      customNPIList: [],
      selectedProviderTaxonomy: [],
    })
    setGlobalTargeting([]);
    setGlobalFeatureSet({
      timeFrame: null,
      dateRange: null,
      patientGender: null,
      selectedAge: [],
      selectedRaceAndEthnicity: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
      language: [],
    });
    setFirstFeatureNumber(0);
    setDefineFeatureCount(0);
    setTargetingDDElementsForFs([])
  };

  const resetUpdatingStates = () => {
    setIfUpdated(false);
    setUpdatedDataSet({});
    setIfUpdatedDataPopulated(false);
  };

  const resetAllStates = () => {
    setIfDataModified(false);
    setIsInitiatedSaving(false);
    setIfAllMessageAreCreated({});
    setIsDependentTargetingSelectedForEachSet({});
    setSavingDataSet({});
    setUpdatedDataSet({});
    setIfUpdatedDataPopulated(false);
  };

  const redirectAudienceListing = () => {
    if(!location.pathname.includes('view-audience')){
      resetSavingStates()
    }
    navigate('/audiences');
  };

  const redirectAudienceUpdate = (id: number) => {
    navigate('/update-audience/' + id);
  };

  const redirectPixelListing = () => {
    navigate('/pixel-listing');
  };

  const redirectPixelSetup = (id?: number) => {
    if (id) {
      navigate('/update-pixel/' + id);
    } else {
      navigate('/create-Pixel');
    }
  };

  const redirectAccountSettings = () => {
    navigate('/account-settings');
  };

  const redirectAudienceSetup = (id?: number) => {
    resetSavingStates();
    if (id) {
      navigate('/audience/' + id + 'define');
    } else {
      navigate('/audience/create');
    }
  };

  const redirectAudienceInsights = (id: number) => {
    navigate('/audience/' + id + '/review');
  }

  const redirectAccountSetup = (id?: number) => {
    navigate('/add-account');
  };

  const redirectAccountListing = () => {
    navigate('/accounts');
  };

  const redirectAudienceView = (id: number) => {
    navigate('/audience/' + id + '/define');
  };

  const redirectToDashboard = () => {
    navigate('/');
  }

  const redirectAddResearch = (id?: number) => {
    resetSavingStates();
    if (id) {
      navigate('/update-research/' + id);
    } else {
      navigate('/add-research');
    }
  };

  const redirectAudienceActivations = () => {
    resetAllStates();
    resetUpdatingStates();
    navigate('/activations');
  };

  const redirectResearch = () => {
    resetSavingStates();
    resetUpdatingStates();
    navigate('/research');
  };

  const redirectAudienceActivationSetup = (audId: number) => {
    resetAllStates();
    resetSavingStates();
    resetUpdatingStates();
    navigate('/audience/' + audId + '/activate');
  };
  const redirectLogin = () => {
    resetSavingStates();
    resetUpdatingStates();
    navigate('/login');
  };

  return {
    redirectLogin,
    resetAllStates,
    redirectPixelSetup,
    redirectPixelListing,
    redirectAudienceSetup,
    redirectAudienceView,
    redirectAudienceListing,
    redirectAudienceUpdate,
    redirectAudienceInsights,
    redirectAudienceActivations,
    redirectAudienceActivationSetup,
    redirectResearch,
    redirectToDashboard,
    redirectAccountSettings,
    redirectAccountListing,
    redirectAccountSetup,
    redirectAddResearch,
  };
};

export default useRedirection;
