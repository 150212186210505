import React, { FC, useEffect, useRef } from 'react';
import anychart from 'anychart';

const BoxWhiskerChartType: FC<{
  data: any[];
  heading: string;
}> = ({ data, heading }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    // Only create and draw the chart if data exists
    if (data && data.length > 0) {
      const defaultData = data.map((item) => {
        const range = item.high - item.low;
        const q1 = item.low + range * 0.25;
        const q3 = item.low + range * 0.75;

        return {
          x: item.x,
          low: item.low,
          q1: q1,
          median: Number(item.median.toFixed(1)),
          q3: q3,
          high: item.high,
          percent: item.value,
        };
      });

      const chart = anychart.box();

      const series = chart.box(defaultData);
      series.whiskerWidth(50);
      series.medianStroke(series.fill(), 2);
      series.outlierMarkers({
        size: 0,
      });
      
      // Custom tooltip configuration to exclude q1 and q3
      series.tooltip().format(function(this) {
        return (
          `Youngest: ${this.lowest}\n` +
          `Median: ${this.median}\n` +
          `Oldest: ${this.highest}\n` 
        );
      });
      
      chart.legend(false);
      chart.palette(['#1B58D0']);
      
      chart.container(chartContainer.current);
      chart.draw();

      return () => {
        chart.dispose();
      };
    }
  }, [data]);

  // Render a message or placeholder if no data
  if (!data || data.length === 0) {
    return (
      <div className="chartWrapper genericChartWrap">
        <p>{heading}</p>
        <div 
          style={{ 
            height: '350px', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
          }}
        >
          No data available
        </div>
      </div>
    );
  }

  return (
    <div className="chartWrapper genericChartWrap">
      <p>{heading}</p>
      <div ref={chartContainer} style={{ height: '350px' }} />
    </div>
  );
};

export default BoxWhiskerChartType;