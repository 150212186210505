import { Table } from 'antd';
import { ActivateData } from './DashboardContent';
import { FC } from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'react-router-dom';


const CommonActivationTable: FC<{
  activationData: ActivateData;
  heading: string;
  is_activate: boolean;
}> = ({ activationData, heading, is_activate }) => {
  function extractParenthesesContent(inputString: string) {
    const start = inputString.indexOf('(');
    const end = inputString.indexOf(')');
    return start !== -1 && end !== -1
      ? inputString.slice(start + 1, end)
      : inputString;
  }

  const is_estimated_columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink to={'/audience/' + record.key + '/review'} title={text}>
          {text}
        </NavLink>
      )
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'audience_type_id',
      render: (text: string) => extractParenthesesContent(text ?? ''),
    },
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'processed_at',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY')
    },
    {
      title: 'Est. Size',
      dataIndex: 'size',
      key: 'size',
    },
  ];

  const is_activated_columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink to={'/audience/' + record.key + '/activate'} title={text}>
          {text}
        </NavLink>
      )
    },
    {
      title: 'Partner',
      dataIndex: 'partner_name',
      key: 'partner_name',
    },
    {
      title: 'Date',
      dataIndex: 'activated_on',
      key: 'activated_on',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY')
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
    },
  ];

  function formatEstimatedSizes(size: number) {
    return size.toLocaleString();
  }

  function manipulateArray(dataArray: any) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        size: !item.size ? '---' : formatEstimatedSizes(item.size),
      };
    });
  }

  return (
    <div className="commonAudienceTableWrap">
      <div className="sectionHeader">
        <h2>{heading}</h2>
      </div>
      <Table
        dataSource={
          is_activate
            ? manipulateArray(activationData.recent_activate)
            : manipulateArray(activationData.recent_estimate)
        }
        columns={is_activate ? is_activated_columns : is_estimated_columns}
        pagination={false}
        loading={activationData.isLoading}
      />
    </div>
  );
};

export default CommonActivationTable;
