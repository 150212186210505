import React, { useEffect } from 'react';
import anychart from 'anychart';

interface ZipCodeEntry {
  id: string;
  zip3: string;
  state?: string;
  value: number;
  total?: number;
  metadata?: Record<string, unknown>;
}

interface StateData {
  id: string;
  value: number;
}

interface USAZip3MapProps {
  zipData?: ZipCodeEntry[];
  heading?: string;
  colorScheme?: [string, string, string];
}

const groupZipDataByState = (zipData: ZipCodeEntry[]): any => {
  const stateGrouping: { [key: string]: StateData } = {};

  zipData.forEach((entry) => {
    const fullStateId = entry.id;
    if (!stateGrouping[fullStateId]) {
      stateGrouping[fullStateId] = {
        id: fullStateId,
        value: 0,
      };
    }
    const stateEntry = stateGrouping[fullStateId];
    stateEntry.value += entry.value;
  });
  return Object.values(stateGrouping);
};

const USAZip3Map: React.FC<USAZip3MapProps> = ({
  zipData = [],
  heading,
  colorScheme = ['#9AB9F6', '#1B58D0'],
}) => {
  useEffect(() => {
    if (zipData.length === 0) return;

    const map = anychart.map();
    map.geoData('anychart.maps.united_states_of_america');

    const series = map.choropleth(
      zipData.length ? groupZipDataByState(zipData) : []
    );

    const colorScale = anychart.scales.linearColor();
    colorScale.colors(colorScheme);
    series.colorScale(colorScale);

    series.tooltip().format(function (this) {
      return `value: ${this.value.toFixed(1).toLocaleString()}%\n`;
    });

    series.labels().enabled(false);

    const legend = map.legend();
    legend.enabled(false);

    map.container('map-container');
    map.draw();

    // Cleanup
    return () => map.dispose();
  }, [zipData, colorScheme]);

  return (
    <div
      className="chartWrapper genericChartWrap"
      style={{ marginBottom: '20px' }}
    >
      <p>{heading}</p>
      <div id="map-container" style={{ width: '100%', height: '500px' }} />
    </div>
  );
};

export default USAZip3Map;
