/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { LeftPanel } from '..';
import { AudienceListingWrap } from './AudienceListingComponents';
import {
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useCheckIfDataModifiedStore,
} from '../../../stores';
import {
  ICheckIfDataModifiedState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
} from '../../../models/interfaces';

const AudienceListingPage: FC<{}> = () => {
  document.title = 'Audiences - BranchLab';

  const { ifUpdated, setIfUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    );

  useEffect(() => {
    ifDataModified && setIfDataModified(false);

    if (ifUpdated) {
      setIfUpdated(false);
      setUpdatedDataSet({});
    }
  }, []);

  return (
    <>
      <LeftPanel defaultSelectedKeys={['3', '31']} />
      <AudienceListingWrap />
    </>
  );
};

export default AudienceListingPage;
