import { Col, Row } from 'antd';
import React, { FC } from 'react';

interface DataPoint {
  x: string;
  value: string;
}

interface CustomIndexChartProps {
  dimension: string;
  index_data: DataPoint[];
  query_data: DataPoint[];
  heading: string;
  dynamic_classname?: string;
}

const CustomIndexChart: FC<CustomIndexChartProps> = ({
  dimension,
  index_data,
  query_data,
  heading,
  dynamic_classname,
}) => {
  // Determine if we need to split into two columns
  const shouldSplitColumns = query_data.length > 25;

  // Split data into two arrays if needed
  const firstColumnData = shouldSplitColumns
    ? query_data.slice(0, Math.ceil(query_data.length / 2))
    : query_data;

  const secondColumnData = shouldSplitColumns
    ? query_data.slice(Math.ceil(query_data.length / 2))
    : [];

  const renderTable = (data: DataPoint[], isFirstColumn: boolean) => (
    <table
      style={{ marginTop: '0px' }}
      className={`customIndexChartTable ${dynamic_classname}`}
    >
      {isFirstColumn && (
        <thead>
          <tr>
            <th>{`${dimension}`}</th>
            <th>Patient Population</th>
            <th colSpan={2}>Over/Under</th>
            <th>U.S. Population</th>
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((data, index) => {
          const originalIndex = query_data.indexOf(data);
          return (
            <tr key={data.x}>
              <td>{data.x}</td>
              <td>{Number(data.value) + '%'}</td>
              <td style={{ background: '#f6f6f6' }}>
                <span
                  style={{
                    paddingRight: '5px',
                    display: 'inline-block',
                  }}
                >
                  {Number(data.value) -
                    Number(index_data[originalIndex].value) <
                  0
                    ? (
                        Number(data.value) -
                        Number(index_data[originalIndex].value)
                      ).toFixed(2) + '%'
                    : ''}
                </span>
                {Number(data.value) - Number(index_data[originalIndex].value) <
                  0 && (
                  <span
                    className="dynamicColorBlock"
                    style={{
                      background: '#C05F52',
                      width: `${Math.abs(
                        Number(data.value) -
                          Number(index_data[originalIndex].value)
                      )}%`,
                      borderRadius: '4px 0 0 4px',
                    }}
                  ></span>
                )}
              </td>
              <td style={{ background: '#f6f6f6' }}>
                {Number(data.value) - Number(index_data[originalIndex].value) >
                  0 && (
                  <span
                    className="dynamicColorBlock"
                    style={{
                      background: '#B1D1B7',
                      width: `${Math.abs(
                        Number(data.value) -
                          Number(index_data[originalIndex].value)
                      )}%`,
                      borderRadius: '0 4px 4px 0',
                    }}
                  ></span>
                )}
                <span
                  style={{
                    paddingLeft: '5px',
                    display: 'inline-block',
                  }}
                >
                  {Number(data.value) -
                    Number(index_data[originalIndex].value) >
                  0
                    ? (
                        Number(data.value) -
                        Number(index_data[originalIndex].value)
                      ).toFixed(1) + '%'
                    : ''}
                </span>
              </td>
              <td> {Number(index_data[originalIndex].value) + '%'}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <>
      <p className="visualizationTableWrapHeading">{heading}</p>
      <Row gutter={16}>
        {
          shouldSplitColumns ? <>
          <Col span={12}>
          {renderTable(firstColumnData, true)}
          </Col>
         <Col span={12}>
          {shouldSplitColumns && renderTable(secondColumnData, true)}
         </Col>
          </> :
         <Col span={24}>
         {renderTable(firstColumnData, true)}
         </Col>
        }
        {/* <Col>
        {renderTable(firstColumnData, true)}
        </Col>
       <Col>
        {shouldSplitColumns && renderTable(secondColumnData, true)}
       </Col> */}
      </Row>
    </>
  );
};

export default CustomIndexChart;
