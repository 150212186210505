import React, { FC, useEffect, useRef } from 'react';
import anychart from 'anychart';
import { TooltipContext } from '../../../models/interfaces/models.interface';

const BarChartType: FC<{
  Data: any;
  heading: string;
  addOnText?:string;
}> = ({ Data, heading, addOnText= "%" }) => {
  const chartContainer = useRef(null);
  
  useEffect(() => {   
    if (chartContainer.current && Data && Data.length > 0) {
      const chart = anychart.column();
    
      const tooltip = chart.tooltip();

      tooltip.titleFormat(function (this: TooltipContext): string {
        try {
          const date = new Date(this.x);
          if (!isNaN(date.getTime())) {
            return date.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            });
          }
          return String(this.x);
        } catch (e) {
          return String(this.x);
        }
      });

      const COLOR_PALETTE = [
        '#1B58D0',
        '#9AB9F6',
        '#42824F',
        '#B1D1B7',
        '#620093',
        '#C29DD2',
        '#890189',
        '#FFCCFF',
        '#588C00',
        '#C1F666',
      ];

      chart
        .labels()
        .enabled(true)
        .position('center-top')
        .anchor('center-bottom')
        .format('{%x}{groupsSeparator: }');

      tooltip.format(function (this: TooltipContext): string {
        return `${this.value} ${addOnText}`;
      })
      .positionMode('point')
      .position('center-top')
      
      tooltip.background({
        fill: '#272727',
      });

      chart.title(false);

      const xAxis = chart.xAxis();
      xAxis.enabled(false);

      const yAxis = chart.yAxis();
      yAxis.enabled(false);

      chart.xGrid().enabled(false);
      chart.yGrid().enabled(false);
      chart.palette(COLOR_PALETTE);
      if (chart.legend) {
        chart.legend().enabled(false);
      }

      chart.margin(0);

      const series = chart.getSeriesAt(0);
      if (series) {
        series.labels().enabled(false);
      }

      chart.data(Data);
      chart.background().enabled(false);
      chart.container(chartContainer.current);
      chart.draw();
    }
  }, [Data]);

  return (
    <div className="chartWrapper genericChartWrap">
      <p>{heading}</p>
      <div 
        ref={chartContainer} 
        className="histogramElem"
        style={{ 
          minHeight: '350px', 
          display: (!Data || Data.length === 0) ? 'flex' : 'block', 
          justifyContent: 'center', 
          alignItems: 'center',
        }}
      >
        {(!Data || Data.length === 0) && (
          <span className="text-gray-500">No chart data available</span>
        )}
      </div>
    </div>
  );
};

export default BarChartType;