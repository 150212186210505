import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';
import { InputRef, Row, Col, Switch } from 'antd';
import {
  IAudienceSetUpState,
  IEachDropdownElementsType,
  IFeatureSetDataState,
  ILoaderState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  useFeatureSetDataHandlingStore,
  useLoaderStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  CommonElementLabel,
  InputField,
  Single,
} from '../../../../components/sharedComponent';
import { RbacSettings } from '../../../../models/enums/rbacSetting';
import { useAudienceSetUp } from '../../../../stores/audienceSets.store';

const DefaultFeatureSet: FC<{
  selectedAdditionalOpts: boolean;
  setSelectedAdditionalOpts: Dispatch<SetStateAction<boolean>>;
  agencyDDElements: IEachDropdownElementsType[];
  advertiserDDElements: IEachDropdownElementsType[];
  brandDDElements: IEachDropdownElementsType[];
  audienceTypeDDElements: IEachDropdownElementsType[];
  setOrganisationType: Dispatch<
    SetStateAction<'agency' | 'brand' | 'advertiser'>
  >;
  is_type_disabled: boolean
  focusOnTitle?: 0 | 1 | 2;
  disabled?: boolean;
  pixelData: any;
  pixelFieldData: any;
  is_duplicated: boolean;
  handlePixelChange: (value: any) => void;
  openOrganisation: Dispatch<SetStateAction<boolean>>;
}> = ({
  pixelData,
  pixelFieldData,
  brandDDElements,
  agencyDDElements,
  advertiserDDElements,
  audienceTypeDDElements,
  openOrganisation,
  selectedAdditionalOpts,
  setSelectedAdditionalOpts,
  handlePixelChange,
  is_duplicated,
  is_type_disabled,
  setOrganisationType,
  focusOnTitle,
  disabled,
}) => {
  const titleRef = useRef<InputRef>(null);
  const agencyRef = useRef<InputRef>(null);

  const { showPageLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { privilegeSetDDElements } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    {
      advertiser,
      setAdvertiser,
      audienceTitle,
      audienceBrand,
      audienceAgency,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
      selectedAudienceType,
      setSelectedAudienceType,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );
    

  useEffect(() => {
    if (!showPageLoaderState && titleRef.current) {
      setTimeout(() => {
        titleRef.current?.focus({
          cursor: 'end',
        });
      }, 300);
    }
  }, [focusOnTitle, showPageLoaderState]);

  function processFeatureSets(data: any) {
    const { feature_sets } = data;
    const filteredFeatureSets = (feature_sets || [])
      .filter(
        (fs: any) =>
          !fs.targeting.some((target: any) =>
            [20, 6, 7].includes(target.targeting_id)
          )
      )
      .map((fs: any, index: number) => {
        if (fs.is_global) {
          fs.targeting = fs.targeting.filter(
            (target: any) => target.targeting_id !== 19
          );
        }
        return fs;
      });
    data.feature_sets = filteredFeatureSets;
    setUpdatedDataSet(data);
    return data;
  }

  function handleDisable() {
    if (!is_duplicated || selectedAudienceType === 2)
      return true;
    else if (is_duplicated) return false;
  }

  return (
    <div className="defaultFeatureSetSection">
      <Row gutter={24}>
        <Col lg={{ span: 12 }} sm={{ span: 24 }}>
          <InputField
            label="Title"
            isMandatory
            inputPlaceholder="Title"
            onChange={(data: any) => {
              setAudienceTitle(data);
            }}
            defaultValue={audienceTitle}
            props={{ ref: titleRef }}
            inputWrapperClasses={['mb-20']}
          />

          <div className="mb-20">
            <CommonElementLabel label="Audience Type" isMandatory={true} />
            <Single
              options={audienceTypeDDElements}
              allowClear={false}
              placeholder="Select Audience Type"
              onChange={(data: any) => {
                if (Object.keys(updatedDataSet).length > 0) {
                  processFeatureSets(updatedDataSet);
                }
                setSelectedAudienceType(data);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              props={{ value: selectedAudienceType }}
              isDisabled={is_type_disabled ?? false}
            />
          </div>
          <div className="checkboxListWrap">
            <Switch
              checked={selectedAdditionalOpts}
              size="small"
              onChange={setSelectedAdditionalOpts}
              disabled={handleDisable()}
            />
            <p>This is a Caregiver/Household Member Audience</p>
          </div>
        </Col>
        <Col lg={{ span: 12 }} sm={{ span: 24 }}>
          <div className="mb-20">
            <div className="labelAndCTA d-flex">
              <CommonElementLabel label="Agency" isMandatory={true} />
              <button
                onClick={() => {
                  setOrganisationType('agency');
                  openOrganisation(true);
                }}
              >
                Add new
              </button>
            </div>
            <Single
              options={agencyDDElements}
              placeholder="Select Agency"
              onChange={(data: any) => {
                setAudienceAgency(data);
              }}
              isSearchable
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: audienceAgency, ref: agencyRef }}
            />
          </div>
          <div className="mb-20">
            <div className="labelAndCTA d-flex">
              <CommonElementLabel label="Advertiser" isMandatory={true} />
              <button
                onClick={() => {
                  setOrganisationType('advertiser');
                  openOrganisation(true);
                }}
              >
                Add new
              </button>
            </div>
            <Single
              options={advertiserDDElements}
              placeholder="Select Advertiser"
              isSearchable
              onChange={(data: any) => {
                setAdvertiser(data);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: advertiser }}
            />
          </div>
          <div className="mb-20">
            <div className="labelAndCTA d-flex">
              <CommonElementLabel label="Brand" isMandatory={true} />
              <button
                onClick={() => {
                  setOrganisationType('brand');
                  openOrganisation(true);
                }}
              >
                Add new
              </button>
            </div>
            <Single
              options={brandDDElements}
              isSearchable
              placeholder="Select Brand"
              onChange={(data: any) => {
                setAudienceBrand(data);
              }}
              componentSpecificClasses={['checkboxListDropdownElem']}
              allowClear={false}
              props={{ value: audienceBrand }}
            />
          </div>
          <div className="mb-20">
            <CommonElementLabel label="Measurement Pixel" isMandatory={false} />
            <Single
              options={pixelData ?? []}
              placeholder="Choose a Pixel"
              onChange={handlePixelChange}
              allowClear={false}
              props={{
                value:
                  pixelFieldData.length === 0
                    ? 'None (Default)'
                    : pixelFieldData,
              }}
              componentSpecificClasses={['measurementPixelDropdown']}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DefaultFeatureSet;
