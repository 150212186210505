/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Empty, Select, Spin, Tag } from 'antd';
import {
  DebounceSelectPropsTwo,
  IDebounceDropdownElements,
  IEnableSaveState,
} from '../../../models/interfaces';
import { renderTooltip } from './';
import { useCheckSavingEnabilityStore } from '../../../stores';
import { WarningOutlined } from '@ant-design/icons';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import apiService from '../../../services/Api.service';

const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
};

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectPropsTwo<ValueType>) {
  const [fetching, setFetching] = useState(false),
    [options, setOptions] = useState<ValueType[]>([]),
    [usableOptions, setUsableOptions] = useState<ValueType[]>([]);

  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  useEffect(() => {
    const temp = options.filter(
      (el: any) => !props.value?.find((elem) => elem.value === el.value)
    );

    setUsableOptions(temp);
  }, [props.value, options]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      {...props}
      options={usableOptions}
      optionRender={({ data }) => getRenderedOptions(data)}
      tagRender={(data) => getRenderedTags(data)}
    />
  );
}

const MultiCustomTaxonomy: FC<{
  placeholder: string;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  allowClear?: boolean;
  props?: any;
  onChange: React.Dispatch<React.SetStateAction<IDebounceDropdownElements[]>>;
  isUpdateInDual?: boolean;
  setIsUpdateInDual?: React.Dispatch<React.SetStateAction<boolean>>;
  targetingSetNumber?: number;
  defaultValue?: IDebounceDropdownElements[];
  featureSetNumber?: number;
}> = ({
  placeholder,
  componentSpecificClasses,
  isDisabled = false,
  defaultPopupWidth = true,
  defaultPopupClassName = '',
  allowClear = true,
  props = {},
  onChange,
  isUpdateInDual = false,
  setIsUpdateInDual,
  defaultValue=[],
  targetingSetNumber,
  featureSetNumber,
}) => {
  const [ifShowError, setIfShowError] = useState<boolean>(false),
    [value, setValue] = useState<IDebounceDropdownElements[]>([]);

  const { isFeatureSetsFilled, updateIisFeatureSetsFilled } =
    useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const onFocus = () => {
    ifShowError &&
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
  };

  useEffect(() => {
    if (
      featureSetNumber !== undefined &&
      targetingSetNumber !== undefined &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    if (defaultValue.length > 0) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const getDropdownOptions = async (
    searchParam: string
  ): Promise<IDebounceDropdownElements[]> => {
    const payload = { search: searchParam };

    return apiService
      .post<any>(
        process.env.REACT_APP_BASE_URL + RoutesEnum.GET_SEARCH_RESULT,
        payload,
        {},
        true
      )
      .then((res: any) => {
        if (res) {
          return res.data.provider_taxonomy;
        } else {
          return [];
        }
      })
      .catch((error) => []);
  };

  return (
    <div className="eachCommonElementWrapper">
      {/* <Select
        optionFilterProp="children"
      /> */}
      <DebounceSelect
        mode="multiple"
        value={value}
        placeholder={placeholder}
        defaultValue={defaultValue}
        fetchOptions={getDropdownOptions}
        onChange={(newValue, option)=>{
          let tempValue = [...value];
          if (newValue.length > tempValue.length) {
            const addedOpt = getAddedOrDeletedOpt(option, tempValue);
  
            tempValue = [...tempValue, addedOpt];
          } else if (newValue.length < tempValue.length) {
            const deletedOpt = getAddedOrDeletedOpt(tempValue, newValue);
  
            tempValue = tempValue.filter((el) => el.value !== deletedOpt.value);
          }
          setValue(tempValue);
          onChange(tempValue);
        }}
        className={`multiSelectDropdown${
          componentSpecificClasses?.length
            ? ' ' + componentSpecificClasses.join(' ')
            : ''
        }`}
        suffixIcon={
          ifShowError ? <WarningOutlined className="validationErrorIcon" /> : ''
        }
        status={ifShowError ? 'error' : ''}
        maxTagPlaceholder={renderTooltip}
        onFocus={onFocus}
        disabled={isDisabled}
        allowClear={allowClear}
        popupMatchSelectWidth={defaultPopupWidth}
        popupClassName={defaultPopupClassName}
        style={{ width: '100%' }}
        {...props}
      />
    </div>
  );
};

const getAddedOrDeletedOpt = (c1: any, c2: any) => {
  const map = new Map(),
    updatedC1 = c1.filter((el: any) => Object.keys(el)?.length > 0);

  c2.forEach((el: any) => map.set(el.value, el));

  return updatedC1.find((el: any) => !map.has(el.value));
};

const getRenderedOptions = (
  data: any
) => {
  const label = JSON.parse(data.label ?? '{}');
  
  return (
    <div title={label.code}>
      <div>
        <b>{label.code}</b>:
      </div>
      <div>Grouping: {label.grouping}</div>
      <div>Classification: {label.classification}</div>
      <div>Specialization: {label.specialization}</div>
    </div>
  );
};

const getRenderedTags = (tag: CustomTagProps) => {
  const { label, value, closable, onClose } = tag;

  try {
    if (value) {
      const labelJson: any = JSON.parse(String(label ?? {})),
        onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };

      return (
        <Tag
          className="debounceDDTagWrapper"
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
        >
          <span>
            <b>{labelJson.code}</b>:&nbsp;
          </span>{' '}
          {Boolean(labelJson.grouping) && <span>{labelJson.grouping}</span>}{' '}
          {Boolean(labelJson.classification) && (
            <span>/ {labelJson.classification}</span>
          )}{' '}
          {Boolean(labelJson.specialization) && (
            <span>/ {labelJson.specialization}</span>
          )}
        </Tag>
      );
    }

    return <></>;
  } catch (err: any) {
    console.log(err.message);
    return <></>;
  }
};


export default MultiCustomTaxonomy;
