import { notification } from 'antd';
import { useEffect } from 'react';
import { NotificationMessageProps } from '../models/interfaces';

const useNotificationMessage = ({
  classNames = [],
  messagecontent = '',
  key,
  type = 'success',
  isShowing = false,
  style = {},
} : NotificationMessageProps) => {
  const [api, context] = notification.useNotification();
  const constructMessage = () => {
    api[type]({
      key,
      message: messagecontent,
      placement: 'bottomLeft',
      className: `${classNames?.length ? ' ' + classNames.join(' ') : ''}`,
      duration: 2,
      style:{
        backgroundColor: 'rgba(20, 20, 20, 0.9)',
        borderRadius: '8px',
      }
    });
  };
  
  const destroyMessage = (messageKey: number | string) => {
    api.destroy(messageKey);
  };

  useEffect(() => {
    if (isShowing) {
      constructMessage();
    }
  }, [isShowing]);

  return { context, destroyMessage };
};

export default useNotificationMessage;
