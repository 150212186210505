import { FC, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu, MenuProps, TableColumnsType } from 'antd';
import Table, { TableProps } from 'antd/es/table';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../hooks';
import dayjs from 'dayjs';
import {
  IResearchListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { SortOrder } from 'antd/es/table/interface';
import { useLoaderStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { moreIconVertical } from '../../../../assets/images';

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'Delete',
  },
];

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

interface DataType {
  key: number;
  title: string;
  reports: string[];
  created_on: string;
}
type OperationType = 'delete';

const ResearchListingTable: FC<{
  researchData: any;
  isLoading: boolean;
  paginationAndSortingData: IResearchListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IResearchListingParam>
  >;
}> = ({
  researchData,
  isLoading,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const [body, setBody] = useState<{ research_id: number } | null>(null),
    [endPoint, setEndPoint] = useState<string>('');
  const [typeOfOperation, setTypeOfOperation] =
      useState<OperationType>('delete'),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [notificationObj, setNotificationObj] = useState<any>({ isShowing: false });

  const { setShowPageLoaderState } = useLoaderStore(
    (state: ILoaderState) => state
  );
  const { context, destroyMessage } = useNotificationMessage(notificationObj);

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;

    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  usePostApiInterceptor(
    isMounted,
    body,
    endPoint,
    (data: any, error: any) => {
      setIsMounted(false);
      if (typeOfOperation === 'delete' && !error) {
        setNotificationObj({
          isShowing: true,
          type: 'success',
          messagecontent: 'Research Deleted',
        });
        if (researchData.rows.length === 1) {
          setPaginationAndSortingData({
            ...paginationAndSortingData,
            pageNumber:
              paginationAndSortingData.pageNumber === 1
                ? 1
                : paginationAndSortingData.pageNumber - 1,
          });
        } else {
          setPaginationAndSortingData({ ...paginationAndSortingData });
        }
        setShowPageLoaderState(false);
      }else{
        setNotificationObj({
          isShowing: true,
          type: 'error',
          messagecontent: 'Error while deleting research error',
        });
      }
    }
  );
  const deleteResearch = (research_id: number) => {
    setNotificationObj({
      isShowing: false,
    });
    setTypeOfOperation('delete');
    destroyMessage(typeOfOperation);
    setShowPageLoaderState(true);
    setEndPoint(RoutesEnum.DELETE_RESEARCH);
    setBody({ research_id: research_id });
    setIsMounted(true);
  };

  const handleActions = (e: any, data: any) => {
    if (e.key === '1') {
      deleteResearch(data.key);
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 'auto',
      sorter: true,
      render: (text, record) => (
        <NavLink to={'/update-research/' + record.key} title={text}>
          {text}
        </NavLink>
      ),
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Reports',
      dataIndex: 'reports',
      key: 'reports',
      width: 'auto',
      render: (reports: string[]) => reports.join(', '),
      // sorter: true,
      showSorterTooltip: false,
      ellipsis: true,
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY'),
      width: '150px',
      sorter: true,
      defaultSortOrder: 'descend',
      showSorterTooltip: false,
    },
    {
      title: '',
      key: 'action',
      width: '50px',
      render: (_, record) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              items={items}
              onClick={(e) => {
                handleActions(e, record);
              }}
            ></Menu>
          }
          placement="bottomRight"
        >
          <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
        </Dropdown>
      ),
    },
  ];

  return (
    <Fragment>
      {context}
      <Table
        columns={columns}
        dataSource={researchData?.rows ?? []}
        onChange={handleChange}
        className={`antTableElement researchListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: researchData?.total_row_count ?? 0,
          showSizeChanger: false,
          pageSize: paginationAndSortingData.rowsPerPage,
        }}
        loading={isLoading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </Fragment>
  );
};

export default ResearchListingTable;
