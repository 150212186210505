import { FC, useEffect, useRef } from 'react';
import anychart from 'anychart';
import { ActivateData } from './DashboardContent';
import { TooltipContext } from '../../../models/interfaces';
import ActivationActivityChart from './ActivationActivityChart';
import DeliveredSizeChart from './DeliveredSizeChart';

const ActivationChart: FC<{
  activationData: ActivateData;
}> = ({ activationData }) => {
  const chartContainer = useRef(null);

  const offsetXValue = activationData.partner_percent.length === 1 ? -45 : -80;

  useEffect(() => {
    if (chartContainer.current) {
      const chart = anychart.pie(activationData.partner_percent);

      const tooltip = chart.tooltip();

      tooltip.format(function (this: TooltipContext): string {
        return `${this.value}%`;
      });
      chart.innerRadius('75%');

      tooltip.background({
        fill: '#272727',
      });

      chart.labels(false);

      chart.explode(0);
      chart.palette([
        '#1B58D0',
        '#B1D1B7',
        '#890189',
        '#ffccff',
        '#9ab9f6',
        '#588c00',
      ]);

      chart.interactivity().selectionMode(false);

      chart
        .label(0)
        .text(`${activationData.total_activation}`)
        .position('center')
        .anchor('center')
        .hAlign('center')
        .vAlign('middle')
        .offsetY(0)
        .offsetX(offsetXValue)
        .fontSize(45)
        .fontWeight(700)
        .fontColor('var(--bl-header)');

      chart
        .label(1)
        .text('Activations')
        .position('center')
        .anchor('center')
        .hAlign('center')
        .vAlign('middle')
        .offsetY(30)
        .offsetX(offsetXValue)
        .fontSize(16)
        .fontWeight(400)
        .fontColor('var(--bl-header)');

      chart.background().enabled(false);

      chart.legend(true);
      chart
        .legend()
        .position('right')
        .itemsLayout('vertical')
        .align('center')
        .itemsFormatter(function (items) {
          return items.map((item) => ({
            text: item.text,
            iconType: item.iconType,
            iconStroke: item.iconStroke,
            iconFill: item.iconFill,
          }));
        });

      chart.legend().fontColor('#555552');
      chart.legend().title().enabled(false);
      chart.legend().paginator().enabled(false);

      chart.padding().bottom(0);
      chart.container(chartContainer.current);
      chart.draw();

      return () => {
        chart.dispose();
      };
    }
  }, [activationData]);

  return (
    <div
      className="chartWrapper"
      style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
    >
      <div ref={chartContainer} style={{  height: '345px' }} />
      <ActivationActivityChart activationData={activationData} />
      <DeliveredSizeChart activationData={activationData}/>
    </div>
  );
};

export default ActivationChart;
