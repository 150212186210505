import { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, Menu, MenuProps, TableColumnsType } from 'antd';
import Table, { TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { IActiveAudienceListingParam } from '../../../../models/interfaces';
import { SortOrder } from 'antd/es/table/interface';
import { moreIconVertical } from '../../../../assets/images';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

const items: MenuProps['items'] = [
  {
    key: '1',
    label: 'View Audience',
  },
];

function extractParenthesesContent(inputString: string) {
  const match = RegExp(/\(([^)]+)\)/).exec(inputString);
  return match ? match[1] : inputString;
}

interface DataType {
  title: string;
  act_partners: string[];
  audience_id: number;
  agency: string;
  advertiser: string;
  brand: string;
  type: string;
  act_audience_sizes: number[];
  act_creation_dates: string[];
}

const ActiveAudienceListingTable: FC<{
  activationData: any;
  isLoading: boolean;
  checkedList: string[];
  paginationAndSortingData: IActiveAudienceListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IActiveAudienceListingParam>
  >;
}> = ({
  activationData,
  isLoading,
  checkedList,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const navigate = useNavigate();

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;
    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder: (() => {
        if (sorter?.columnKey === 'date') {
          return getSortOrder('descend');
        }
        if (sorter) {
          return getSortOrder(sorter.order);
        }
        return getSortOrder(sorter.order);
      })(),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  const handleActions = (e: any, data: DataType) => {
    navigate('/audience/' + data.audience_id + '/define');
  };

  function formatEstimatedSizes(sizes: number[]): string[] {
    return sizes.map((size) => {
      return size.toLocaleString();
    });
  }

  function manipulateDataArray(dataArray: DataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        act_audience_sizes: formatEstimatedSizes(item.act_audience_sizes),
        audience_type: extractParenthesesContent(item.audience_type),
      };
    });
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'audience_id',
      key: 'audience_id',
      render: (text) => <>{'#' + text}</>,
      className: 'genericTableID',
      width: '100px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Audience & Partner',
      dataIndex: 'title',
      key: 'audiencePartner',
      width: '200px',
      ellipsis: true,
      render: (text: any, record: any) => (
        <>
          <NavLink to={`/audience/${record.key}/activate`}>
            <h4>{record.title}</h4>
          </NavLink>
          <ul className="partnerList">
            {record.act_partners.map(
              (partner: string, index: React.Key | null | undefined) => (
                <li key={record.key + '_' + index}>&rarr; {partner}</li>
              )
            )}
          </ul>
        </>
      ),
      showSorterTooltip: false,
      sorter: true,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      ellipsis: true,
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
      ellipsis: true,
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      ellipsis: true,
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'type',
      width: '130px',
      ellipsis: true,
      className: 'topAligned',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Size',
      dataIndex: 'act_audience_sizes',
      key: 'size',
      width: '130px',
      ellipsis: true,
      render: (act_audience_sizes: number[]) => (
        <>
          {act_audience_sizes.map(
            (size: number, index: React.Key | null | undefined) => (
              <span key={size + '_' + index}>{size}</span>
            )
          )}
        </>
      ),
      className: 'bottomAligned',
      showSorterTooltip: false,
    },
    {
      title: 'Created',
      dataIndex: 'act_creation_dates',
      key: 'created_on',
      ellipsis: true,
      width: '130px',
      sorter: true,
      render: (act_creation_dates: string[]) => (
        <>
          {act_creation_dates.map(
            (date: string, index: React.Key | null | undefined) => (
              <span key={date + '_' + index}>
                {dayjs(date).format('M/D/YYYY')}
              </span>
            )
          )}
        </>
      ),
      className: 'bottomAligned',
      showSorterTooltip: false,
      defaultSortOrder: 'descend',
      sortDirections: ['descend'],
    },
    {
      title: '',
      key: 'action',
      width: '50px',
      render: (_: any, record: DataType) => (
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu
              items={items}
              onClick={(e) => {
                handleActions(e, record);
              }}
            ></Menu>
          }
          placement="bottomRight"
        >
          <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
        </Dropdown>
      ),
      showSorterTooltip: false,
    },
  ];

  function CreateNewColumns() {
    let newColumns = columns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
      defaultSortOrder:
        item.key === paginationAndSortingData.sortColumn
          ? (paginationAndSortingData.sortOrder as SortOrder)
          : undefined,
    }));
    return checkedList.length > 0 ? newColumns : columns;
  }

  return (
    <Table
      columns={CreateNewColumns()}
      dataSource={manipulateDataArray(activationData?.rows) ?? []}
      onChange={handleChange}
      className={`antTableElement audienceListTable ${
        isLoading ? ' hideNoDataLabel' : ''
      }`}
      pagination={{
        current: paginationAndSortingData.pageNumber,
        total: activationData?.total_row_count ?? 0,
        showSizeChanger: true,
        pageSize: paginationAndSortingData.rowsPerPage,
        locale: {
          items_per_page: 'per page',
        },
      }}
      loading={isLoading}
      bordered
      sortDirections={['ascend', 'descend', 'ascend']}
    />
  );
};

export default ActiveAudienceListingTable;
