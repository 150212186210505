import { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  IAudienceSaveMethods,
  IAudienceState,
  ICustomNPIList,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IHcpFeatureSetBaseData,
  IHcpFeatureState,
  IMasterProviderSpecialtyDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { Select } from 'antd';
import {
  useAudienceSaveMethods,
  useAudienceSets,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import {
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores';
import { featureSetList } from './featureSetList';

const baseValues: IHcpFeatureSetBaseData = {
  selectedProviderSpecialty: [],
  customNPIList: [],
  selectedProviderTaxonomy: [],
};

interface InputObject {
  displayName?: string;
  file?: {
    uid?: string;
  };
  [key: string]: any; // Allow any other properties
}

const HcpFeatureSet: FC<{
  featureSetNumber: number;
  featureSetOrderNumber: number;
  disabled?: boolean;
}> = ({ featureSetNumber, featureSetOrderNumber, disabled }) => {
  const { isCancelled } = useAudienceSets((state: IAudienceState) => state),
    {
      hcpFeatureSet,
      updateHcpFeatureSet,
      hcpTargeting,
      setHcpTargeting,
      updateHcpTargeting,
      setIsHcpModified,
      setUuid,
      uuid,
    } = useHcpFeatureSet((state: IHcpFeatureState) => state),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    {
      isEnableSaveForEachSet,
      updateIsEnableSaveForEachSet,
      updateIisFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { targetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    );

  const [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>(
      []
    ),
    [ifUpdatedCompleted, setIfUpdatedCompleted] = useState<0 | 1 | 2>(0),
    [initialFeatureSet, setInitialFeatureSet] =
      useState<IHcpFeatureSetBaseData | null>(null);

  const targetingOptionsRef = useRef<any[]>([]);

  useEffect(() => {
    if (
      targetingOptionsRef.current.length === 0 &&
      Object.keys(updatedDataSet).length === 0
    ) {
      let newOptions = targetingDDElements.filter(
        (tar) => tar.is_hpa && !tar.is_caregiver && !tar.is_global
      );
      targetingOptionsRef.current = [...newOptions];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkForChanges = (
    currentSet: IHcpFeatureSetBaseData,
    initialSet: IHcpFeatureSetBaseData | null
  ) => {
    if (!initialSet) return false;

    // Helper function to compare arrays
    const areArraysEqual = (arr1: any[] | null, arr2: any[] | null) => {
      if (!arr1 && !arr2) return true;
      if (!arr1 || !arr2) return false;
      if (arr1.length !== arr2.length) return false;
      return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
    };

    // Compare each field
    const hasChanges =
      !areArraysEqual(
        currentSet.selectedProviderSpecialty,
        initialSet.selectedProviderSpecialty
      ) ||
      !areArraysEqual(currentSet.customNPIList, initialSet.customNPIList) ||
      !areArraysEqual(
        currentSet.selectedProviderTaxonomy,
        initialSet.selectedProviderTaxonomy
      );

    return hasChanges;
  };

  useEffect(() => {
    if (!initialFeatureSet && hcpTargeting.length > 0) {
      setInitialFeatureSet({ ...hcpFeatureSet });
    }
  }, [hcpTargeting, initialFeatureSet]);

  useEffect(() => {
    if (initialFeatureSet) {
      const modified = checkForChanges(hcpFeatureSet, initialFeatureSet);
      setIsHcpModified(modified);
    }
  }, [hcpFeatureSet, initialFeatureSet]);

  useEffect(() => {
    if (isCancelled) {
      setFeatureSetBody([]);
    }
  }, [isCancelled]);

  const handleTargetingOptions = (value: any) => {
    updateHcpTargeting(value);
    let updatedOptions = targetingOptionsRef.current.filter(
      (opt: any) => opt.value !== value
    );
    if (value === 7 || value === 20) {
      const disabledValue = value === 7 ? 20 : 7;
      updatedOptions = updatedOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
    }
    targetingOptionsRef.current = [...updatedOptions];
  };

  const checkSaveEnabled = () => {
    switch (true) {
      case !hcpTargeting?.length:
        return 0;
      case hcpTargeting.includes(6) &&
        !hcpFeatureSet.selectedProviderSpecialty?.length:
      case hcpTargeting.includes(7) && !hcpFeatureSet.customNPIList?.length:
      case hcpTargeting.includes(20) &&
        !hcpFeatureSet?.selectedProviderTaxonomy?.length:
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hcpTargeting, hcpFeatureSet]);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      updateHcpFeatureSet(data, key);
    }
  };

  const performValidations = () => {
    validationForArray(6, hcpFeatureSet.selectedProviderSpecialty);
    validationForArray(7, hcpFeatureSet.customNPIList);
    validationForArray(20, hcpFeatureSet.selectedProviderTaxonomy);
  };

  const validationForArray = (tId: number, data: any) => {
    if (hcpTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  useEffect(() => {
    performValidations();
  }, []);

  const handleSetDelete = (setIds: [number, keyof IHcpFeatureSetBaseData]) => {
    const newTargetingElemArray = hcpTargeting.filter((el) => setIds[0] !== el);
    setHcpTargeting(newTargetingElemArray);
    updateHcpFeatureSet(baseValues[setIds[1]], setIds[1]);

    const matchingTarget = targetingDDElements.find(
      (tar: any) =>
        tar.value === setIds[0] && !tar.is_caregiver && !tar.is_global
    );

    let disableValue: number | null = null;

    if (setIds[0] === 7) {
      disableValue = 20;
    } else if (setIds[0] === 20) {
      disableValue = 7;
    }

    const updatedOptionsWithEnabled = targetingOptionsRef.current.map(
      (opt: any) =>
        opt.value === disableValue ? { ...opt, disabled: false } : opt
    );

    if (matchingTarget) {
      targetingOptionsRef.current = [
        ...[...updatedOptionsWithEnabled, matchingTarget].sort(
          (a: any, b: any) => a.value - b.value
        ),
      ];
    }
  };

  const getParams = () => {
    const params: IFeatureSetParamDatum[] = [
      {
        value: 6,
        values: [hcpFeatureSet.selectedProviderSpecialty],
        names: ['selectedProviderSpecialty'],
        base: [baseValues.selectedProviderSpecialty],
        param: {
          setId: 6,
          featureSetNumber,
          label: 'Provider Speciality',
          is_feature: true,
          options: providerSpecialtyDDElements,
          placeholder: 'Select Provider Specialities',
          isSearchable: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedProviderSpecialty', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: hcpFeatureSet.selectedProviderSpecialty,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 7,
        values: [hcpFeatureSet.customNPIList],
        names: ['customNPIList'],
        base: [baseValues.customNPIList],
        param: {
          setId: 7,
          featureSetNumber,
          label: 'Custom NPI List',
          is_feature: true,
          uuid: uuid,
          customNPIList: hcpFeatureSet.customNPIList,
          setCustomNPIList: (data: any) => {
            handleFeatureSetUpdates('customNPIList', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 20,
        values: [hcpFeatureSet.selectedProviderTaxonomy],
        names: ['selectedProviderTaxonomy'],
        base: [baseValues.selectedProviderTaxonomy],
        param: {
          setId: 20,
          featureSetNumber,
          label: 'Provider Taxonomy',
          is_feature: true,
          placeholder: 'Select Provider Taxonomy',
          isSearchable: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedProviderTaxonomy', data);
          },
          value: hcpFeatureSet.selectedProviderTaxonomy,
          handleSetDelete,
          isDisabled: disabled,
        },
      },
    ];
    return params;
  };

  useEffect(() => {
    if (hcpTargeting?.length) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();
      hcpTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
  }, [hcpTargeting, hcpFeatureSet]);

  // Custom NPI List
  useEffect(() => {
    if (
      (ifUpdatedCompleted === 0 || (ifUpdated && ifUpdatedCompleted === 2)) &&
      hcpTargeting.includes(7)
    ) {
      const usableData: ICustomNPIList[] = hcpFeatureSet.customNPIList.map(
        (el) => ({
          key: el.key,
          displayName: el.displayName,
          name: el.name,
          uuid: el.uuid,
        })
      );
      updateHcpFeatureSet(usableData, 'customNPIList');
    }
  }, [ifUpdated]);

  function extractDisplayNameAndUid(
    inputArray: InputObject[]
  ): { displayName: string; uuid: number } | null {
    if (!inputArray || inputArray.length === 0) {
      return null;
    }
    const firstObject = inputArray[0];
    const displayName = firstObject.displayName ?? '';

    return {
      displayName,
      uuid,
    };
  }

  const getFormattedData = () => {
    const tempData: any = {
        ...hcpFeatureSet,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });

    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_rank: featureSetOrderNumber,
        condition_id: 5,
        is_global: false,
        is_hcp: true,
      },
      targeting:any = [];
    for (const key in hcpFeatureSet) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value:
            usableTargetingId === 7
              ? extractDisplayNameAndUid(tempData[key])
              : tempData[key],
          targeting_set_rank: hcpTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (hcpTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (
      ifUpdated &&
      updatedDataSet?.feature_sets?.length &&
      hcpTargeting.length === 0
    ) {
      setIfUpdatedCompleted(1);
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) => el.feature_set_id === featureSetNumber && el.is_hcp
      );

      if (featureSet) {
        const params = getParams(),
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [],
          targetId: any = [];

        targetingSet.forEach((el: any) => {
          newTargetingSet.push({
            ...el,
            targeting_value:
              el.targeting_id !== 14
                ? [JSON.parse(el.targeting_value)]
                : el.targeting_value,
            uniqueIds: [el.targeting_id],
          });
          targetId.push(el.targeting_id);
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );
        usableTargetingSet.forEach((el: any) => {
          if (el.targeting_id === 7) {
            setUuid(el.targeting_value[0].uuid);
          }
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            const onChange = param?.param?.onChange;
            if (onChange !== undefined) {
              onChange(el.targeting_value[0]);
            }
            if (el.uniqueIds[0] === 7) {
              param.param.setCustomNPIList([el.targeting_value[0]]);
            }
          }
        });
        let newOptions = targetingDDElements.filter(
          (tar) =>
            !targetId.includes(tar.value) &&
            tar.is_hpa &&
            !tar.is_caregiver &&
            !tar.is_global
        );

        const isTargetingId7 = targetId.includes(7);
        const isTargetingId20 = targetId.includes(20);
        const isExist = isTargetingId7 ? 20 : 30;
        const disabledValue = isTargetingId20 ? 7 : isExist;

        const updatedElements = newOptions.map((opt: any) =>
          opt.value === disabledValue ? { ...opt, disabled: true } : opt
        );
        targetingOptionsRef.current = [...updatedElements];
        setHcpTargeting(usableTargetingSet.map((el: any) => el.targeting_id));
      } else {
        let newOptions = targetingDDElements.filter(
          (tar) => tar.is_hpa && !tar.is_caregiver && !tar.is_global
        );
        targetingOptionsRef.current = [...newOptions];
      }
    } else if(hcpTargeting.length) {
      let newOptions = targetingDDElements.filter(
        (tar) =>
          !hcpTargeting.includes(Number(tar.value)) &&
          tar.is_hpa &&
          !tar.is_caregiver &&
          !tar.is_global
      );

      const isTargetingId7 = hcpTargeting.includes(7);
      const isTargetingId20 = hcpTargeting.includes(20);
      const isExist = isTargetingId7 ? 20 : 30;
      const disabledValue = isTargetingId20 ? 7 : isExist;

      const updatedElements = newOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
      targetingOptionsRef.current = [...updatedElements];
    }else{
      let newOptions = targetingDDElements.filter(
        (tar) => tar.is_hpa && !tar.is_caregiver && !tar.is_global
      );
      targetingOptionsRef.current = [...newOptions];
    }
  }, []);

  return (
    <>
      <div className="audienceDefinitionElem">
        <div className="audienceDefinitionleft">
          <p>Healthcare Professional Attributes</p>
        </div>
        <div className="audienceDefinitionRight">
          {hcpTargeting.length > 0 && (
            <div>
              {featureSetBody.map((el: IFeatureSetElement) => (
                <Fragment key={`${el.orderId}_${el.setId}`}>
                  {el.element}
                </Fragment>
              ))}
            </div>
          )}

          {targetingOptionsRef.current.length > 0 && (
            <Select
              style={{ width: 190 }}
              options={targetingOptionsRef.current}
              placeholder="Choose"
              onChange={(data: number) => handleTargetingOptions(data)}
              value={null}
              disabled={disabled}
            />
          )}
        </div>
      </div>
      <div className="intermediateContent"><span>Who Are Treating...</span><span className='pipe'></span></div>
    </>
  );
};

export default HcpFeatureSet;
