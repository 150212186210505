import { FC, useEffect, useState } from 'react';
import { ListingTable } from './';
import { PageHeader } from '../../../sharedComponent';
import {
  useIsTablet,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { IAudienceListingParam } from '../../../../models/interfaces';
import { Button, ConfigProvider, TableColumnsType, Tooltip } from 'antd';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { ColumnSelectorIcon, filter } from '../../../../assets/images';
import { ColumnSelector } from '../../../sharedComponent/ColumnSelector';
import { DataType } from './ListingTable';

type AudienceBodyType = {
  audience_type: string | null;
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  title: string | null;
  brand_id: string | null;
  status_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  timeframe: string | null;
  filter_option: number | null;
  is_archived: boolean;
};

const convertFiltersToNumberArray = (filters: any): IAudienceListingParam => {
  const result: Partial<IAudienceListingParam> = {
    sortOrder: filters.audience.sortOrder,
    sortColumn: filters.audience.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: filters.audience.rowsPerPage,
  };

  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }

  if (filters.audience) {
    if (filters.audience.title) result.title = filters.audience.title;
    if (filters.audience.filter_option)
      result.filter_option = filters.audience.filter_option;
    if (filters.audience.audienceType)
      result.audienceType = filters.audience.audienceType;
    if (filters.audience.is_archived)
      result.is_archived = filters.audience.is_archived;
  }

  if (filters.audience && filters.audience.status) {
    result.status = filters.audience.status.toString();
  }

  return result as IAudienceListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const baseListingParam = {
  dateRange: null,
  audienceType: null,
  status: null,
  agency: null,
  brand: null,
  advertiser: null,
  title: '',
  filter_option: 1,
  timeframe: null,
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  is_archived: false,
};

const AudienceListingWrap: FC = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') ?? '{}'),
    columnList = JSON.parse(localStorage.getItem('columns') ?? '{}'),
    isTablet = useIsTablet(),
    [audienceListingParam, setAudienceListingParam] =
      useState<IAudienceListingParam>(
        Object.keys(filters.audience ?? {}).length > 0
          ? convertFiltersToNumberArray(filters)
          : baseListingParam
      ),
    [body, setBody] = useState<AudienceBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [checkedList, setCheckedList] = useState<string[]>(
      columnList.audiences ?? [
        'title',
        'agency',
        'advertiser',
        'brand',
        'status',
        'created_on',
        'action',
        'audience_type',
      ]
    ),
    [columnPickerOpen, setColumnPickerOpen] = useState<boolean>(false),
    [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { data, isLoading } = usePostApiInterceptor(
      isMounted,
      body,
      RoutesEnum.AUDIENCE_LISTING_DATA,
      () => {
        setIsMounted(false);
      }
    ),
    { redirectAudienceSetup } = useRedirection();

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    setBody({
      agency_id: audienceListingParam.agency?.length
        ? audienceListingParam.agency.toString()
        : null,
      advertiser_id: audienceListingParam.advertiser?.length
        ? audienceListingParam.advertiser.toString()
        : null,
      brand_id: audienceListingParam.brand?.length
        ? audienceListingParam.brand.toString()
        : null,
      status_id: audienceListingParam.status?.length
        ? audienceListingParam.status.toString()
        : null,
      audience_type: audienceListingParam.audienceType?.length
        ? audienceListingParam.audienceType.toString()
        : null,
      end_date: audienceListingParam.dateRange?.length
        ? audienceListingParam.dateRange[1]
        : null,
      start_date: audienceListingParam.dateRange?.length
        ? audienceListingParam.dateRange[0]
        : null,
      title: audienceListingParam.title,
      filter_option: audienceListingParam.filter_option,
      timeframe: audienceListingParam.timeframe,
      limit: audienceListingParam.rowsPerPage,
      is_archived : audienceListingParam.is_archived,
      off_set:
        (audienceListingParam.pageNumber - 1) *
        audienceListingParam.rowsPerPage,
      order_column: audienceListingParam.sortColumn,
      order_direction: audienceListingParam.sortOrder,
    });
  }, [audienceListingParam]);

  useEffect(() => {
    let existingFilters = JSON.parse(localStorage.getItem('Filters') ?? '{}');
    let filterStorage = {
      ...existingFilters,
      audience: {
        ...existingFilters.audience,
        rowsPerPage: audienceListingParam.rowsPerPage,
        sortColumn: audienceListingParam.sortColumn,
        sortOrder: audienceListingParam.sortOrder,
      },
    };
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
  }, [
    audienceListingParam.rowsPerPage,
    audienceListingParam.sortColumn,
    audienceListingParam.sortOrder,
  ]);

  const applyFilters = (data: any) => {
    let filterData = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      status_id: data.status?.length ? data.status.toString() : null,
      audience_type: data.audienceType?.length
        ? data.audienceType.toString()
        : null,
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      title: data.title,
      filter_option: data.filter_option,
      limit: audienceListingParam.rowsPerPage,
      is_archived: data.is_archived,
      timeframe: data.timeframe,
      off_set:
        (audienceListingParam.pageNumber - 1) *
        audienceListingParam.rowsPerPage,
      order_column: audienceListingParam.sortColumn,
      order_direction: audienceListingParam.sortOrder,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') ?? '{}'),
      global: {
        agency: filterData.agency_id,
        advertiser: filterData.advertiser_id,
        brand: filterData.brand_id,
        dateRange:
          filterData.start_date && filterData.end_date
            ? [filterData.start_date, filterData.end_date]
            : null,
        timeframe: filterData.timeframe,
      },
      audience: {
        title: filterData.title,
        filter_option: filterData.filter_option,
        status: data.status,
        is_archived: data.is_archived,
        audienceType: data.audienceType,
        rowsPerPage: audienceListingParam.rowsPerPage,
        sortColumn: audienceListingParam.sortColumn,
        sortOrder: audienceListingParam.sortOrder,
      },
    };
    setAudienceListingParam({
      audienceType: data.audienceType?.length ? data.audienceType : null,
      status: data.status?.length ? data.status.toString() : null,
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title,
      filter_option: data.filter_option,
      rowsPerPage: audienceListingParam.rowsPerPage,
      timeframe: data.timeframe ?? null,
      pageNumber: audienceListingParam.pageNumber,
      sortColumn: audienceListingParam.sortColumn,
      sortOrder: audienceListingParam.sortOrder,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
      is_archived: data.is_archived,
    });
    setBody(filterData);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setIsFilterModalOpen(false);
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  const Columns: TableColumnsType<DataType> = [
    { title: 'ID', dataIndex: 'key', key: 'key' },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Agency', dataIndex: 'agency', key: 'agency' },
    { title: 'Advertiser', dataIndex: 'advertiser', key: 'advertiser' },
    { title: 'Brand', dataIndex: 'brand', key: 'brand' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Type', dataIndex: 'audience_type', key: 'audience_type' },
    { title: 'Created', dataIndex: 'created_on', key: 'created_on' },
    { title: 'Modified', dataIndex: 'updated_on', key: 'updated_on' },
    // { title: 'Action', dataIndex: 'action', key: 'action' },
  ];

  const options = Columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  useEffect(() => {
    const tabletColumns = [
      'title',
      'agency',
      'advertiser',
      'brand',
      'status',
      'created_on',
      'action',
      'audience_type',
    ];
    const newColumnList = { ...columnList };
    newColumnList['audiences'] = isTablet ? tabletColumns : checkedList;
    localStorage.setItem('columns', JSON.stringify(newColumnList));
    if (
      JSON.stringify(newColumnList['audiences']) !== JSON.stringify(checkedList)
    ) {
      setCheckedList(newColumnList['audiences']);
    }
  }, [isTablet]);

  useEffect(() => {
    if (!isTablet) {
      const newColumnList = { ...columnList };
      newColumnList['audiences'] = checkedList;
      localStorage.setItem('columns', JSON.stringify(newColumnList));
    }
  }, [checkedList]);

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Audiences" />
      <div className="audienceListingPageContent">
        <div className="filterWrap">
          <div className="filterBtnAndText">
            <ConfigProvider wave={{ disabled: true }}>
              <Tooltip title="Configure Columns">
                <Button onClick={() => setColumnPickerOpen(true)}>
                  <img src={ColumnSelectorIcon} alt="" />
                </Button>
              </Tooltip>
              <Tooltip title="Filter Results">
                <Button onClick={() => setIsFilterModalOpen(true)}>
                  <img src={filter} alt="" />
                </Button>
              </Tooltip>
            </ConfigProvider>
            {(audienceListingParam.agency ||
              audienceListingParam.advertiser ||
              audienceListingParam.brand ||
              audienceListingParam.audienceType ||
              audienceListingParam.timeframe ||
              audienceListingParam.dateRange ||
              audienceListingParam.title ||
              audienceListingParam.status ||
              audienceListingParam.is_archived ||
              audienceListingParam.audienceType) && (
              <FilterMessage type="audience" data={audienceListingParam} />
            )}
          </div>

          <ColumnSelector
            isOpen={columnPickerOpen}
            onOk={() => {
              setColumnPickerOpen(false);
            }}
            col={checkedList}
            setCol={setCheckedList}
            tableOptions={options}
            onCancel={() => setColumnPickerOpen(false)}
          />

          <GlobalFilterModal
            isOpen={isFilterModalOpen}
            filterData={audienceListingParam}
            type="audience"
            handleOk={applyFilters}
            handleCancel={handleCancel}
          />

          <button
            className="primaryBtn addAudienceBtn"
            onClick={() => redirectAudienceSetup()}
          >
            New Audience
          </button>
        </div>
        <ListingTable
          audData={data}
          checkedList={checkedList}
          isLoading={isLoading}
          paginationAndSortingData={audienceListingParam}
          setPaginationAndSortingData={setAudienceListingParam}
        />
      </div>
    </div>
  );
};

export default AudienceListingWrap;
