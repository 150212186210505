import React, { useEffect, useState } from 'react';
import { ai } from '../../../../assets/images';
import {
  IAudienceState,
  IFeatureSetDataState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { PageHeader } from '../../../sharedComponent';
import { AudienceEstimateTable } from '../../AudienceActivation/AudienceActivationComponent';
import {
  useFeatureSetDataHandlingStore,
  useMasterAudienceTypeElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
} from '../../../../stores/masterData.store';
import { useAudienceSets } from '../../../../stores/audienceSets.store';
import AudienceInsightsWrap from './AudienceInsights/AudienceInsightsWrap';
import { Tooltip } from 'antd';

type TEstimateBody = {
  audience_id: number;
  estimate_id: number;
};

const ReviewAudience = () => {
  document.title = 'Review Audience - BranchLab';

  const { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { setIsDefinitionUpdated } = useAudienceSets(
      (state: IAudienceState) => state
    ),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    );

  const [estimateData, setEstimateData] = useState<any>([]),
    [showAudienceEstimate, setShowAudienceEstimate] = useState<boolean>(false),
    [isEstimateMounted, setIsEstimateMounted] = useState<boolean>(false),
    [disableEstimate, setDisableEstimate] = useState<boolean>(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [isReviewMounted, setIsReviewMounted] = useState(false),
    [isReviewBody, setIsReviewBody] = useState<{ audience_id: number } | null>(
      null
    ),
    [showAllEstimate, setShowAllEstimate] = useState(false),
    [estimateSizeBody, setEstimateSizeBody] = useState<TEstimateBody | null>(
      null
    );

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const audienceTypeLookup = new Map(
    audienceTypeDDElements.map((item) => [item.value, item.label])
  );
  const agencyLookup = new Map(
    agencyDDElements.map((item) => [item.value, item.label])
  );
  const advertiserLookup = new Map(
    advertiserDDElements.map((item) => [item.value, item.label])
  );
  const brandLookup = new Map(
    brandDDElements.map((item) => [item.value, item.label])
  );

  useEffect(() => {
    if (updatedDataSet.estimated_info) {
      let estimateStatus =
        (updatedDataSet.status_id === 2 ||
          updatedDataSet.status_id === 3 ||
          updatedDataSet.estimated_info[0].status === 'Regenerating' ||
          updatedDataSet.estimated_info[0].status === 'Pending') &&
        userInformation.role !== 4;
      setShowAudienceEstimate(estimateStatus);
      if (
        updatedDataSet.estimated_info[0].status === 'Regenerating' ||
        updatedDataSet.estimated_info[0].status === 'Pending'
      ){
        setDisableEstimate(true);
        setShowAllEstimate(true)
      }
      setEstimateData(updatedDataSet.estimated_info);
    }
  }, [updatedDataSet]);

  const DestroyMessage = () => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
  };

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  usePostApiInterceptor(
    isEstimateMounted,
    estimateSizeBody,
    RoutesEnum.SAVE_ESTIMATE,
    (data: any, error: any) => {
      if (error || !data) {
        updateErrorMessage('error', 'Error occurred while generating estimate');
        return;
      }
      setIsDefinitionUpdated(false);
      updateErrorMessage('success', 'Audience estimation started');
      setEstimateSizeBody(null);
      setIsEstimateMounted(false);
      setIsReviewMounted(true);
      setIsReviewBody({ audience_id: updatedDataSet.audience_id });
    }
  );

  usePostApiInterceptor(
    isReviewMounted,
    isReviewBody,
    RoutesEnum.GET_ACTIVATION,
    (data: any, error: any) => {
      setIsReviewMounted(false);
      setIsReviewBody(null);
      if (error || !data) {
        updateErrorMessage('error', 'Error occurred while generating review');
        return;
      }
      if (data.estimated_info) {
        setShowAllEstimate(true)
        updatedDataSet['estimated_info'] = data.estimated_info;
        if (userInformation.role !== 4) setShowAudienceEstimate(true);
        setEstimateData(data.estimated_info);
      }
      setUpdatedDataSet(updatedDataSet);
    }
  );

  const generateEstimate = () => {
    DestroyMessage();
    setEstimateSizeBody({
      audience_id: parseInt(updatedDataSet.audience_id ?? '0'),
      estimate_id: estimateData.length ? estimateData[0].estimate_id : 0,
    });
    setDisableEstimate(true);
    setIsEstimateMounted(true);
  };

  return (
    <>
      {context}
      <PageHeader
        title="Review Audience"
        componentSpecificClass="audienceActivationPageHeader"
        disabled={updatedDataSet?.activation_list?.length === 0}
      />
      <div className="infoElemWrap">
        <div className="chart1">
          <p className="reviewAudienceInfo">
            <span>
              <strong>Audience Name:</strong> {updatedDataSet.title}
            </span>
            <span>
              <strong>Type:</strong>{' '}
              {audienceTypeLookup.get(updatedDataSet.audience_type_id)}
            </span>
            <span>
              <strong>Agency:</strong>{' '}
              {agencyLookup.get(updatedDataSet.agency_id)}
            </span>
            <span>
              <strong>Advertiser:</strong>{' '}
              {advertiserLookup.get(updatedDataSet.advertiser_id)}
            </span>
            <span>
              <strong>Brand:</strong> {brandLookup.get(updatedDataSet.brand_id)}
            </span>
          </p>
          {updatedDataSet?.ai_summary?.length > 0 && (
            <div className="aiSummary d-flex">
              <Tooltip title="AI Summary">
                <img src={ai} alt="" className="aiIcon" />
              </Tooltip>
              <p>{updatedDataSet.ai_summary}</p>
            </div>
          )}
        </div>
      </div>
      <div className="audienceActivationTable mb-20">
        <AudienceEstimateTable
          isLoading={false}
          showAllEstimate={showAllEstimate}
          setShowAllEstimate={setShowAllEstimate}
          isActive={showAudienceEstimate}
          data={estimateData}
          disable={disableEstimate}
          selectedAudienceType={updatedDataSet.audience_type_id}
          generateEstimate={generateEstimate}
        />
      </div>
      {estimateData.length > 0 && estimateData[0].status === 'Completed' && (
        <AudienceInsightsWrap
          audience_id={updatedDataSet.audience_id}
          job_id={estimateData[0].job_id}
        />
      )}
    </>
  );
};

export default ReviewAudience;
