import React, { FC, useEffect, useState } from 'react';
import { Button, Modal, Row, Select, Switch } from 'antd';
import {
  CommonElementLabel,
  DateDropdown,
  Multi,
  StatusDropdownMulti,
} from '../../sharedComponent';
import dayjs from 'dayjs';
import {
  useMasterAudienceTypeElementsStore,
  useMasterStatusElementsStore,
  useMasterTimeFrameElementsStore,
} from '../../../stores';
import {
  IAudienceSetUpState,
  IfilterProps,
  IMasterActionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterFilterDDState,
  IMasterPartnerDDState,
  IMasterReportsDDState,
  IMasterStatusDDState,
  IMasterTimeFrameDDState,
} from '../../../models/interfaces';
import {
  useMasterActionElementsStore,
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterFilterElmentStore,
  useMasterPartnerElementsStore,
  useMasterReportsElementsStore,
} from '../../../stores/masterData.store';
import Input from 'antd/es/input/Input';
import { itemTypeElement } from '../../../demoData/baseData';
import { useAudienceSetUp } from '../../../stores/audienceSets.store';

interface FilterModalProps {
  isOpen: boolean;
  type:
    | 'audience'
    | 'activation'
    | 'research'
    | 'pixel'
    | 'audit'
    | 'dashboard'
    | '';
  filterData: any;
  handleOk: (data: any) => void;
  handleCancel: () => void;
  isDisabled?: boolean;
}

const GlobalFilterModal: FC<FilterModalProps> = ({
  isOpen,
  filterData,
  handleOk,
  type,
  handleCancel,
  isDisabled,
}) => {
  const { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { actionDDElements } = useMasterActionElementsStore(
      (state: IMasterActionDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { filterDDElements } = useMasterFilterElmentStore(
      (state: IMasterFilterDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { partnerDDElements } = useMasterPartnerElementsStore(
      (state: IMasterPartnerDDState) => state
    ),
    { reportsDDElements } = useMasterReportsElementsStore(
      (state: IMasterReportsDDState) => state
    ),
    { statusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { emptyFilter, setEmptyFilter } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    );

  const [filterObject, setFilterObject] = useState<IfilterProps>({
    agency: filterData.agency ?? null,
    brand: filterData.brand ?? null,
    timeframe: filterData.timeframe ?? null,
    advertiser: filterData.advertiser ?? null,
    dateRange: filterData.dateRange ?? null,
    status: filterData.status
      ? filterData.status?.split(',').map(Number)
      : null,
    title: filterData.title ?? null,
    filter_option: filterData.filter_option ?? 1,
    audienceType: filterData.audienceType ?? null,
    partner: filterData.partner
      ? filterData.partner.split(',').map(Number)
      : null,
    report_id: filterData.report_id ?? null,
    action_name: filterData.action_name,
    type: filterData.type,
    is_archived: filterData.is_archived,
    username: filterData.username ?? null,
  });

  const onClearAll = () => {
    setFilterObject({
      agency: null,
      brand: null,
      timeframe: null,
      advertiser: null,
      dateRange: null,
      status: null,
      title: null,
      filter_option: 1,
      audienceType: null,
      partner: null,
      report_id: null,
      action_name: null,
      type: null,
      is_archived:false,
      username: null,
    });
  };

  const handleApplyFilter = () => {
    handleOk(filterObject);
    handleCancel();
  };

  const handleClose = () => {
    handleCancel();
    setFilterObject({
      agency: filterData.agency ?? null,
      brand: filterData.brand ?? null,
      timeframe: filterData.timeframe ?? null,
      advertiser: filterData.advertiser ?? null,
      dateRange: filterData.dateRange ?? null,
      status: filterData.status
        ? filterData.status.split(',').map(Number)
        : null,
      title: filterData.title ?? null,
      filter_option: filterData.filter_option ?? 1,
      audienceType: filterData.audienceType ?? null,
      partner: filterData.partner
        ? filterData.partner.split(',').map(Number)
        : null,
      report_id: filterData.report_id ?? null,
      is_archived: filterData.is_archived ?? false,
    });
  };

  useEffect(() => {
    if (emptyFilter) {
      onClearAll();
      handleOk({
        agency: null,
        brand: null,
        timeframe: null,
        advertiser: null,
        dateRange: null,
        status: null,
        title: null,
        filter_option: 1,
        audienceType: null,
        partner: null,
        report_id: null,
        action_name: null,
        type: null,
        username: null,
        is_archived:false
      });
      setEmptyFilter(false);
    }
  }, [emptyFilter]);

  return (
    <Modal
      centered
      open={isOpen}
      onOk={handleApplyFilter}
      onCancel={handleClose}
      footer={[
        <Button key="back" onClick={onClearAll}>
          Clear All
        </Button>,
        <Button key="confirm" disabled={isDisabled} onClick={handleApplyFilter}>
          Apply Filters
        </Button>,
      ]}
      className="commonModal globalFilterModal"
      width="770px"
    >
      <div className="GlobalFilterModal modalGenericContent">
        <div className="modalGenericTextContent">
          <h3>Filters</h3>
          <div>
            {type !== 'dashboard' && <p>Global Filters</p>}
            {type !== 'dashboard' && (
              <DateDropdown
                label="Timeframe"
                isMandatory={false}
                options={timeFrameDDElements}
                placeholder="Select Timeframe"
                isSearchable={true}
                timeFrame={filterObject.timeframe ?? null}
                dateRange={filterObject.dateRange ?? null}
                setTimeFrame={(data: any) =>
                  setFilterObject({ ...filterObject, timeframe: data })
                }
                setDateRange={(data: any) =>
                  setFilterObject({ ...filterObject, dateRange: data })
                }
                props={{ value: filterObject.timeframe ?? null }}
                minDate={dayjs('2022-01-01')}
                componentSpecificClassesDD={['timeframeClass']}
              />
            )}

            <Row>
              <CommonElementLabel label="Agency" />
              <Multi
                options={agencyDDElements}
                placeholder="Select a Agency"
                isSearchable={true}
                allowClear={false}
                onChange={(data: any) =>
                  setFilterObject({ ...filterObject, agency: data })
                }
                value={filterObject.agency ?? []}
              />
            </Row>
            <Row>
              <CommonElementLabel label="Advertiser" />
              <Multi
                options={advertiserDDElements}
                placeholder="Select a Advertiser"
                isSearchable={true}
                allowClear={false}
                onChange={(data: any) =>
                  setFilterObject({ ...filterObject, advertiser: data })
                }
                value={filterObject.advertiser ?? []}
              />
            </Row>
            <Row>
              <CommonElementLabel label="Brand" />
              <Multi
                options={brandDDElements}
                placeholder="Select a Brand"
                isSearchable={true}
                allowClear={false}
                onChange={(data: any) =>
                  setFilterObject({ ...filterObject, brand: data })
                }
                value={filterObject.brand ?? []}
              />
            </Row>
          </div>
          {type !== 'dashboard' && (
            <div>
              <p>Local Filters</p>

              {type !== 'audit' && (
                <div className="multipleInputsInARow">
                  <CommonElementLabel label="Title" />
                  <Input
                    placeholder="Title"
                    value={filterObject.title ?? ''}
                    onChange={(data: any) =>
                      setFilterObject({
                        ...filterObject,
                        title: data.target.value,
                      })
                    }
                    className="multipleInputSecFirstElem"
                  />
                  <Select
                    options={filterDDElements}
                    placeholder="Select a Operator"
                    allowClear={false}
                    onChange={(data: any) =>
                      setFilterObject({ ...filterObject, filter_option: data })
                    }
                    value={filterObject.filter_option ?? 1}
                    className="multipleInputSecSecondElem"
                  />
                </div>
              )}
              {type === 'audience' && (
                <>
                  <Row>
                    <CommonElementLabel label="Types" />
                    <Multi
                      options={audienceTypeDDElements}
                      placeholder="Select Types"
                      onChange={(data: any) =>
                        setFilterObject((prevData: any) => ({
                          ...prevData,
                          audienceType: data,
                        }))
                      }
                      value={filterObject.audienceType ?? []}
                    />
                  </Row>
                  <Row>
                    <StatusDropdownMulti
                      label="Status"
                      isMandatory={false}
                      options={statusDDElements}
                      placeholder="Select Status"
                      componentSpecificClasses={['statusTagSelect']}
                      selectedStatus={
                        Array.isArray(filterObject.status)
                          ? filterObject.status
                          : []
                      }
                      setSelectedStatus={(data: any) =>
                        setFilterObject((prevData: any) => ({
                          ...prevData,
                          status: data,
                        }))
                      }
                    />
                  </Row>
                  <Row>
                    <CommonElementLabel label="Show archived audiences" />
                    <Switch
                      checked={filterObject.is_archived}
                      size="small"
                      onChange={(data: any) =>
                        setFilterObject((prevData: any) => ({
                          ...prevData,
                          is_archived: data,
                        }))
                      }
                    />
                  </Row>
                </>
              )}
              {type === 'activation' && (
                <Row>
                  <CommonElementLabel label="Partner To" />
                  <Multi
                    options={partnerDDElements}
                    placeholder="Select Partner"
                    onChange={(data: any) => {
                      console.log(data, partnerDDElements);
                      setFilterObject({ ...filterObject, partner: data });
                    }}
                    value={filterObject.partner ?? []}
                  />
                </Row>
              )}
              {type === 'research' && (
                <div className="multipleInputsInARow">
                  <CommonElementLabel label="Report Types" />
                  <Multi
                    options={reportsDDElements}
                    placeholder="Report Types"
                    onChange={(data: any) =>
                      setFilterObject({ ...filterObject, report_id: data })
                    }
                    value={filterObject.report_id ?? []}
                  />
                </div>
              )}
              {type === 'audit' && (
                <div className="multipleInputsInARow">
                  <CommonElementLabel label="Username" />
                  <Input
                    placeholder="Username"
                    value={filterObject.username ?? ''}
                    onChange={(data: any) =>
                      setFilterObject({
                        ...filterObject,
                        username: data.target.value,
                      })
                    }
                    className="multipleInputSecFirstElem"
                  />
                  <Select
                    options={filterDDElements}
                    placeholder="Select a Operator"
                    allowClear={false}
                    onChange={(data: any) =>
                      setFilterObject({ ...filterObject, filter_option: data })
                    }
                    value={filterObject.filter_option ?? 1}
                    className="multipleInputSecSecondElem"
                  />
                </div>
              )}
              {type === 'audit' && (
                <Row>
                  <CommonElementLabel label="Action Types" />
                  <Multi
                    options={actionDDElements}
                    placeholder="Action Types"
                    onChange={(data: any) =>
                      setFilterObject({ ...filterObject, action_name: data })
                    }
                    value={filterObject.action_name ?? []}
                  />
                </Row>
              )}
              {type === 'audit' && (
                <Row>
                  <CommonElementLabel label="Item Type" />
                  <Multi
                    options={itemTypeElement}
                    placeholder="Item Type"
                    onChange={(data: any) =>
                      setFilterObject({ ...filterObject, type: data })
                    }
                    value={filterObject.type ?? []}
                  />
                </Row>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default GlobalFilterModal;
