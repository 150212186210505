import {
  Button,
  Dropdown,
  Menu,
  MenuProps,
  Table,
  TableColumnsType,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';
import {
  useFeatureSetDataHandlingStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IFeatureSetDataState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { RbacSettings } from '../../../../models/enums/rbacSetting';
import { moreIconVertical } from '../../../../assets/images';
import { roundTo } from './AudienceEstimateTable';

interface IDtcDataType {
  activation_id: number;
  audience_filename: string;
  partner: string;
  date: Date;
  audience_size: number;
  ati: number;
  override_size: number | null;
  status: boolean;
}

interface IHcpDataType {
  activation_id: number;
  audience_filename: string;
  partner: string;
  date: Date;
  audience_size: number;
  override_size: number;
  status: boolean;
}

const ActivationTable: FC<{
  selectedAudienceType: number | null;
  isLoading: boolean;
  data: [];
  isActive: boolean;
  destroy: () => void;
  openModal: () => void;
  activateModal: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  attachPixel: (id: number) => void;
  setImpPixel_Code: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({
  isLoading,
  data,
  selectedAudienceType,
  activateModal,
  isActive,
  destroy,
  disabled,
  openModal,
  attachPixel,
  setImpPixel_Code,
}) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Impression Pixel',
      disabled,
    },
  ];

  const { privilegeSetDDElements } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  const shorten = (value: number) => {
    if (value !== null) return <>{roundTo(value, 1)}x</>;
    else {
      return <>---</>;
    }
  };

  function formatEstimatedSizes(size: number) {
    return size.toLocaleString();
  }

  const getStatusColumnValueJsx = (value: string) => {
    if (value === 'Pending') {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#FFF598', color: '#645A00' }}
        >
          {value}
        </span>
      );
    } else {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#D7EED6', color: '#2D912B' }}
        >
          {value}
        </span>
      );
    }
  };

  function handleActions(e: any, record: any) {
    setImpPixel_Code(record.imp_pixel_code);
    if (!record.imp_pixel_code && record.status !== 'Pending') {
      attachPixel(record.activation_id);
    }
    openModal();
  }

  const dtcColumns: TableColumnsType<IDtcDataType> = [
    {
      title: 'Activation ID',
      dataIndex: 'activation_id',
      key: 'activation_id',
      render: (text) => <>{'#' + text}</>,
      className: 'genericTableID',
      width: 'auto',
      showSorterTooltip: false,
    },
    {
      title: 'Process Date',
      dataIndex: 'created_on',
      key: 'date',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY HH:mm A'),
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Audience Filename',
      dataIndex: 'audience_name',
      key: 'Audience_name',
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Audience Size',
      dataIndex: 'audience_size',
      key: 'Audience_size',
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
      render: (text, record) => {
        return (
          <>
            <p>{text}</p>
            {record.override_size && (
              <p style={{ color: '#ACABA5' }}>
                (Override: {record.override_size})
              </p>
            )}
          </>
        );
      },
    },
    {
      title: (
        <Tooltip title="Audience Targeting Index">
          <span>ATI</span>
        </Tooltip>
      ),
      dataIndex: 'ati',
      key: 'ati',
      width: 'auto',
      showSorterTooltip: false,
      render: (value) => {
        return (
          <p>
            <p>
              {updatedDataSet.additional_option_id ? 'N/A' : shorten(value)}
            </p>
          </p>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 'auto',
      render: (text) => getStatusColumnValueJsx(text),
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      sorter: false,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                items={items}
                onClick={(e) => handleActions(e, record)}
              ></Menu>
            }
            placement="bottomRight"
            overlayClassName="actionMenu"
          >
            <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
          </Dropdown>
        );
      },
    },
  ];

  const hcpColumns: TableColumnsType<IHcpDataType> = [
    {
      title: 'Activation ID',
      dataIndex: 'activation_id',
      key: 'activation_id',
      render: (text) => <>{'#' + text}</>,
      className: 'genericTableID',
      width: 'auto',
      showSorterTooltip: false,
    },
    {
      title: 'Process Date',
      dataIndex: 'created_on',
      key: 'date',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY HH:mm A'),
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Filename',
      dataIndex: 'audience_name',
      key: 'Audience_name',
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'HCPs Delivered',
      dataIndex: 'audience_size',
      key: 'Audience_size',
      width: 'auto',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusColumnValueJsx(text),
      width: '130px',
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      width: '50px',
      sorter: false,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu
                items={items}
                onClick={(e) => handleActions(e, record)}
              ></Menu>
            }
            placement="bottomRight"
            overlayClassName="actionMenu"
          >
            <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
          </Dropdown>
        );
      },
    },
  ];

  function manipulateDTCArray(dataArray: IDtcDataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        audience_size: !item.audience_size
          ? '---'
          : formatEstimatedSizes(item.audience_size),
        score: !item.score ? '---' : formatEstimatedSizes(item.score),
        override_size: !item.override_size
          ? '---'
          : formatEstimatedSizes(item.override_size),
      };
    });
  }

  function manipulateHCPArray(dataArray: IHcpDataType[]) {
    return dataArray?.map((item: any) => {
      return {
        ...item,
        audience_size: !item.audience_size
          ? '---'
          : formatEstimatedSizes(item.audience_size),
        override_size: !item.override_size
          ? '---'
          : formatEstimatedSizes(item.override_size),
      };
    });
  }

  return (
    <div className="activationTableWrap">
      <div className="sectionHeader audienceEstimate">
        <h2>Activations</h2>
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) && (
          <Button
            className="primaryBtn"
            disabled={disabled}
            onClick={() => {
              activateModal(true);
              destroy();
            }}
            size="large"
          >
            New Activation
          </Button>
        )}
      </div>
      <Table
        columns={selectedAudienceType === 1 ? dtcColumns : hcpColumns}
        dataSource={
          selectedAudienceType === 1
            ? manipulateDTCArray(data) ?? []
            : manipulateHCPArray(data) ?? []
        }
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        locale={{
          emptyText: (
            <CustomEmptyTableComponent message="This audience has not been activated yet." />
          ),
        }}
        pagination={false}
        loading={isLoading}
        sortDirections={['ascend', 'descend', 'ascend']}
      />
    </div>
  );
};

export default ActivationTable;
