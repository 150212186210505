import { Button, Table, TableColumnsType, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import CustomEmptyTableComponent from '../../../sharedComponent/Empty/CustomEmptyTableComponent';
import {
  useFeatureSetDataHandlingStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IFeatureSetDataState,
  IUserInformationState,
} from '../../../../models/interfaces';
import { RbacSettings } from '../../../../models/enums/rbacSetting';
import { SyncOutlined } from '@ant-design/icons';
import EstimateAlert from '../../AudienceSetup/AudienceSetupComponents/EstimateAlert';
import { history } from '../../../../assets/images';

interface DTCdataType {
  estimate_id: number;
  date: string;
  size: string;
  total_scored_population_size: string;
  patient_population: number;
  ati: number;
  status_id: number;
  is_active: number;
}

interface HcpDataType {
  estimate_id: number;
  date: string;
  size: string;
  total_scored_population_size: string;
  status_id: number;
  is_active: number;
}

export function roundTo(num: number, decimals: number): number {
  return Number(num.toFixed(decimals));
}

const AudienceEstimateTable: FC<{
  isLoading: boolean;
  data: any;
  disable: boolean;
  isActive: boolean;
  showAllEstimate: boolean;
  setShowAllEstimate: React.Dispatch<React.SetStateAction<boolean>>;
  selectedAudienceType: number | null;
  generateEstimate: () => void;
}> = ({
  isLoading,
  data,
  disable,
  showAllEstimate,
  generateEstimate,
  isActive,
  setShowAllEstimate,
  selectedAudienceType,
}) => {
  const { privilegeSetDDElements, userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  const shorten = (value: number) => {
    if (value) return <>{roundTo(value, 1)}x</>;
    else {
      return <>---</>;
    }
  };

  const dateComparison = () => {
    if (updatedDataSet.estimated_info) {
      if (
        Date.parse(updatedDataSet.updated_on) >
        Date.parse(updatedDataSet.estimated_info[0].processed_at)
      )
        return true;
      else return false;
    } else {
      return false;
    }
  };

  const getStatusColumnValueJsx = (value: string) => {
    if (value === 'Pending') {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#FFF598', color: '#645A00' }}
        >
          {value}
        </span>
      );
    } else if (value === 'Error') {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#F9BFBF', color: '#CC2525' }}
        >
          {value}
        </span>
      );
    } else {
      return (
        <span
          className={`statusChip 1`}
          style={{ backgroundColor: '#D7EED6', color: '#2D912B' }}
        >
          {value}
        </span>
      );
    }
  };

  const dtcColumns: TableColumnsType<DTCdataType> = [
    {
      title: 'Estimate ID',
      dataIndex: 'estimate_id',
      key: 'estimate_id',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {'#' + text}
        </div>
      ),
      width: '100px',
      className: 'genericTableID',
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'processed_at',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {dayjs.utc(text).local().format('M/D/YYYY H:mm A')}
        </div>
      ),
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'Max Audience Size',
      dataIndex: 'total_scored_population_size',
      key: 'total_scored_population_size',
      width: '160px',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {text}
        </div>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'Total Patient Population',
      dataIndex: 'population',
      key: 'population',
      width: '180px',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {text}
        </div>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'Recommended Audience Size',
      dataIndex: 'size',
      key: 'size',
      width: '180px',
      showSorterTooltip: false,
      render: (text, record) => {
        return (
          <div className={record.is_active === 0 ? 'disabledElement' : ''}>
            <p>{text}</p>
          </div>
        );
      },
    },
    {
      title: (
        <Tooltip title="Audience Targeting Index">
          <span>ATI</span>
        </Tooltip>
      ),
      dataIndex: 'ati',
      key: 'ati',
      width: '120px',
      showSorterTooltip: false,
      render: (value, record) => {
        return (
          <p className={record.is_active === 0 ? 'disabledElement' : ''}>
            {updatedDataSet.additional_option_id ? 'N/A' : shorten(value)}
          </p>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '80px',
      showSorterTooltip: false,
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {getStatusColumnValueJsx(text)}
        </div>
      ),
    },
  ];

  const hcpColumns: TableColumnsType<HcpDataType> = [
    {
      title: 'Estimate ID',
      dataIndex: 'estimate_id',
      key: 'estimate_id',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {'#' + text}
        </div>
      ),
      width: '100px',
      className: 'genericTableID',
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'processed_at',
      key: 'processed_at',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY H:mm A'),
      width: '180px',
      showSorterTooltip: false,
    },
    {
      title: 'HCPs Found',
      dataIndex: 'size',
      key: 'size',
      width: '180px',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {text}
        </div>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'HCPs Matched',
      dataIndex: 'total_scored_population_size',
      key: 'total_scored_population_size',
      width: '160px',
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {text}
        </div>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '80px',
      showSorterTooltip: false,
      render: (text, record) => (
        <div className={record.is_active === 0 ? 'disabledElement' : ''}>
          {getStatusColumnValueJsx(text)}
        </div>
      ),
    },
  ];

  function formatEstimatedSizes(size: number) {
    return size.toLocaleString();
  }

  function manipulateDTCArray(dataArray: DTCdataType[]) {
    return (
      showAllEstimate
        ? dataArray
        : dataArray.filter((est: any) => est.is_active === 1)
    )?.map((item: any) => {
      return {
        ...item,
        total_scored_population_size: !item.total_scored_population_size
          ? '---'
          : formatEstimatedSizes(item.total_scored_population_size),
        size: !item.size ? '---' : formatEstimatedSizes(item.size),
        population: !item.population
          ? '---'
          : formatEstimatedSizes(item.population),
        score: !item.score ? '---' : formatEstimatedSizes(item.score),
      };
    });
  }

  function manipulateHCPArray(dataArray: HcpDataType[]) {
    return (
      showAllEstimate
        ? dataArray
        : dataArray.filter((est: any) => est.is_active === 1)
    )?.map((item: any) => {
      return {
        ...item,
        total_scored_population_size: !item.total_scored_population_size
          ? '---'
          : formatEstimatedSizes(item.total_scored_population_size),
        size: !item.size ? '---' : formatEstimatedSizes(item.size),
      };
    });
  }

  function getDaysFromDate(dateString: string): number {
    const inputDate = new Date(dateString);

    if (isNaN(inputDate.getTime())) {
      throw new Error('Invalid date string provided');
    }

    const currentDate = new Date();

    const differenceInMilliseconds = Math.abs(
      currentDate.getTime() - inputDate.getTime()
    );

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.floor(
      differenceInMilliseconds / millisecondsPerDay
    );

    return differenceInDays;
  }

  const getRemainDays = (value: string) => {
    const givenDate = new Date(value).getTime();
    const currentDate = Date.now();
    const remainingTime = givenDate + 45 * 24 * 60 * 60 * 1000 - currentDate;

    return Math.ceil(remainingTime / (24 * 60 * 60 * 1000));
  };

  const isEstimateEnabled = () => {
    const isUserAdmin = userInformation.role === 4;
    const isRecentlyProcessed = getDaysFromDate(data[0].processed_at) < 45;
    const isActiveOrDisabled = isActive || disable;

    if (isUserAdmin && !isActiveOrDisabled) return false;
    if (isActiveOrDisabled && isRecentlyProcessed) return true;
  };

  const saveEstimate = () => {
    generateEstimate();
  };

  return (
    <>
      <div className="sectionHeader audienceEstimate">
        <h2>Audience Estimates</h2>
        {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) &&
          data.length === 0 && (
            <Button
              className="primaryBtn"
              disabled={isActive || disable}
              onClick={saveEstimate}
              size="large"
            >
              Generate Estimate
            </Button>
          )}
        {data.length > 0 && (
          <div>
            {data.length > 1 && (
              <Tooltip title="Toggle History">
                <button
                  className={`noBGBtn historyBtn `}
                  onClick={() => setShowAllEstimate(!showAllEstimate)}
                >
                  <img src={history} alt="history" />
                </button>
              </Tooltip>
            )}
            {privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD) &&
              data.length > 0 && (
                <>
                  {updatedDataSet.status_id !== 2 && (
                    <Button
                      className="primaryBtn generateEstimateBtn"
                      disabled={isEstimateEnabled()}
                      onClick={saveEstimate}
                      size="large"
                    >
                      <SyncOutlined />
                      <p>Update Estimate</p>
                    </Button>
                  )}
                  {userInformation.role === 4 &&
                    updatedDataSet.status_id === 2 && (
                      <Button
                        className="primaryBtn generateEstimateBtn"
                        disabled={isEstimateEnabled()}
                        onClick={saveEstimate}
                        size="large"
                      >
                        <SyncOutlined />
                        <p>Update Estimate</p>
                      </Button>
                    )}
                  {userInformation.role !== 4 &&
                    updatedDataSet.status_id === 2 && (
                      <Tooltip
                        title={`Locked for ${getRemainDays(
                          data[0].processed_at
                        )} more days. To rerun the estimate, update the audience definition.`}
                      >
                        <Button
                          className="primaryBtn generateEstimateBtn"
                          disabled={isEstimateEnabled()}
                          onClick={saveEstimate}
                          size="large"
                        >
                          <SyncOutlined />
                          <p>Update Estimate</p>
                        </Button>
                      </Tooltip>
                    )}
                </>
              )}
          </div>
        )}
      </div>

      {data.length > 0 &&
        data[0].is_valid === 0 &&
        data[0].error_code &&
        data[0].reason && (
          <EstimateAlert
            className="audienceAlertError"
            msgBody={<p>Error: {data[0].reason}</p>}
          />
        )}

      {data.length > 0 &&
        data[0].is_valid === 1 &&
        data[0].error_code === 1 &&
        data[0].reason && (
          <EstimateAlert
            className="audienceAlertWarning"
            msgBody={<p>Warning: {data[0].reason}</p>}
          />
        )}

      {dateComparison() && (
        <EstimateAlert
          className="audienceAlertWarning"
          msgBody={
            <p>
              Warning: The audience definition has changed. Update this estimate
              for accurate results
            </p>
          }
        />
      )}

      <Table
        columns={selectedAudienceType === 1 ? dtcColumns : hcpColumns}
        dataSource={
          selectedAudienceType === 1
            ? manipulateDTCArray(data) ?? []
            : manipulateHCPArray(data) ?? []
        }
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        locale={{
          emptyText: (
            <CustomEmptyTableComponent message="This audience has not been estimated yet." />
          ),
        }}
        loading={isLoading}
        pagination={false}
        sortDirections={['ascend', 'descend', 'ascend']}
        scroll={data.length > 0 ? { x: 'max-content' } : undefined}
      />
    </>
  );
};

export default AudienceEstimateTable;