/* eslint-disable react-hooks/exhaustive-deps */
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IGlobalFeatureSetBaseData,
  IGlobalFeatureState,
  IMasterDmaDDState,
  IMasterGenderDDState,
  IMasterLanguageDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterTargetingDDState,
  IMasterTimeFrameDDState,
} from '../../../../models/interfaces';
import { Select } from 'antd';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterGenderElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterStateElementsStore,
  useMasterTargetingElementsStore,
  useMasterTimeFrameElementsStore,
} from '../../../../stores';
import { featureSetList } from './featureSetList';
import dayjs from 'dayjs';
import {
  useMasterDmaElementsStore,
  useMasterLanguageElementStore,
} from '../../../../stores/masterData.store';
import {
  useAudienceSaveMethods,
  useAudienceSets,
  useAudienceSetUp,
  useGlobalFeatureSet,
} from '../../../../stores/audienceSets.store';

const baseValues: IGlobalFeatureSetBaseData = {
  timeFrame: null,
  dateRange: null,
  patientGender: null,
  selectedAge: [18,100],
  selectedRaceAndEthnicity: [],
  selectedGeographyDmas: [],
  selectedGeographyStates: [],
  language: [],
};

const GlobalFeatureSet: FC<{
  featureSetNumber: number;
  featureSetOrderNumber: number;
  disabled?: boolean;
}> = ({ disabled, featureSetNumber, featureSetOrderNumber }) => {
  const { targetingDDElements } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { raceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { languageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLanguageDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    {
      updateIisFeatureSetsFilled,
      isEnableSaveForEachSet,
      updateIsEnableSaveForEachSet,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { dmaDDElements, updateDmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { updateIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    { isGlobalDisabled } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    ),
    { setDeletedIds, deletedIds, audienceSummary } = useAudienceSets(
      (state: IAudienceState) => state
    ),
    {
      globalFeatureSet,
      updateGlobalFeatureSet,
      globalTargeting,
      setGlobalTargeting,
      setIsGlobalModified,
      updateGlobalTargeting,
    } = useGlobalFeatureSet((state: IGlobalFeatureState) => state);

  const [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>(
      []
    ),
    [initialFeatureSet, setInitialFeatureSet] =
      useState<IGlobalFeatureSetBaseData | null>(null);

  const checkForChanges = (
    currentSet: IGlobalFeatureSetBaseData,
    initialSet: IGlobalFeatureSetBaseData | null
  ) => {
    if (!initialSet) return false;

    // Helper function to compare arrays
    const areArraysEqual = (arr1: any[] | null, arr2: any[] | null) => {
      if (!arr1 && !arr2) return true;
      if (!arr1 || !arr2) return false;
      if (arr1.length !== arr2.length) return false;
      return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
    };

    // Compare each field
    const hasChanges =
      currentSet.timeFrame !== initialSet.timeFrame ||
      JSON.stringify(currentSet.dateRange) !==
        JSON.stringify(initialSet.dateRange) ||
      currentSet.patientGender !== initialSet.patientGender ||
      !areArraysEqual(currentSet.selectedAge, initialSet.selectedAge) ||
      !areArraysEqual(
        currentSet.selectedRaceAndEthnicity,
        initialSet.selectedRaceAndEthnicity
      ) ||
      !areArraysEqual(
        currentSet.selectedGeographyDmas,
        initialSet.selectedGeographyDmas
      ) ||
      !areArraysEqual(
        currentSet.selectedGeographyStates,
        initialSet.selectedGeographyStates
      ) ||
      !areArraysEqual(currentSet.language, initialSet.language);

    return hasChanges;
  };

  useEffect(() => {
    if (!initialFeatureSet && globalTargeting.length > 0) {
      setInitialFeatureSet({ ...globalFeatureSet });
    }
  }, [globalTargeting, initialFeatureSet]);

  useEffect(() => {
    if (initialFeatureSet) {
      const modified = checkForChanges(globalFeatureSet, initialFeatureSet);
      setIsGlobalModified(modified);
    }
  }, [globalFeatureSet, initialFeatureSet]);

  const targetableOptionsRef = useRef<any[]>([]);

  useEffect(() => {
    updateDmaDDElements(featureSetNumber, [], stateDDElements);
  }, []);

  useEffect(() => {
    if (
      targetableOptionsRef.current.length === 0 &&
      Object.keys(updatedDataSet).length === 0
    ) {
      let newOptions = targetingDDElements
        .filter(
          (tar) =>
            !tar.is_hpa && tar.value !== 21 && tar.value !== 22 && tar.is_global
        )
        .sort((a: any, b: any) => a.value - b.value);

      targetableOptionsRef.current = [...newOptions];
    }
  }, []);

  useEffect(() => {
    if (
      updatedDataSet?.feature_sets?.length > 0 ||
      globalTargeting.length !== 0
    ) {
      let newOption = targetingDDElements.filter(
        (target: any) =>
          !globalTargeting.includes(target.value) &&
          !target.is_hpa &&
          target.value !== 21 &&
          target.value !== 22 &&
          target.is_global
      );
      const isTargetingId5 = globalTargeting.includes(5);
      const isTargetingId15 = globalTargeting.includes(15);
      const isExist = isTargetingId15 ? 5 : 30;
      const enableValue = isTargetingId5 ? 15 : isExist;
      const updatedOptionsWithEnabled = newOption.map((opt: any) =>
        opt.value === enableValue ? { ...opt, disabled: true } : opt
      );
      targetableOptionsRef.current = [...updatedOptionsWithEnabled];
    }
  }, [globalTargeting]);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      if (Array.isArray(data)) {
        updateGlobalFeatureSet(data, key, true);
      } else {
        updateGlobalFeatureSet(data, key, false);
      }
    }
  };

  function handleSetDelete(setIds: [number, keyof IGlobalFeatureSetBaseData]) {
    const newTargetingElemArray = globalTargeting.filter(
      (el) => setIds[0] !== el
    );
    if (globalTargeting.includes(setIds[0])) {
      setDeletedIds([setIds[0]]);
    }
    setGlobalTargeting(newTargetingElemArray);
    updateGlobalFeatureSet(baseValues[setIds[1]], setIds[1], false);

    const matchingTarget = targetingDDElements.find(
      (tar: any) => tar.value === setIds[0]
    );

    let disableValue: number | null = null;

    if (setIds[0] === 5) {
      disableValue = 15;
    } else if (setIds[0] === 15) {
      disableValue = 5;
    }

    const updatedOptionsWithEnabled = [...targetableOptionsRef.current].map(
      (opt: any) =>
        opt.value === disableValue ? { ...opt, disabled: false } : opt
    );

    if (matchingTarget) {
      targetableOptionsRef.current = [
        ...[...updatedOptionsWithEnabled, matchingTarget].sort(
          (a: any, b: any) => a.value - b.value
        ),
      ];
    }
  }

  const getParams = () => {
    const params: IFeatureSetParamDatum[] = [
      {
        value: 1,
        values: [globalFeatureSet.timeFrame, globalFeatureSet.dateRange],
        names: ['timeFrame', 'dateRange'],
        base: [baseValues.timeFrame, baseValues.dateRange],
        param: {
          setId: 1,
          featureSetNumber,
          label: 'Timeframe',
          options: timeFrameDDElements,
          placeholder: 'Select Timeframe',
          isSearchable: false,
          timeFrame: globalFeatureSet.timeFrame,
          dateRange: globalFeatureSet.dateRange,
          is_feature: true,
          setTimeFrame: (data: number) => {
            handleFeatureSetUpdates('timeFrame', data);
          },
          setDateRange: (data: string[]) => {
            handleFeatureSetUpdates('dateRange', data);
          },
          handleSetDelete,
          minCustomDate: dayjs('2022-01-01'),
          isDisabled: disabled,
        },
      },
      {
        value: 2,
        values: [globalFeatureSet.patientGender],
        names: ['patientGender'],
        base: [baseValues.patientGender],
        param: {
          setId: 2,
          featureSetNumber,
          label: 'Patient Gender',
          options: genderDDElements,
          placeholder: 'Select Patient Gender',
          isSearchable: false,
          isDisabled: disabled,
          is_feature: true,
          onChange: (key: string, data: any) => {
            handleFeatureSetUpdates('patientGender', data.value ?? data);
          },
          otherProps: { defaultValue: globalFeatureSet.patientGender },
          handleSetDelete,
        },
      },
      {
        value: 3,
        values: [globalFeatureSet.selectedAge],
        names: ['selectedAge'],
        base: [baseValues.selectedAge],
        param: {
          setId: 3,
          featureSetNumber,
          label: 'Patient Age',
          is_feature: true,
          defaultValue: globalFeatureSet.selectedAge?.length
            ? globalFeatureSet.selectedAge
            : baseValues.selectedAge,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedAge', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 4,
        values: [globalFeatureSet.selectedRaceAndEthnicity],
        names: ['selectedRaceAndEthnicity'],
        base: [baseValues.selectedRaceAndEthnicity],
        param: {
          setId: 4,
          featureSetNumber,
          label: 'Ethnicity',
          is_feature: true,
          options: raceAndEthnicityDDElements,
          placeholder: 'Select Ethnicity',
          isSearchable: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedRaceAndEthnicity', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: globalFeatureSet.selectedRaceAndEthnicity,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 5,
        values: [globalFeatureSet.selectedGeographyStates],
        names: ['selectedGeographyStates'],
        base: [baseValues.selectedGeographyStates],
        param: {
          setId: 5,
          featureSetNumber,
          label: 'State',
          options: stateDDElements,
          is_feature: true,
          placeholder: 'Select State',
          isSearchable: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedGeographyStates', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: globalFeatureSet.selectedGeographyStates,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 15,
        values: [globalFeatureSet.selectedGeographyDmas],
        names: ['selectedGeographyDmas'],
        base: [baseValues.selectedGeographyDmas],
        param: {
          setId: 15,
          label: 'DMA',
          isSearchable: true,
          is_feature: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedGeographyDmas', data);
          },
          handleSetDelete,
          value: globalFeatureSet.selectedGeographyDmas,
          featureSetNumber,
          isDisabled: disabled,
        },
      },
      {
        value: 19,
        values: [globalFeatureSet.language],
        names: ['language'],
        base: [baseValues.language],
        param: {
          setId: 19,
          featureSetNumber,
          label: 'Language',
          options: languageDDElements,
          placeholder: 'Select Language',
          isSearchable: true,
          is_feature: true,
          onChange: (data: any) => {
            handleFeatureSetUpdates('language', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: globalFeatureSet.language,
          },
          isDisabled: disabled,
        },
      },
    ];
    return params;
  };

  useEffect(() => {
    if (deletedIds?.length) {
      setDeletedIds(null);
      const params = getParams(),
        selectedParam: IFeatureSetParamDatum[] = params.filter((el: any) =>
          deletedIds?.includes(el.value)
        ),
        // tempFeatureSetDataForMessage = { ...featureSetDataForMessage },
        dependentTargets = [6, 7, 8];

      selectedParam?.length &&
        selectedParam.forEach((eachParam: IFeatureSetParamDatum) => {
          if (dependentTargets.includes(eachParam.value)) {
            updateIsDependentTargetingSelectedForEachSet(
              featureSetNumber,
              false
            );
          }
        });
      /**setFeatureSetDataForMessage({ ...tempFeatureSetDataForMessage });*/
    }
    if (globalTargeting?.length) {
      if (
        globalTargeting.includes(3) &&
        !globalFeatureSet.selectedAge?.length
      ) {
        handleFeatureSetUpdates('selectedAge', baseValues.selectedAge);
      }

      const params = getParams(),
        updatedElem: IFeatureSetElement[] = [],
        dependentTargets = [6, 7, 8];

      updateIsDependentTargetingSelectedForEachSet(
        featureSetNumber,
        Boolean(globalTargeting.find((el) => dependentTargets.includes(el)))
      );

      globalTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });
      setFeatureSetBody(updatedElem);
    } else {
      setFeatureSetBody([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTargeting]);

  useEffect(() => {
    if (globalTargeting?.length) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();

      globalTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem: any) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTargeting]);

  const performValidations = () => {
    validationForNonArray(1, globalFeatureSet.timeFrame);
    validationDateRange();
    validationForNonArray(2, globalFeatureSet.patientGender);
    validationForArray(4, globalFeatureSet.selectedRaceAndEthnicity);
    validationForArray(5, globalFeatureSet.selectedGeographyStates);
    validationForArray(15, globalFeatureSet.selectedGeographyDmas);
    validationForArray(19, globalFeatureSet.language);
  };

  const validationForNonArray = (tId: number, data: any) => {
    if (globalTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data ? 1 : 2);
      if (!data) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationForArray = (tId: number, data: any) => {
    if (globalTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationDateRange = () => {
    if (
      globalTargeting.includes(1) &&
      globalFeatureSet.timeFrame === 3 &&
      !globalFeatureSet.dateRange &&
      isSaveInitiated
    ) {
      updateIisFeatureSetsFilled(
        featureSetNumber,
        17,
        !globalFeatureSet.dateRange ? 1 : 2
      );
      if (!globalFeatureSet.dateRange) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, 17, 0);
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTargeting, globalFeatureSet]);

  useEffect(() => {
    performValidations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSaveEnabled = () => {
    switch (true) {
      case !globalTargeting?.length:
        return 0;
      case globalTargeting.includes(1) &&
        (!globalFeatureSet.timeFrame ||
          (globalFeatureSet.timeFrame === 3 && !globalFeatureSet.dateRange)):
        return 1;
      case globalTargeting.includes(2) && !globalFeatureSet.patientGender:
      case globalTargeting.includes(4) &&
        !globalFeatureSet.selectedRaceAndEthnicity?.length:
      case globalTargeting.includes(5) &&
        !globalFeatureSet.selectedGeographyStates?.length:
      case globalTargeting.includes(15) &&
        !globalFeatureSet.selectedGeographyDmas?.length:
      case globalTargeting.includes(19) && !globalFeatureSet.language?.length:
        return 1;
      default:
        return 2;
    }
  };

  const handleTargetableOptions = (value: number) => {
    updateGlobalTargeting(value);
    let updatedOptions = [...targetableOptionsRef.current].filter(
      (opt: any) => opt.value !== value
    );
    if (value === 5 || value === 15) {
      const disabledValue = value === 5 ? 15 : 5;
      updatedOptions = updatedOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
    }

    targetableOptionsRef.current = [...updatedOptions];
  };

  useEffect(() => {
    const newDmaList = dmaDDElements[`${featureSetNumber}`]
      ? [...dmaDDElements[`${featureSetNumber}`]]
      : [];

    if (globalFeatureSet?.selectedGeographyDmas?.length) {
      const newSelectedDma = newDmaList
        .filter((el) =>
          globalFeatureSet.selectedGeographyDmas.includes(el.value)
        )
        ?.map((el) => el.value);
      handleFeatureSetUpdates('selectedGeographyDmas', newSelectedDma);
    }
  }, [dmaDDElements]);

  const getFormattedData = (message?: string) => {
    const tempData: any = {
        ...globalFeatureSet,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });
    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_summary: audienceSummary ?? '',
        feature_set_rank: featureSetOrderNumber,
        condition_id: 5,
        is_global: true,
        is_caregiver: false,
      },
      targeting: any = [],
      duals: any = {
        dateRange: 17,
        selectedInsuranceProviders: 16,
      };

    for (const key in globalFeatureSet) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1) ||
          (key === 'timeFrame' &&
            globalFeatureSet.timeFrame === 3 &&
            !globalFeatureSet.dateRange)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = Object.keys(duals).includes(key)
            ? duals[key]
            : targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;
        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank:
            globalTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (globalTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (
      ifUpdated &&
      updatedDataSet?.feature_sets?.length &&
      globalTargeting?.length === 0
    ) {
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) => el.feature_set_id === featureSetNumber
      );

      if (featureSet) {
        const params = getParams(),
          duals: any = { 17: 1, 16: 12 },
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [];

        targetingSet.forEach((el: any) => {
          const keys = Object.keys(duals);

          if (keys.includes(el.targeting_id.toString())) {
            const index: number = newTargetingSet.findIndex(
              (elem: any) => elem.targeting_id === duals[`${el.targeting_id}`]
            );

            newTargetingSet[index] = {
              ...newTargetingSet[index],
              targeting_value: [
                ...newTargetingSet[index].targeting_value,
                JSON.parse(el.targeting_value),
              ],
              uniqueIds: [...newTargetingSet[index].uniqueIds, el.targeting_id],
            };
          } else {
            newTargetingSet.push({
              ...el,
              targeting_value:
                el.targeting_id !== 14
                  ? [JSON.parse(el.targeting_value)]
                  : el.targeting_value,
              uniqueIds: [el.targeting_id],
            });
          }
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            const onChange = param?.param?.onChange;
            if (onChange !== undefined) {
              if (el.uniqueIds[0] === 2) {
                onChange(param.names[0], el.targeting_value[0]);
              } else {
                onChange(el.targeting_value[0]);
              }
            } else if (el.uniqueIds[0] === 1) {
              param.param.setTimeFrame(el.targeting_value[0]);
              param.param.setDateRange(el.targeting_value[1]);
            }
          }
        });
        /**setFeatureSetDataForMessagePrev(tempFeatureSetDataForMessage);
        setFeatureSetDataForMessage(tempFeatureSetDataForMessage);*/
        setGlobalTargeting(
          usableTargetingSet.map((el: any) => el.targeting_id) ?? []
        );
        const targetingIds = usableTargetingSet.map(
          (el: any) => el.targeting_id
        );

        const newElements = targetingDDElements.filter(
          (target: any) =>
            !targetingIds.includes(target.value) &&
            !target.is_hpa &&
            target.is_global &&
            target.value !== 21 &&
            target.value !== 22
        );

        const isTargetingId5 = targetingIds.includes(5);
        const isTargetingId15 = targetingIds.includes(15);
        const isExist = isTargetingId15 ? 5 : 30;
        const disabledValue = isTargetingId5 ? 15 : isExist;

        // Update elements by disabling the one matching the disabled value
        const updatedElements = newElements.map((element: any) =>
          element.value === disabledValue
            ? { ...element, disabled: true }
            : element
        );

        // Update the reference with the new list of elements
        targetableOptionsRef.current = [...updatedElements];
      } else {
        let newOptions = targetingDDElements
          .filter(
            (tar) =>
              !tar.is_hpa &&
              tar.value !== 21 &&
              tar.value !== 22 &&
              tar.is_global
          )
          .sort((a: any, b: any) => a.value - b.value);

        targetableOptionsRef.current = [...newOptions];
      }
    } else if (globalTargeting.length) {
      let newOptions = targetingDDElements.filter(
        (tar) =>
          !globalTargeting.includes(Number(tar.value)) &&
          !tar.is_hpa &&
          tar.is_global &&
          tar.value !== 21 &&
          tar.value !== 22
      );

      const isTargetingId5 = globalTargeting.includes(5);
      const isTargetingId15 = globalTargeting.includes(15);
      const isExist = isTargetingId15 ? 5 : 30;
      const disabledValue = isTargetingId5 ? 15 : isExist;

      const updatedElements = newOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
      targetableOptionsRef.current = [...updatedElements];
    } else {
      let newOptions = targetingDDElements
        .filter(
          (tar) =>
            !tar.is_hpa && tar.value !== 21 && tar.value !== 22 && tar.is_global
        )
        .sort((a: any, b: any) => a.value - b.value);

      targetableOptionsRef.current = [...newOptions];
    }
  }, []);

  return (
    <>
      <div className="audienceDefinitionElem">
        <div className="audienceDefinitionleft">
          <p>Patient Profile</p>
        </div>

        <div className="audienceDefinitionRight">
          {globalTargeting.length > 0 && (
            <div>
              {featureSetBody.map((el: IFeatureSetElement) => (
                <Fragment key={`${el.orderId}_${el.setId}`}>
                  {el.element}
                </Fragment>
              ))}
            </div>
          )}
          {targetableOptionsRef.current.length > 0 && (
            <Select
              options={targetableOptionsRef.current}
              placeholder="Choose"
              style={{ width: 190 }}
              onChange={(data: number) => handleTargetableOptions(data)}
              value={null}
              disabled={isGlobalDisabled || disabled}
            />
          )}
        </div>
      </div>
      <div className="intermediateContent"><span>And</span><span className='pipe'></span></div>
    </>
  );
};

export default GlobalFeatureSet;

