import { FC, Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import {
  IActiveAudienceListingParam,
  ILoaderState,
} from '../../../../models/interfaces';
import { useLoaderStore } from '../../../../stores';
import { ActiveAudienceListingTable } from './';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { Button, ConfigProvider, TableColumnsType, Tooltip } from 'antd';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { ColumnSelectorIcon, filter } from '../../../../assets/images';
import { useIsTablet, usePostApiInterceptor } from '../../../../hooks';
import { ColumnSelector } from '../../../sharedComponent/ColumnSelector';

type activationAudBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  title: string | null;
  order_column: string | null;
  order_direction: string | null;
  partner: number | null;
  brand_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  filter_option: number | null;
  timeframe: string | null;
};
interface DataType {
  title: string;
  act_partners: string[];
  audience_id: number;
  agency: string;
  advertiser: string;
  brand: string;
  type: string;
  act_audience_sizes: number[];
  act_creation_dates: string[];
}

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  rowsPerPage: 15,
  dateRange: null,
  status: null,
  partner: null,
  title: '',
  agency: null,
  brand: null,
  advertiser: null,
  audienceType: null,
  timeframe: null,
  filter_option: 1,
};

const convertFiltersToNumberArray = (
  filters: any
): IActiveAudienceListingParam => {
  const result: Partial<IActiveAudienceListingParam> = {
    sortOrder: filters.activation.sortOrder,
    sortColumn: filters.activation.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: filters.activation.rowsPerPage,
  };

  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }

  if (filters.activation) {
    if (filters.activation.title) result.title = filters.activation.title;
    if (filters.activation.filter_option)
      result.filter_option = filters.activation.filter_option;
    if (filters.activation.partner)
      result.partner = filters.activation.partner.toString();
  }

  return result as IActiveAudienceListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const ActiveAudienceListingWrap: FC = () => {
  const isTablet = useIsTablet();
  const filters = JSON.parse(localStorage.getItem('Filters') ?? '{}'),
    columnList = JSON.parse(localStorage.getItem('columns') ?? '{}'),
    [activationAudListingParam, setActivationAudListingParam] =
      useState<IActiveAudienceListingParam>(
        Object.keys(filters.activation ?? {}).length > 0
          ? convertFiltersToNumberArray(filters)
          : baseListingParam
      );

  const [body, setBody] = useState<activationAudBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [checkedList, setCheckedList] = useState<string[]>(
      columnList.activations ?? [
        'agency',
        'advertiser',
        'brand',
        'size',
        'type',
        'action',
        'created_on',
        'audiencePartner',
      ]
    ),
    [columnPickerOpen, setColumnPickerOpen] = useState<boolean>(false),
    [isFilterModalOpen, setIsFilterModalOpen] = useState<boolean>(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_ALL_ACTIVATIONS,
    () => {
      setIsMounted(false);
    }
  );

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    const newParams: activationAudBodyType = {
      agency_id: activationAudListingParam.agency?.length
        ? activationAudListingParam.agency.toString()
        : null,
      advertiser_id: activationAudListingParam.advertiser?.length
        ? activationAudListingParam.advertiser.toString()
        : null,
      brand_id: activationAudListingParam.brand?.length
        ? activationAudListingParam.brand.toString()
        : null,
      timeframe: activationAudListingParam.timeframe,
      end_date: activationAudListingParam.dateRange?.length
        ? activationAudListingParam.dateRange[1]
        : null,
      start_date: activationAudListingParam.dateRange?.length
        ? activationAudListingParam.dateRange[0]
        : null,
      title: activationAudListingParam.title,
      filter_option: activationAudListingParam.filter_option,
      limit: activationAudListingParam.rowsPerPage,
      off_set:
        (activationAudListingParam.pageNumber - 1) *
        activationAudListingParam.rowsPerPage,
      order_column: activationAudListingParam.sortColumn,
      order_direction: activationAudListingParam.sortOrder,
      partner: activationAudListingParam.partner,
    };

    setBody(newParams);
  }, [activationAudListingParam]);

  const applyFilters = (data: any) => {
    let filterData = {
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      limit: activationAudListingParam.rowsPerPage,
      off_set:
        (activationAudListingParam.pageNumber - 1) *
        activationAudListingParam.rowsPerPage,
      title: data.title,
      order_column: activationAudListingParam.sortColumn,
      order_direction: activationAudListingParam.sortOrder,
      partner: data.partner?.length ? data.partner?.toString() : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      audience_type: data.audienceType?.length
        ? data.audienceType.toString()
        : null,
      filter_option: data.filter_option,
      timeframe: data.timeframe,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') ?? '{}'),
      global: {
        agency: filterData.agency_id,
        advertiser: filterData.advertiser_id,
        brand: filterData.brand_id,
        dateRange:
          filterData.start_date && filterData.end_date
            ? [filterData.start_date, filterData.end_date]
            : null,
        timeframe: filterData.timeframe,
      },
      activation: {
        title: filterData.title,
        filter_option: filterData.filter_option,
        partner: filterData.partner,
        rowsPerPage: activationAudListingParam.rowsPerPage,
        sortColumn: activationAudListingParam.sortColumn,
        sortOrder: activationAudListingParam.sortOrder,
      },
    };
    setActivationAudListingParam({
      audienceType: data.audienceType?.length ? data.audienceType : null,
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title,
      filter_option: data.filter_option,
      timeframe: data.timeframe ?? null,
      rowsPerPage: activationAudListingParam.rowsPerPage,
      pageNumber: activationAudListingParam.pageNumber,
      sortColumn: activationAudListingParam.sortColumn,
      sortOrder: activationAudListingParam.sortOrder,
      partner: data.partner?.length ? data.partner?.toString() : null,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
    });
    setBody(filterData);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setIsFilterModalOpen(false);
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    let existingFilters = JSON.parse(localStorage.getItem('Filters') ?? '{}');

    let filterStorage = {
      ...existingFilters,
      activation: {
        ...existingFilters.activation,
        rowsPerPage: activationAudListingParam.rowsPerPage,
        sortColumn: activationAudListingParam.sortColumn,
        sortOrder: activationAudListingParam.sortOrder,
      },
    };

    localStorage.setItem('Filters', JSON.stringify(filterStorage));
  }, [
    activationAudListingParam.rowsPerPage,
    activationAudListingParam.sortColumn,
    activationAudListingParam.sortOrder,
  ]);

  const Columns: TableColumnsType<DataType> = [
    { title: 'ID', dataIndex: 'audience_id', key: 'audience_id' },
    { title: 'Audience & Partner', dataIndex: 'title', key: 'audiencePartner' },
    { title: 'Agency', dataIndex: 'agency', key: 'agency' },
    { title: 'Advertiser', dataIndex: 'advertiser', key: 'advertiser' },
    { title: 'Brand', dataIndex: 'brand', key: 'brand' },
    { title: 'Type', dataIndex: 'audience_type', key: 'type' },
    { title: 'Size', dataIndex: 'act_audience_sizes', key: 'size' },
    { title: 'Created', dataIndex: 'act_creation_dates', key: 'created_on' },
  ];

  const options = Columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  useEffect(() => {
    const tabletColumns = [
      'agency',
      'advertiser',
      'brand',
      'size',
      'type',
      'action',
      'created_on',
      'audiencePartner',
    ];
    const newColumnList = { ...columnList };
    newColumnList['activations'] = isTablet ? tabletColumns : checkedList;
    localStorage.setItem('columns', JSON.stringify(newColumnList));
    if (
      JSON.stringify(newColumnList['activations']) !==
      JSON.stringify(checkedList)
    ) {
      setCheckedList(newColumnList['activations']);
    }
  }, [isTablet]);

  useEffect(() => {
    if (!isTablet) {
      const newColumnList = { ...columnList };
      newColumnList['activations'] = checkedList;
      localStorage.setItem('columns', JSON.stringify(newColumnList));
    }
  }, [checkedList]);

  return (
    <div className="audienceCommonWrap activeAudienceCommonWrap">
      <PageHeader title="Activations" />
      <div className="audienceListingPageContent">
        {!loaderState && (
          <Fragment>
            <div className="filterWrap noRightSideCTA">
              <ConfigProvider wave={{ disabled: true }}>
                <Tooltip title="Configure Columns">
                  <Button onClick={() => setColumnPickerOpen(true)}>
                    <img src={ColumnSelectorIcon} alt="" />
                  </Button>
                </Tooltip>
                <Tooltip title="Filter Results">
                  <Button onClick={() => setIsFilterModalOpen(true)}>
                    <img src={filter} alt="" />
                  </Button>
                </Tooltip>
              </ConfigProvider>
              {(activationAudListingParam.agency ||
                activationAudListingParam.advertiser ||
                activationAudListingParam.brand ||
                activationAudListingParam.audienceType ||
                activationAudListingParam.timeframe ||
                activationAudListingParam.dateRange ||
                activationAudListingParam.title ||
                activationAudListingParam.partner) && (
                <FilterMessage
                  type="activation"
                  data={activationAudListingParam}
                />
              )}

              <ColumnSelector
                isOpen={columnPickerOpen}
                onOk={() => {
                  setColumnPickerOpen(false);
                }}
                col={checkedList}
                setCol={setCheckedList}
                tableOptions={options}
                onCancel={() => setColumnPickerOpen(false)}
              />

              <GlobalFilterModal
                isOpen={isFilterModalOpen}
                filterData={activationAudListingParam}
                type="activation"
                handleOk={applyFilters}
                handleCancel={handleCancel}
              />
            </div>
            <ActiveAudienceListingTable
              activationData={data}
              isLoading={isLoading}
              checkedList={checkedList}
              paginationAndSortingData={activationAudListingParam}
              setPaginationAndSortingData={setActivationAudListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ActiveAudienceListingWrap;
