import React, { Fragment, useEffect, useState } from 'react';
import { PageHeader } from '../../../sharedComponent';
import PixelListTable from './PixelListTable';
import { Button, ConfigProvider, TableColumnsType, Tooltip } from 'antd';
import {
  IfilterProps,
  ILoaderState,
  IPixelListingParam,
} from '../../../../models/interfaces';
import {
  useIsTablet,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { useLoaderStore } from '../../../../stores';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFilterModal from '../../../sharedComponent/GlobalFilterModal/GlobalFilterModal';
import { FilterMessage } from '../../../sharedComponent/FilterMessage';
import { filter, ColumnSelectorIcon } from '../../../../assets/images';
import { ColumnSelector } from '../../../sharedComponent/ColumnSelector';

interface DataType {
  key: number;
  title: string;
  pixel_domain: string;
  created_on: string;
  updated_on: string;
}

type PixelBodyType = {
  end_date: string | null;
  start_date: string | null;
  limit: number;
  off_set: number;
  order_column: string | null;
  order_direction: string | null;
  title: string | null;
  brand_id: string | null;
  agency_id: string | null;
  advertiser_id: string | null;
  filter_option: number | null;
  timeframe: string | null;
};

const baseListingParam = {
  sortOrder: 'DESC',
  sortColumn: 'created_on',
  pageNumber: 1,
  timeframe: null,
  rowsPerPage: 15,
  dateRange: null,
  title: null,
  filter_option: 1,
  agency: null,
  brand: null,
  advertiser: null,
};

const convertFiltersToNumberArray = (filters: any): IPixelListingParam => {
  const result: Partial<IPixelListingParam> = {
    sortOrder: filters.pixel.sortOrder,
    sortColumn: filters.pixel.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: filters.pixel.rowsPerPage,
  };

  if (filters.global) {
    if (filters.global.agency)
      result.agency = convertToNumberArray(filters.global.agency);
    if (filters.global.advertiser)
      result.advertiser = convertToNumberArray(filters.global.advertiser);
    if (filters.global.brand)
      result.brand = convertToNumberArray(filters.global.brand);
    if (filters.global.dateRange) result.dateRange = filters.global.dateRange;
    if (filters.global.timeframe) result.timeframe = filters.global.timeframe;
  }

  if (filters.pixel) {
    if (filters.pixel.title) result.title = filters.pixel.title;
    if (filters.pixel.filter_option)
      result.filter_option = filters.activation.filter_option;
  }

  return result as IPixelListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const PixelListingWrap = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') ?? '{}');
  const columnList = JSON.parse(localStorage.getItem('columns') ?? '{}');
  const { redirectPixelSetup } = useRedirection();
  const isTablet = useIsTablet();
  const [pixelListingParam, setPixelListingParam] =
      useState<IPixelListingParam>(
        Object.keys(filters.pixel ?? {}).length > 0
          ? convertFiltersToNumberArray(filters)
          : baseListingParam
      ),
    [body, setBody] = useState<PixelBodyType | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [checkedList, setCheckedList] = useState<string[]>(
      columnList.pixels ?? [
        'title',
        'agency',
        'brand',
        'advertiser',
        'pixel_domain',
        'created_on',
        'udpated_on',
      ]
    ),
    [columnPickerOpen, setColumnPickerOpen] = useState<boolean>(false),
    [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { data, isLoading } = usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.PIXEL_LISTING_DATA,
    () => {
      setIsMounted(false);
    }
  );

  const { loaderState } = useLoaderStore((state: ILoaderState) => state);

  useEffect(() => {
    setIsMounted(true);
  }, [body]);

  useEffect(() => {
    setBody({
      end_date: pixelListingParam.dateRange?.length
        ? pixelListingParam.dateRange[1]
        : null,
      filter_option: pixelListingParam.filter_option,
      title: pixelListingParam.title,
      start_date: pixelListingParam.dateRange?.length
        ? pixelListingParam.dateRange[0]
        : null,
      timeframe: pixelListingParam.timeframe,
      limit: pixelListingParam.rowsPerPage,
      off_set:
        (pixelListingParam.pageNumber - 1) * pixelListingParam.rowsPerPage,
      order_column: pixelListingParam.sortColumn,
      order_direction: pixelListingParam.sortOrder,
      brand_id: pixelListingParam.brand?.length
        ? pixelListingParam.brand.toString()
        : null,
      agency_id: pixelListingParam.agency?.length
        ? pixelListingParam.agency.toString()
        : null,
      advertiser_id: pixelListingParam.advertiser?.length
        ? pixelListingParam.advertiser.toString()
        : null,
    });
  }, [pixelListingParam]);

  const applyFilters = (data: IfilterProps) => {
    let filter: PixelBodyType = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      timeframe: data.timeframe ? data.timeframe.toString() : null,
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      filter_option: data.filter_option ?? null,
      title: data.title ?? null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      limit: pixelListingParam.rowsPerPage,
      off_set:
        (pixelListingParam.pageNumber - 1) * pixelListingParam.rowsPerPage,
      order_column: pixelListingParam.sortColumn,
      order_direction: pixelListingParam.sortOrder,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') ?? '{}'),
      global: {
        agency: filter.agency_id,
        advertiser: filter.advertiser_id,
        brand: filter.brand_id,
        dateRange:
          filter.start_date && filter.end_date
            ? [filter.start_date, filter.end_date]
            : null,
        timeframe: filter.timeframe,
      },
      pixel: {
        title: filter.title,
        filter_option: filter.filter_option,
        rowsPerPage: pixelListingParam.rowsPerPage,
        sortColumn: pixelListingParam.sortColumn,
        sortOrder: pixelListingParam.sortOrder,
      },
    };
    setPixelListingParam({
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      title: data.title ?? '',
      filter_option: data.filter_option ?? null,
      rowsPerPage: pixelListingParam.rowsPerPage,
      timeframe: data.timeframe ? data.timeframe.toString() : null,
      pageNumber: pixelListingParam.pageNumber,
      sortColumn: pixelListingParam.sortColumn,
      sortOrder: baseListingParam.sortOrder,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
    });
    setBody(filter);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
  };

  useEffect(() => {
    const tabletColumns = [
      'title',
      'agency',
      'brand',
      'advertiser',
      'pixel_domain',
      'created_on',
      'udpated_on',
    ];
    const newColumnList = { ...columnList };
    newColumnList['pixels'] = isTablet ? tabletColumns : checkedList;
    localStorage.setItem('columns', JSON.stringify(newColumnList));
    if (
      JSON.stringify(newColumnList['pixels']) !== JSON.stringify(checkedList)
    ) {
      setCheckedList(newColumnList['pixels']);
    }
  }, [isTablet]);

  useEffect(() => {
    if (!isTablet) {
      if(columnList['pixels'] !== checkedList){
        columnList['pixels'] = checkedList;
      localStorage.setItem('columns', JSON.stringify(columnList));
      }
    }
  }, [columnList, checkedList, isTablet]);

  const Columns: TableColumnsType<DataType> = [
    { title: 'ID', dataIndex: 'key', key: 'key' },
    { title: 'Title', dataIndex: 'title', key: 'title' },
    { title: 'Advertiser', dataIndex: 'advertiser', key: 'advertiser' },
    { title: 'Agency', dataIndex: 'agency', key: 'agency' },
    { title: 'Brand', dataIndex: 'brand', key: 'brand', width: '130px' },
    { title: 'Domain', dataIndex: 'pixel_domain', key: 'pixel_domain' },
    { title: 'Created', dataIndex: 'created_on', key: 'created_on' },
    { title: 'Modified', dataIndex: 'updated_on', key: 'updated_on' },
  ];

  useEffect(() => {
    let existingFilters = JSON.parse(localStorage.getItem('Filters') ?? '{}');

    let filterStorage = {
      ...existingFilters,
      pixel: {
        ...existingFilters.pixel,
        rowsPerPage: pixelListingParam.rowsPerPage,
        sortColumn: pixelListingParam.sortColumn,
        sortOrder: pixelListingParam.sortOrder,
      },
    };

    localStorage.setItem('Filters', JSON.stringify(filterStorage));
  }, [pixelListingParam.rowsPerPage, pixelListingParam.sortColumn, pixelListingParam.sortOrder]);
  

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  const options = Columns.map(({ key, title }) => ({
    label: title,
    value: key,
  }));

  return (
    <div className="audienceCommonWrap">
      <PageHeader title="Conversion Pixels" />
      <div className="audienceListingPageContent">
        {!loaderState && (
          <Fragment>
            <div className="filterWrap">
              <div className="filterBtnAndText">
                <ConfigProvider wave={{ disabled: true }}>
                  <Tooltip title="Configure Columns">
                    <Button onClick={() => setColumnPickerOpen(true)}>
                      <img src={ColumnSelectorIcon} alt="" />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Filter Results">
                    <Button onClick={() => setIsFilterModalOpen(true)}>
                      <img src={filter} alt="" />
                    </Button>
                  </Tooltip>
                </ConfigProvider>
                {(pixelListingParam.agency ||
                  pixelListingParam.advertiser ||
                  pixelListingParam.brand ||
                  pixelListingParam.timeframe ||
                  pixelListingParam.dateRange ||
                  pixelListingParam.title) && (
                  <FilterMessage type="pixel" data={pixelListingParam} />
                )}

                <ColumnSelector
                  isOpen={columnPickerOpen}
                  onOk={() => {
                    setColumnPickerOpen(false);
                  }}
                  col={checkedList}
                  setCol={setCheckedList}
                  tableOptions={options}
                  onCancel={() => setColumnPickerOpen(false)}
                />

                <GlobalFilterModal
                  isOpen={isFilterModalOpen}
                  type="pixel"
                  filterData={pixelListingParam}
                  handleOk={applyFilters}
                  handleCancel={handleCancel}
                />
              </div>

              <button
                className="primaryBtn addAudienceBtn"
                onClick={() => redirectPixelSetup()}
              >
                New Pixel
              </button>
            </div>
            <PixelListTable
              pixelData={data}
              isLoading={isLoading}
              checkedList={checkedList}
              paginationAndSortingData={pixelListingParam}
              setPaginationAndSortingData={setPixelListingParam}
            />
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default PixelListingWrap;
