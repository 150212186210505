import React, { FC, useEffect, useRef } from 'react';
import anychart from 'anychart';
import { AudienceData } from './DashboardContent';
import { TooltipContext } from '../../../models/interfaces';
import CreatedAudience from './CreatedAudience';


const AudienceChart: FC<{
  audienceData: AudienceData;
}> = ({ audienceData }) => {
  const chartContainer = useRef(null);

  useEffect(() => {
    if (chartContainer.current) {
      // Pie Chart Data
      const data = [
        { x: 'Building', value: audienceData.building_percent,  },
        { x: 'Estimated', value: audienceData.estimate_percent },
        { x: 'Activated', value: audienceData.activate_percent },
      ];
  
      const chart = anychart.pie(data);

      const tooltip = chart.tooltip();
      
      tooltip.format(function(this: TooltipContext): string {
        return `${this.value}%`;
      });

      tooltip.background({
        fill: '#272727',
      });

      chart.innerRadius('75%');  

      chart.labels(false);
      
      chart.explode(0);
      chart.palette(["#5BB1F9","#0E72D6","#FA7300"])

      chart.interactivity().selectionMode(false);
      
      chart.background().enabled(false);
      
      chart
        .label(0)
        .text(`${audienceData.total_audience}`)
        .position('center')
        .anchor('center')
        .hAlign('center')
        .vAlign('middle')
        .offsetY(-23) 
        .offsetX(-55)
        .fontSize(45) 
        .fontWeight(700)
        .fontColor('var(--bl-header)');

      
      chart
        .label(1) 
        .text('Audiences') 
        .position('center')
        .anchor('center')
        .hAlign('center')
        .vAlign('middle')
        .offsetY(8) 
        .offsetX(-55)
        .fontSize(16)
        .fontWeight(400)
        .fontColor('var(--bl-header)');
  
      chart.legend(true);
      chart.legend()
        .position('right')  
        .itemsLayout('vertical')
        .align('center')
        .itemsFormatter(function(items) {
          return items.map(item => ({
            text: item.text,
            iconType: item.iconType,
            iconStroke: item.iconStroke,
            iconFill: item.iconFill
          }));
        });
      
        chart.legend().fontColor('#555552');
      chart.legend().title().enabled(false);
      chart.legend().paginator().enabled(false);
      
      chart.padding().bottom(50);
      
      chart.container(chartContainer.current);
      chart.draw();
      
      return () => {
        chart.dispose();
      };
    }
  }, [audienceData]);

  return (
    <div className="chartWrapper">
      <div ref={chartContainer} style={{  height: '400px' }} />

      <div
        className="audienceTypeWrap"
      >
        <span
          style={{
            flex: `${audienceData.dtc_count} 1 auto`,
            background: '#1b58d0',
            color: '#fff',
            padding: '8px',
            textAlign: 'center'
          }}
        >
          <strong>DTC</strong> ({audienceData.dtc_count})
        </span>
        <span
          style={{
            flex: `${audienceData.hcp_count} 1 auto`,
            background: '#9ab9f6',
            color: 'var(--bl-header)',
            padding: '8px',
            textAlign: 'center'
          }}
        >
          <strong>HCP</strong> ({audienceData.hcp_count})
        </span>
      </div>
      <CreatedAudience
        audienceData={audienceData} heading={''}/>
    </div>
  );
};

export default AudienceChart;