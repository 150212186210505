import { Checkbox, CheckboxOptionType, Modal } from 'antd';
import React, { FC } from 'react';

const ColumnSelector: FC<{
  isOpen: boolean;
  tableOptions: any;
  onCancel: () => void;
  onOk: () => void;
  col: string[];
  setCol: React.Dispatch<React.SetStateAction<string[]>>;
}> = ({ tableOptions, onCancel, isOpen, onOk, setCol, col }) => {
  return (
    <Modal 
    open={isOpen} 
    centered onOk={onOk} 
    onCancel={onCancel} 
    footer={null}
    className='columnSelectorModal'
    >
      <h3>Configure Columns</h3>
      <Checkbox.Group
        value={col}
        options={tableOptions as CheckboxOptionType[]}
        onChange={(value)=>{
          setCol([...value, 'action'])
        }}
      />
    </Modal>
  );
};

export default ColumnSelector;
