import Cookies from 'js-cookie';
import { useCookies, useRedirection } from './';
import { useUserInformationStore } from '../stores';
import { IUserInformationState } from '../models/interfaces';

type UserType = {
  user_id: number | null;
  email: string | null;
  timestamp: number | null;
  user_role_id: number | null;
  user_token: string | null;
  profile_pic: string | null;
  first_name: string | null;
  last_name: string | null;
  user_account_id: number | null;
  privilege_set: string[] | null;
};

const useAuth = () => {
  const { redirectLogin, redirectToDashboard } = useRedirection(),
    { createCookie, deleteCookie } = useCookies();

  const { setUserIsLoggedIn } = useUserInformationStore(
    (state: IUserInformationState) => state
  );

  const login = (user: UserType) => {
    createCookie(user);
    redirectToDashboard();
  };

  const logout = () => {
    localStorage.clear();
    setUserIsLoggedIn(false);
    deleteCookie();
    localStorage.clear();
    redirectLogin();
  };

  const getUserInfo = () => {
    try {
      const token = Cookies.get('branch-lab');

      if (token) {
        const user: any = JSON.parse(atob(token));

        Cookies.remove('branch-lab');
        createCookie(user);

        return { ...user, timeStamp: Date.now() };
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  return {
    login,
    logout,
    getUserInfo,
    createCookie,
    deleteCookie,
  };
};

export default useAuth;
