/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Select, SelectProps, Tag, Tooltip } from 'antd';
import {
  IEachDropdownElementsType,
  IEnableSaveState,
  IMasterDmaDDState,
} from '../../../../models/interfaces';
import {
  useCheckSavingEnabilityStore,
  useMasterDmaElementsStore,
} from '../../../../stores';
import { WarningOutlined } from '@ant-design/icons';

type TagRender = SelectProps['tagRender'];

const DMASelector: FC<{
  featureSetNumber: number;
  targetingSetNumber?: number;
  isSearchable?: boolean;
  componentSpecificClasses?: string[];
  isDisabled?: boolean;
  defaultPopupWidth?: number | boolean;
  defaultPopupClassName?: string;
  allowClear?: boolean;
  props?: any;
  onChange: React.Dispatch<React.SetStateAction<(number | string)[]>>;
  value: (number | string)[];
}> = ({
  featureSetNumber,
  targetingSetNumber,
  isSearchable = false,
  componentSpecificClasses,
  isDisabled = false,
  defaultPopupWidth = true,
  defaultPopupClassName,
  allowClear = true,
  props = {},
  onChange,
  value,
}) => {
  const [dma, setDma] = useState<(number | string)[]>([]),
    [ddElements, setDdElements] = useState<IEachDropdownElementsType[]>([]),
    [ifShowError, setIfShowError] = useState<boolean>(false);

  const {
    isFeatureSetsFilled,
    updateIisFeatureSetsFilled,
  } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state);

  const filterOption = (input: string, option?: IEachDropdownElementsType) => {
    return (
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) ||
      (option?.dma_id?.toString() ?? '').includes(input.toLowerCase())
    );
  };

  const { dmaDDElements } = useMasterDmaElementsStore(
    (state: IMasterDmaDDState) => state
  );

  const onFocus = () => {
    ifShowError &&
      updateIisFeatureSetsFilled(featureSetNumber, targetingSetNumber, 0);
  };

  useEffect(() => {
    if (
      featureSetNumber !== undefined &&
      targetingSetNumber !== undefined &&
      isFeatureSetsFilled[`${featureSetNumber}`][`${targetingSetNumber}`] === 1
    ) {
      setIfShowError(true);
      return;
    }
    setIfShowError(false);
  }, [isFeatureSetsFilled]);

  useEffect(() => {
    const newDmaList = dmaDDElements[`${featureSetNumber}`]
      ? [...dmaDDElements[`${featureSetNumber}`]]
      : [];

    setDdElements(newDmaList);
    if (dma?.length) {
      const newSelectedDma = newDmaList
        .filter((el) => dma.includes(el.value))
        ?.map((el) => el.value);
      setDma(newSelectedDma);
    }
  }, [dmaDDElements]);

  useEffect(() => {
    setDma(value);
  }, [value]);

  return (
    <div className="eachCommonElementWrapper">
      <Select
        mode="multiple"
        showSearch={isSearchable}
        placeholder="Select DMA"
        optionFilterProp="children"
        value={dma.map((el) => ddElements.find((e) => e.value === el))}
        onChange={(data) => {
          setDma(data);
          onChange(data);
        }}
        filterOption={filterOption}
        optionRender={({ data }) => renderOptions(data)}
        options={ddElements.filter((el) => !dma.includes(el.value))}
        className={`multiSelectDropdown${
          componentSpecificClasses?.length
            ? ' ' + componentSpecificClasses.join(' ')
            : ''
        }`}
        disabled={isDisabled}
        allowClear={allowClear}
        popupMatchSelectWidth={defaultPopupWidth}
        popupClassName={`dmaSelectionDDOptionWrap${
          defaultPopupClassName ? ' ' + defaultPopupClassName : ''
        }`}
        tagRender={renderTag}
        maxTagPlaceholder={renderTooltip}
        onFocus={onFocus}
        status={ifShowError ? 'error' : ''}
        suffixIcon={
          ifShowError ? <WarningOutlined className="validationErrorIcon" /> : ''
        }
        {...props}
      />
    </div>
  );
};

export default DMASelector;

const renderTag: TagRender = (data: any) => {
  const { label, closable, onClose } = data,
    showingLabel = (
      <>
        {String(label)
          ?.split(':')
          .map((el: string, index: number) =>
            index === 0 ? (
              <span key="1">
                <b>{el}</b>:&nbsp;{' '}
              </span>
            ) : (
              <span key="2">{el}</span>
            )
          )}
      </>
    );

  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      className="debounceDDTagWrapper"
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {showingLabel}
    </Tag>
  );
};

const renderTooltip = (omittedValues: any) => (
  <Tooltip title={omittedValues.map((el: any) => el.label).join(', ')}>
    <span className="multiSelectTooltip">
      + {omittedValues?.length ?? 0} ...
    </span>
  </Tooltip>
);

const renderOptions = (data: any) => {
  return (
    <>
      <b>{data.dma_id}</b>
      {data.label.replace(data.dma_id, '')}
    </>
  );
};
