import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  dmaDDType,
  IEachDropdownElementsType,
  IMasterDmaDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterLanguageDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterTimeFrameDDState,
} from '../../../../models/interfaces';
import {
  useMasterDmaElementsStore,
  useMasterGenderElementsStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterStateElementsStore,
  useMasterTimeFrameElementsStore,
} from '../../../../stores';
import {
  useMasterInsurancePlanProviderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterLanguageElementStore,
  useMasterOperatorElementsStore,
} from '../../../../stores/masterData.store';

interface FormattedData {
  [key: string]: string | null | string[];
}

interface MappingConfig {
  key: string;
  data?: string;
  transform?: any;
  md?: IEachDropdownElementsType[] | dmaDDType;
  tol: 0 | 1 | 2 | 3 | 4 | 5;
}

interface Mappings {
  [targetId: string]: MappingConfig;
}

const MessageFormatter: FC<{
  globalTargeting: number[];
  globalFeatureSet: any;
  hcpTargeting: number[];
  hcpFeatureSet: any;
  caregiverTargeting: number[];
  caregiverFeatureSet: any;
  definedData: any;
  audienceType: number | null;
  isCaregiver: boolean;
  setGlobalMsg: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  globalTargeting,
  globalFeatureSet,
  hcpTargeting,
  hcpFeatureSet,
  caregiverTargeting,
  caregiverFeatureSet,
  setGlobalMsg,
  definedData,
  audienceType,
  isCaregiver
}) => {
  const { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { dmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { raceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { insurancePlanTypeDDElements } = useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    ),
    { insurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      ),
    { languageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLanguageDDState) => state
    );

  const formattedGlobalData = useMemo(() => {
    const data: FormattedData = {};

    if (globalTargeting.includes(1)) {
      data.timeFrame = formatDataToCreateMessage({
        data: globalFeatureSet.timeFrame,
        md: timeFrameDDElements,
        tol: 1,
      });
      data.dateRange = formatDataToCreateMessage({
        data: globalFeatureSet.dateRange,
        tol: 0,
      });
    }

    const mappings: Mappings = {
      '2': {
        key: 'gender',
        data: 'patientGender',
        md: genderDDElements,
        tol: 1,
      },
      '3': { key: 'selectedAge', data: 'selectedAge', tol: 0 },
      '4': {
        key: 'selectedRaceAndEthnicity',
        data: 'selectedRaceAndEthnicity',
        md: raceAndEthnicityDDElements,
        tol: 2,
      },
      '5': {
        key: 'selectedGeographyStates',
        data: 'selectedGeographyStates',
        md: stateDDElements,
        tol: 2,
      },
      '15': {
        key: 'selectedGeographyDmas',
        data: 'selectedGeographyDmas',
        md: dmaDDElements,
        tol: 5,
      },
      '19': {
        key: 'language',
        data: 'language',
        md: languageDDElements,
        tol: 2,
      },
    };

    Object.entries(mappings).forEach(
      ([targetId, config]: [string, MappingConfig]) => {
        if (globalTargeting.includes(Number(targetId))) {
          data[config.key] = formatDataToCreateMessage({
            data: globalFeatureSet[config.data ?? 1],
            md: config.md,
            tol: config.tol,
          });
        }
      }
    );

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalTargeting, globalFeatureSet]);

  const formattedCaregiverData = useMemo(() => {
    const data: FormattedData = {};

    const mappings: Mappings = {
      '21': {
        key: 'caregiverGender',
        data: 'caregiverGender',
        md: genderDDElements,
        tol: 1,
      },
      '22': { key: 'caregiverAge', data: 'caregiverAge', tol: 0 },
      '15': {
        key: 'selectedGeographyDmas',
        data: 'selectedGeographyDmas',
        md: dmaDDElements,
        tol: 5,
      },
      '5': {
        key: 'selectedGeographyStates',
        data: 'selectedGeographyStates',
        md: stateDDElements,
        tol: 2,
      },
    };

    Object.entries(mappings).forEach(([targetId, config]) => {
      if (caregiverTargeting.includes(Number(targetId))) {
        data[config.key] = formatDataToCreateMessage({
          data: caregiverFeatureSet[config.key],
          md: config.md,
          tol: config.tol,
        });
      }
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caregiverTargeting, caregiverFeatureSet]);

  const formattedHcpData = useMemo(() => {
    const data: FormattedData = {};

    const mappings: Mappings = {
      6: {
        key: 'selectedProviderSpecialty',
        data: 'selectedRaceAndEthnicity',
        md: providerSpecialtyDDElements,
        tol: 2,
      },
      7: {
        key: 'customNPIList',
        tol: 0,
        transform: (list: Array<{ displayName: string }>) =>
          list.map((el) => el.displayName),
      },
      20: {
        key: 'selectedProviderTaxonomy',
        data: 'selectedProviderTaxonomy',
        tol: 0,
      },
    };

    Object.entries(mappings).forEach(([targetId, config]) => {
      if (hcpTargeting.includes(Number(targetId))) {
        const sourceData = hcpFeatureSet[config.key];

        if(targetId === '20'){
          data[config.key] = formatDataToCreateMessage({
            data: sourceData.map(
              (el: any) => el.label
            ),
            md: config.md,
            tol: config.tol,
          });
        }else{
          data[config.key] = formatDataToCreateMessage({
            data: sourceData,
            md: config.md,
            tol: config.tol,
          });
        }
      }
    });

    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hcpTargeting, hcpFeatureSet]);

  const formattedDefinedData = useMemo(() => {
    let formatted: { [key: string]: FormattedData & { operator: string } } = {};

    Object.entries(definedData).forEach(([key, data]: any) => {
      if (data?.targeting?.includes(9)) {
        formatted['selectedDiagnosis'] = {
          ...formatDataToCreateMessage({
            data: data.featureSetValues.selectedDiagnosis.map(
              (el: any) => el.label
            ),
            tol: 0,
          }),
          operator:
            operatorDDElements.find((op: any) => op.value === data.operator)
              ?.label ?? 'AND',
        };
      }

      if (data?.targeting?.includes(10)) {
        formatted['selectedDrugs'] = {
          ...formatDataToCreateMessage({
            data: data.featureSetValues.selectedDrugs.map((el: any) =>
              el?.label?.replaceAll('~', ' ')
            ),
            tol: 0,
          }),
          operator:
            operatorDDElements.find((op: any) => op.value === data.operator)
              ?.label ?? 'AND',
        };
      }

      if (data?.targeting?.includes(11)) {
        formatted['selectedProcedures'] = {
          ...formatDataToCreateMessage({
            data: data.featureSetValues.selectedProcedures.map(
              (el: any) => el.label
            ),
            tol: 0,
          }),
          operator:
            operatorDDElements.find((op: any) => op.value === data.operator)
              ?.label ?? 'AND',
        };
      }

      if (data?.targeting?.includes(12)) {
        formatted['selectedInsurancePlanType'] = {
          ...formatDataToCreateMessage({
            data: data.featureSetValues.selectedInsurancePlanType,
            md: insurancePlanTypeDDElements,
            tol: 2,
          }),
          operator:
            operatorDDElements.find((op: any) => op.value === data.operator)
              ?.label ?? 'AND',
        };
      }

      if (data?.targeting?.includes(16)) {
        formatted['selectedInsuranceProviders'] = {
          ...formatDataToCreateMessage({
            data: data.featureSetValues.selectedInsuranceProviders,
            md: insurancePlanProviderDDElements,
            tol: 2,
          }),
          operator:
            operatorDDElements.find((op: any) => op.value === data.operator)
              ?.label ?? 'AND',
        };
      }

      if (data?.targeting?.includes(18)) {
        formatted['genericDrug'] = {
          ...formatDataToCreateMessage({
            data: data.featureSetValues.genericDrug.map((el: any) =>
              el?.label?.replaceAll('~', ' ')
            ),
            tol: 0,
          }),
          operator:
            operatorDDElements.find((op: any) => op.value === data.operator)
              ?.label ?? 'AND',
        };
      }
    });
    return formatted;
  }, [definedData]);

  // Format and combine all messages
  const createMergedMessageString = useCallback(() => {
    const globalMappings = {
      gender: 'gender of',
      selectedAge: 'age of',
      selectedRaceAndEthnicity: 'race of',
      selectedGeographyStates: 'states of',
      selectedGeographyDmas: 'DMA of',
      language: 'language of',
      timeFrame: 'time frame of',
      dateRange: 'date range of',
    };

    const hcpMappings = {
      selectedProviderSpecialty: 'provider speciality of',
      selectedProviderTaxonomy: 'Taxonomy of',
    };

    const caregiverMappings = {
      caregiverAge: 'caregiver age of',
      caregiverGender: 'caregiver gender of',
      selectedGeographyStates: 'states of',
      selectedGeographyDmas: 'DMA of',
    };

    const defineMapping = {
      selectedDiagnosis: 'diagnosis of',
      selectedDrugs: 'drugs of',
      selectedProcedures: 'procedure of',
      selectedInsurancePlanType: 'payer type of',
      selectedInsuranceProviders: 'insurance provider of',
      genericDrug: 'generic drug of',
    };

    const messageParts: string[] = [];

    if(audienceType === 1 && isCaregiver){
      messageParts.push('caregivers of patients ')
    }else if(audienceType === 1){
      messageParts.push('patients ')
    }else if(audienceType === 2){
      messageParts.push('professionals ')
    }

    // Always initiate the summary with "This health audience targets".
    Object.entries(globalMappings).forEach(([dataKey, messageKey]) => {
      const value = formattedGlobalData[dataKey];
      if (value) {
        messageParts.push(`"${messageKey}":"${value}"`);
      }
    });

    Object.entries(hcpMappings).forEach(([dataKey, messageKey]) => {
      const value = formattedHcpData[dataKey];
      if (value) {
        messageParts.push(`"${messageKey}":"${value}"`);
      }
    });

    Object.entries(caregiverMappings).forEach(([dataKey, messageKey]) => {
      const value = formattedCaregiverData[dataKey];
      if (value) {
        messageParts.push(`"${messageKey}":"${value}"`);
      }
    });

    Object.entries(formattedDefinedData).forEach(([key, data]) => {
      const mappingKey = defineMapping[key as keyof typeof defineMapping];
      if (mappingKey && data) {
        const operator = data.operator ? `${data.operator} ` : '';
        const value = Object.entries(data)
          .filter(([k]) => k !== 'operator')
          .map(([, v]) => v)
          .join(' * ');
        messageParts.push(`${operator}"${mappingKey}":"${value}"`);
      }
    });

    return messageParts.length ? `{${messageParts.join(' * ')}}` : '';
  }, [
    formattedGlobalData,
    formattedHcpData,
    formattedCaregiverData,
    formattedDefinedData,
    audienceType,
    isCaregiver,
  ]);

  // Update useEffect to use the memoized message creation function
  useEffect(() => {
    const finalMessage = createMergedMessageString();
    setGlobalMsg(finalMessage);
  }, [createMergedMessageString, setGlobalMsg]);

  return null;
};

export default MessageFormatter;

const formatDataToCreateMessage = (params: {
  data: any;
  md?: any;
  tol: 0 | 1 | 2 | 3 | 4 | 5;
  parentId?: any;
  ck?: string;
}) => {
  const { data, md, tol, parentId, ck } = params;
  switch (tol) {
    // Single select
    case 1: {
      return (
        md.find((el: IEachDropdownElementsType) => el.value === data)?.label ??
        ''
      );
    }
    // Multi Select
    case 2: {
      return md
        .filter((el: IEachDropdownElementsType) => data.includes(el.value))
        ?.map((el: IEachDropdownElementsType) => el.label);
    }
    // Dual selector
    case 3: {
      try {
        const typeOfSelectedParent = Array.isArray(parentId);
        if (
          ((typeOfSelectedParent && parentId.length) ||
            (!typeOfSelectedParent && parentId)) &&
          ck
        ) {
          const childMasterDataNested = md
              .filter((el: any) =>
                !typeOfSelectedParent
                  ? el.value === parentId
                  : parentId.includes(el.value)
              )
              .map((el: any) => el[ck]),
            childMasterData = childMasterDataNested.concat(
              ...childMasterDataNested
            );

          return childMasterData
            .filter((el: IEachDropdownElementsType) => data.includes(el.value))
            ?.map((el: IEachDropdownElementsType) => el.label);
        }
        return null;
      } catch (error) {
        return null;
      }
    }
    case 4: {
      if (data?.length) {
        return 'All';
      }
      return null;
    }
    case 5: {
      const labelMap: any = Object.values(md)
        .flat()
        .reduce((acc: any, item: any) => {
          acc[item.value] = item.label;
          return acc;
        }, {});
      return data.map((item: any) => labelMap[item] || null);
    }
    default: {
      return data;
    }
  }
};

