import Checkbox, { CheckboxProps } from 'antd/es/checkbox/Checkbox';
import { FC, useEffect, useState } from 'react';
import {
  useMasterReportsElementsStore,
  useResearchDataCheckStore,
  useResearchDataHandlingStore,
} from '../../../stores';
import { useParams } from 'react-router-dom';
import {
  IMasterReportsDDState,
  IResearchDataCheckState,
  IResearchDataState,
} from '../../../models/interfaces';

interface ResearchToGenerateProps {
  setParentSelectedReports: (data: number[]) => void;
}

const ResearchToGenerate: FC<ResearchToGenerateProps> = ({
  setParentSelectedReports,
}) => {
  const { id } = useParams<{ id: string }>();
  const [selectedReports, setSelectedReports] = useState<number[]>([]);

  const { reportsDDElements } = useMasterReportsElementsStore(
    (state: IMasterReportsDDState) => state
  );
  const { updatedDataSet } = useResearchDataHandlingStore(
    (state: IResearchDataState) => state
  );
  const { ifUpdated } = useResearchDataCheckStore(
    (state: IResearchDataCheckState) => state
  );

  useEffect(() => {
    if (ifUpdated && updatedDataSet?.report_ids?.length) {
      const { report_ids } = updatedDataSet;
      setSelectedReports(report_ids);
    }
  }, [updatedDataSet]);

  useEffect(() => {
    setSelectedReports([]);
  }, [id]);

  useEffect(() => {
    setParentSelectedReports(selectedReports);
  }, [selectedReports, setParentSelectedReports]);

  const onChange: CheckboxProps['onChange'] = (e) => {
    const value = Number(e.target.value);
    if (selectedReports) {
      setSelectedReports((prevSelectedReports) =>
        e.target.checked
          ? [...prevSelectedReports, value]
          : prevSelectedReports.filter((reportId) => reportId !== value)
      );
    } else {
      const tempSelectedArray:any = [];
      tempSelectedArray.push(value);
      setSelectedReports(tempSelectedArray);
    }
  };

  return (
    <>
      <div className="createResearchElementsWrap">
        <div className="sectionHeader">
          <div>
            <h2 className="pageHeader">
              Research to generate<span className="isMandatory">*</span>
            </h2>
          </div>
        </div>
        <div className="checkboxWrapToGenerateResearch">
          {reportsDDElements.map((item, index) => (
            <Checkbox
              onChange={onChange}
              key={index}
              value={item.value}
              checked={selectedReports?.includes(Number(item?.value))}
            >
              {item.label}
            </Checkbox>
          ))}
        </div>
      </div>
    </>
  );
};

export default ResearchToGenerate;
