/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { ModalComponent } from '../../../../components/sharedComponent';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterAdditionalOptionElementsStore,
  useMasterDmaElementsStore,
  useMasterInsurancePlanProviderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterOperatorElementsStore,
  useMasterStateElementsStore,
  useMasterTargetingElementsStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  IDependentTargetingSelectedState,
  IEnableSaveState,
  IFeatureSetBaseData,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IFeatureSetParamDatum,
  IMasterAdditionalOptionDDState,
  IMasterDmaDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterOperatorDDState,
  IMasterStateDDState,
  IMasterTargetingDDState,
} from '../../../../models/interfaces';
import { featureSetList } from './featureSetList';
import { Select } from 'antd';
import { allSelectionOnlyElement } from '../../../../demoData/baseData';
import {
  useAudienceSaveMethods,
  useAudienceSets,
  useAudienceSetUp,
} from '../../../../stores/audienceSets.store';

const baseValues: IFeatureSetBaseData = {
  genericDrug: [],
  selectedDiagnosis: [],
  selectedDrugs: [],
  selectedProcedures: [],
  selectedInsurancePlanType: [],
  selectedInsuranceProviders: [],
  selectedAdditionalOpts: [],
  additionalInfo: '',
};

interface FeatureSet {
  targeting: number[];
  featureSetValues: Record<string, any>;
  operator: number;
}

const DefinedFeatureSet: FC<{
  featureSetNumber: number;
  featureSetOrderNumber: number;
  disabled?: boolean;
  setDeletingSetId?: React.Dispatch<React.SetStateAction<number[] | null>>;
}> = ({
  featureSetNumber,
  featureSetOrderNumber,
  disabled = false,
  setDeletingSetId,
}) => {
  const {
      targetingDDElements,
      targetingDDElementsForFs,
      updateTargetingDDElementsForFs,
      setTargetingDDElementsForFs,
    } = useMasterTargetingElementsStore(
      (state: IMasterTargetingDDState) => state
    ),
    { updateIisFeatureSetsFilled } = useCheckSavingEnabilityStore(
      (state: IEnableSaveState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { insurancePlanTypeDDElements } = useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    ),
    { insurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { ifUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { isEnableSaveForEachSet, updateIsEnableSaveForEachSet } =
      useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    { updateSavingDataSet, updatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { updateDmaDDElements } = useMasterDmaElementsStore(
      (state: IMasterDmaDDState) => state
    ),
    { updateIsDependentTargetingSelectedForEachSet } =
      useCheckDependentTargetingSelectedStore(
        (state: IDependentTargetingSelectedState) => state
      ),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    { isGlobalDisabled } = useAudienceSetUp(
      (state: IAudienceSetUpState) => state
    ),
    {
      setDeletedIds,
      deletedIds,
      setIsCancelled,
      isCancelled,
      firstFeatureNumber,
      defineFeatureSet,
      setDefineFeatureSet,
      updateDefineOperator,
      updateFeatureSet,
      updateDefineFeatureTargeting,
      updateDefineFeatureValues,
      setIsDefineModified,
    } = useAudienceSets((state: IAudienceState) => state);

  const isNotFirstFeatureSet = firstFeatureNumber !== featureSetNumber;

  const [featureSetBody, setFeatureSetBody] = useState<IFeatureSetElement[]>(
      []
    ),
    [initialFeatureSet, setInitialFeatureSet] = useState<FeatureSet | null>(
      null
    ),
    [ifUpdatedCompleted, setIfUpdatedCompleted] = useState<0 | 1 | 2>(0),
    [operator, setOperator] = useState<number>(5),
    [modalToOpen, setModalToOpen] = useState(false);

  useEffect(() => {
    if (!defineFeatureSet[featureSetNumber]) {
      defineFeatureSet[featureSetNumber] = {
        targeting: [],
        featureSetValues: JSON.parse(JSON.stringify(baseValues)),
        operator: 5,
      };
      setDefineFeatureSet(defineFeatureSet);
    }
  }, []);

  useEffect(() => {
    if (
      targetingDDElementsForFs.length === 0 &&
      Object.keys(updatedDataSet).length <= 0
    ) {
      let newOptions = targetingDDElements.filter(
        (tar) => !tar.is_hpa && !tar.is_caregiver && !tar.is_global
      );
      setTargetingDDElementsForFs(newOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newTargeting = defineFeatureSet[featureSetNumber]?.targeting || [];
  const newFeatureValues =
    defineFeatureSet[featureSetNumber]?.featureSetValues ||
    JSON.parse(JSON.stringify(baseValues));

  const storeInitialState = useCallback(() => {
    if (defineFeatureSet[featureSetNumber]) {
      setInitialFeatureSet({
        targeting: [...defineFeatureSet[featureSetNumber].targeting],
        featureSetValues: JSON.parse(
          JSON.stringify(defineFeatureSet[featureSetNumber].featureSetValues)
        ),
        operator: defineFeatureSet[featureSetNumber].operator,
      });
    }
  }, [defineFeatureSet[featureSetNumber]]);

  const checkForChanges = () => {
    if (!initialFeatureSet) return;

    const currentFeatureSet = defineFeatureSet[featureSetNumber];
    setIsDefineModified({
      targeting: !arraysEqual(
        initialFeatureSet?.targeting,
        currentFeatureSet?.targeting
      ),
      featureSetValues: !objectsEqual(
        initialFeatureSet.featureSetValues,
        currentFeatureSet.featureSetValues
      ),
      operator: initialFeatureSet.operator !== currentFeatureSet.operator,
    });
  };

  const arraysEqual = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  };

  const objectsEqual = useCallback(
    (obj1: Record<string, any>, obj2: Record<string, any>) => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      return keys1.every((key) => {
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
          return arraysEqual(obj1[key], obj2[key]);
        }
        return obj1[key] === obj2[key];
      });
    },
    [arraysEqual]
  );

  useEffect(() => {
    if (ifUpdatedCompleted === 1 || !initialFeatureSet) {
      storeInitialState();
    }
  }, [ifUpdatedCompleted]);

  useEffect(() => {
    if (initialFeatureSet) {
      checkForChanges();
    }
  }, [defineFeatureSet[featureSetNumber]]);

  useEffect(() => {
    if (isCancelled) {
      setFeatureSetBody([]);
      setIsCancelled(false);
    }
  }, [isCancelled]);

  const handleTargetableOptions = (value: any) => {
    updateDefineFeatureTargeting(value, featureSetNumber);
    let newOptions = targetingDDElementsForFs.filter(
      (tar) =>
        tar.value !== value &&
        !tar.is_hpa &&
        !tar.is_caregiver &&
        !tar.is_global
    );
    if (value === 12 || value === 16) {
      const disabledValue = value === 12 ? 16 : 12;
      newOptions = newOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
    } else if (value === 10 || value === 18) {
      const disabledValue = value === 10 ? 18 : 10;
      newOptions = newOptions.map((opt: any) =>
        opt.value === disabledValue ? { ...opt, disabled: true } : opt
      );
    }
    setTargetingDDElementsForFs(newOptions);
  };

  const handleOperator = (id: number) => {
    setOperator(id);
    updateDefineOperator(id, featureSetNumber);
  };

  const checkSaveEnabled = () => {
    switch (true) {
      case !newTargeting?.length:
        return 0;
      case newTargeting.includes(9) &&
        !newFeatureValues.selectedDiagnosis?.length:
      case newTargeting.includes(10) && !newFeatureValues.selectedDrugs?.length:
      case newTargeting.includes(11) &&
        !newFeatureValues.selectedProcedures?.length:
      case newTargeting.includes(12) &&
        !newFeatureValues.selectedInsurancePlanType:
      case newTargeting.includes(16) &&
        !newFeatureValues.selectedInsuranceProviders.length:
      case newTargeting.includes(18) && !newFeatureValues.genericDrug.length:
        return 1;
      default:
        return 2;
    }
  };

  useEffect(() => {
    updateIsEnableSaveForEachSet(featureSetNumber, checkSaveEnabled());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTargeting, newFeatureValues]);

  const handleFeatureSetUpdates = (key: string, data: any) => {
    if (data) {
      if (Array.isArray(data)) {
        updateDefineFeatureValues(data, key, true, featureSetNumber);
      } else {
        updateDefineFeatureValues(data, key, false, featureSetNumber);
      }
    }
  };

  useEffect(() => {
    updateDmaDDElements(featureSetNumber, [], stateDDElements);
  }, []);

  useEffect(() => {
    performValidations();
  }, []);

  const performValidations = () => {
    validationForArray(9, newFeatureValues.selectedDiagnosis);
    validationForArray(10, newFeatureValues.selectedDrugs);
    validationForArray(11, newFeatureValues.selectedProcedures);
    validationForNonArray(12, newFeatureValues.selectedInsurancePlanType);
    validationForArray(16, newFeatureValues.selectedInsuranceProviders);
    validationForArray(18, newFeatureValues.genericDrug);
  };

  const validationForNonArray = (tId: number, data: any) => {
    if (newTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data ? 1 : 2);
      if (!data) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  const validationForArray = (tId: number, data: any) => {
    if (newTargeting.includes(tId)) {
      updateIisFeatureSetsFilled(featureSetNumber, tId, !data?.length ? 1 : 2);
      if (!data.length) {
        setIsSaveInitiated(false);
      }
      return;
    }
    updateIisFeatureSetsFilled(featureSetNumber, tId, 0);
  };

  function handleSetDelete(setIds: any) {
    if (isNotFirstFeatureSet) {
      updateTargetingDDElementsForFs(setIds);
      setModalToOpen(true);
    } else {
      const newDefineFeatureSet = JSON.parse(JSON.stringify(defineFeatureSet));
      updateTargetingDDElementsForFs(setIds[0]);
      const newTargetingElemArray = newDefineFeatureSet[
        setIds[2]
      ].targeting.filter((el: number) => setIds[0] !== el);
      newDefineFeatureSet[setIds[2]].featureSetValues[setIds[1]] = [];
      newDefineFeatureSet[setIds[2]].targeting = newTargetingElemArray;
      updateFeatureSet({ [setIds[2]]: newDefineFeatureSet[setIds[2]] });
    }
    setIsDefineModified({
      targeting: true,
      featureSetValues: false,
      operator: false,
    });
  }

  const getParams = () => {
    const params: IFeatureSetParamDatum[] = [
      {
        value: 9,
        values: [newFeatureValues.selectedDiagnosis],
        names: ['selectedDiagnosis'],
        base: [baseValues.selectedDiagnosis],
        param: {
          setId: 9,
          featureSetNumber,
          label: 'Diagnosis',
          is_feature: true,
          placeholder: 'Select Diagnosis',
          is_delete_target: isNotFirstFeatureSet,
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_DIAGNOSIS_PARAMS ?? '{}'
          ),
          value: newFeatureValues.selectedDiagnosis,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedDiagnosis', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 10,
        values: [newFeatureValues.selectedDrugs],
        names: ['selectedDrugs'],
        base: [baseValues.selectedDrugs],
        param: {
          setId: 10,
          featureSetNumber,
          label: 'Drug',
          is_feature: true,
          placeholder: 'Select Drugs',
          esParams: JSON.parse(process.env?.REACT_APP_ES_DRUG_PARAMS ?? '{}'),
          value: newFeatureValues.selectedDrugs,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedDrugs', data);
          },
          is_delete_target: isNotFirstFeatureSet,
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 11,
        values: [newFeatureValues.selectedProcedures],
        names: ['selectedProcedures'],
        base: [baseValues.selectedProcedures],
        param: {
          setId: 11,
          featureSetNumber,
          label: 'Procedure',
          is_feature: true,
          is_delete_target: isNotFirstFeatureSet,
          options: allSelectionOnlyElement,
          placeholder: 'Select Procedures',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_PROCEDURE_PARAMS ?? '{}'
          ),
          value: newFeatureValues.selectedProcedures,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedProcedures', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 12,
        values: [newFeatureValues.selectedInsurancePlanType],
        names: ['selectedInsurancePlanType'],
        base: [baseValues.selectedInsurancePlanType],
        param: {
          setId: 12,
          featureSetNumber,
          is_feature: true,
          label: 'Payer Type',
          value: newFeatureValues.selectedInsurancePlanType,
          options: insurancePlanTypeDDElements,
          placeholder: 'Select Payer Type',
          isSearchable: false,
          is_delete_target: isNotFirstFeatureSet,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedInsurancePlanType', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 16,
        values: [newFeatureValues.selectedInsuranceProviders],
        names: ['selectedInsuranceProviders'],
        base: [baseValues.selectedInsuranceProviders],
        param: {
          setId: 16,
          featureSetNumber,
          is_feature: true,
          label: 'Insurance Provider',
          options: insurancePlanProviderDDElements,
          placeholder: 'Select Insurance',
          isSearchable: true,
          is_delete_target: isNotFirstFeatureSet,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedInsuranceProviders', data);
          },
          value: newFeatureValues.selectedInsuranceProviders,
          handleSetDelete,
          isDisabled: disabled,
        },
      },
      {
        value: 13,
        values: [newFeatureValues.selectedAdditionalOpts],
        names: ['selectedAdditionalOpts'],
        base: [baseValues.selectedAdditionalOpts],
        param: {
          setId: 13,
          featureSetNumber,
          label: 'Additional Options',
          is_feature: true,
          options: additionalOptionDDElements,
          placeholder: 'Select Additional Options',
          isSearchable: true,
          is_delete_target: isNotFirstFeatureSet,
          onChange: (data: any) => {
            handleFeatureSetUpdates('selectedAdditionalOpts', data);
          },
          handleSetDelete,
          otherProperties: {
            defaultValue: newFeatureValues.selectedAdditionalOpts,
          },
          isDisabled: disabled,
        },
      },
      {
        value: 14,
        values: [newFeatureValues.additionalInfo],
        names: ['additionalInfo'],
        base: [baseValues.additionalInfo],
        param: {
          setId: 14,
          featureSetNumber,
          label: 'Additional Information',
          fieldType: 'textarea',
          inputPlaceholder: 'Additional Information',
          additionalInfo: newFeatureValues.additionalInfo,
          onChange: (data: any) => {
            handleFeatureSetUpdates('additionalInfo', data);
          },
          is_feature: true,
          handleSetDelete,
          props: { autoSize: { minRows: 1, maxRows: 1 } },
          isDisabled: disabled,
        },
      },
      {
        value: 18,
        values: [newFeatureValues.genericDrug],
        names: ['genericDrug'],
        base: [baseValues.genericDrug],
        param: {
          setId: 18,
          featureSetNumber,
          label: 'Generic Drug',
          is_feature: true,
          placeholder: 'Select Drugs',
          esParams: JSON.parse(
            process.env?.REACT_APP_ES_DRUG_GENERIC_PARAMS ?? '{}'
          ),
          is_delete_target: isNotFirstFeatureSet,
          value: newFeatureValues.genericDrug,
          onChange: (data: any) => {
            handleFeatureSetUpdates('genericDrug', data);
          },
          handleSetDelete,
          isDisabled: disabled,
        },
      },
    ];
    return params;
  };

  useEffect(() => {
    if (newTargeting?.length) {
      const updatedElem: IFeatureSetElement[] = [],
        params = getParams();

      newTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });

      setFeatureSetBody(updatedElem);
    }
  }, [newTargeting, newFeatureValues]);

  const getFormattedData = () => {
    const tempData: any = {
        ...newFeatureValues,
      },
      allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
        (el: any) => el.feature_set_id
      );
    let allUpdatingTargetingIds: any = {};
    updatedDataSet?.feature_sets?.forEach((el: any) => {
      if (el.feature_set_id === featureSetNumber) {
        el.targeting.forEach(
          (elem: any) =>
            (allUpdatingTargetingIds = {
              ...allUpdatingTargetingIds,
              [elem.targeting_id]: elem.targeting_set_id,
            })
        );
      }
    });

    let newFeatureSetData: any = {
        feature_set_id: allUpdatingFeatureSetIds?.includes(featureSetNumber)
          ? featureSetNumber
          : 0,
        feature_set_rank: featureSetOrderNumber,
        condition_id:
          featureSetOrderNumber !== firstFeatureNumber ? operator : 0,
        is_global: false,
        is_caregiver: false,
      },
      targeting:any = [],
      duals: any = {
        selectedInsuranceProviders: 16,
      };
    for (const key in newFeatureValues) {
      if (
        !(
          !tempData[key] ||
          (Array.isArray(tempData[key]) && tempData[key].length < 1)
        )
      ) {
        const params = getParams(),
          targetingId = params.find((el: IFeatureSetParamDatum) =>
            el.names.includes(key as any)
          )?.value,
          usableTargetingId = Object.keys(duals).includes(key)
            ? duals[key]
            : targetingId,
          usableTargetingSetId = Object.keys(allUpdatingTargetingIds).includes(
            `${usableTargetingId}`
          )
            ? allUpdatingTargetingIds[`${usableTargetingId}`]
            : 0;

        targeting.push({
          targeting_set_id: usableTargetingSetId,
          targeting_id: usableTargetingId,
          targeting_value: tempData[key],
          targeting_set_rank: newTargeting.indexOf(targetingId as number) + 1,
        });
      }
    }

    if (isSaveInitiated && Object.keys(newFeatureSetData)?.length) {
      updateSavingDataSet(featureSetNumber, {
        ...newFeatureSetData,
        targeting,
      });
    }
  };

  useEffect(() => {
    if (isSaveInitiated) {
      const isEnableValues = Object.values(isEnableSaveForEachSet);
      performValidations();
      if (isEnableValues.includes(1)) {
        setIsSaveInitiated(false);
        return;
      }
      if (newTargeting?.length) {
        getFormattedData();
      } else {
        updateSavingDataSet(featureSetNumber, {});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  useEffect(() => {
    if (deletedIds?.length) {
      setDeletedIds(null);
      const params = getParams(),
        selectedParam: IFeatureSetParamDatum[] = params.filter((el: any) =>
          deletedIds?.includes(el.value)
        ),
        // tempFeatureSetData = { ...featureSetData },
        dependentTargets = [6, 7, 8];

      selectedParam?.length &&
        selectedParam.forEach((eachParam: IFeatureSetParamDatum) => {
          if (dependentTargets.includes(eachParam.value)) {
            updateIsDependentTargetingSelectedForEachSet(
              featureSetNumber,
              false
            );
          }
        });
    }

    if (newTargeting?.length) {
      const params = getParams(),
        updatedElem: IFeatureSetElement[] = [],
        dependentTargets = [6, 7, 8];

      updateIsDependentTargetingSelectedForEachSet(
        featureSetNumber,
        Boolean(newTargeting.find((el) => dependentTargets.includes(el)))
      );

      newTargeting.forEach((el: number | string, index: number) => {
        updatedElem.push({
          setId: el as number,
          orderId: index,
          element: featureSetList
            .find((elem) => elem.value === (el as number))
            ?.component(
              params.find((elem) => elem.value === (el as number))?.param
            ),
        });
      });
      setFeatureSetBody(updatedElem);
    } else {
      setFeatureSetBody([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTargeting]);

  useEffect(() => {
    const allUpdatingFeatureSetIds = updatedDataSet?.feature_sets?.map(
      (el: any) => el.feature_set_id
    );
    if (
      ifUpdated &&
      !ifUpdatedCompleted &&
      updatedDataSet?.feature_sets?.length &&
      allUpdatingFeatureSetIds?.includes(featureSetNumber) &&
      !defineFeatureSet[featureSetNumber].targeting.length
    ) {
      setIfUpdatedCompleted(1);
      const featureSet = updatedDataSet.feature_sets.find(
        (el: any) =>
          el.feature_set_id === featureSetNumber &&
          !el.is_caregiver &&
          !el.is_hcp &&
          !el.is_global
      );

      if (featureSet) {
        const params = getParams(),
          targetingSet = featureSet.targeting.sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );

        let newTargetingSet: any = [];

        targetingSet.forEach((el: any) => {
          newTargetingSet.push({
            ...el,
            targeting_value:
              el.targeting_id !== 14
                ? [JSON.parse(el.targeting_value)]
                : el.targeting_value,
            uniqueIds: [el.targeting_id],
          });
        });

        const usableTargetingSet = newTargetingSet.sort(
          (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
        );

        usableTargetingSet.forEach((el: any) => {
          const param = params.find(
            (param: IFeatureSetParamDatum) => param.value === el.targeting_id
          );
          if (param) {
            if (
              param.names[0] === 'genericDrug' ||
              param.names[0] === 'selectedDiagnosis' ||
              param.names[0] === 'selectedProcedures' ||
              param.names[0] === 'selectedAdditionalOpts' ||
              param.names[0] === 'selectedInsurancePlanType' ||
              param.names[0] === 'selectedInsuranceProviders' ||
              param.names[0] === 'additionalInfo' ||
              param.names[0] === 'selectedDrugs'
            ) {
              defineFeatureSet[featureSetNumber].featureSetValues[
                param.names[0]
              ] = el.targeting_value[0];
            }
          }
        });
        setOperator(featureSet.condition_id);
        defineFeatureSet[featureSetNumber].targeting = usableTargetingSet.map(
          (el: any) => el.targeting_id
        );
        setDefineFeatureSet(defineFeatureSet);
      }
    }
  }, [updatedDataSet]);

  return (
    <Fragment>
      {modalToOpen && (
        <ModalComponent
          modalHeader="Delete FeatureSet"
          modalToOpen={modalToOpen}
          setModalToOpen={setModalToOpen}
          deleteSetParams={{
            setId: featureSetNumber,
            name: `FeatureSet ${featureSetOrderNumber}`,
            targetingArr: newTargeting,
            handleDelete: setDeletingSetId,
          }}
        />
      )}
      <div className="patientHealthAttributeWrap">
        
        {(featureSetNumber === 1 ||
          firstFeatureNumber === featureSetNumber) && (
          <div className="audienceDefinitionElem">
            <div className="audienceDefinitionleft">
              <p>Patient Health Attributes</p>
            </div>

            <div className="audienceDefinitionRight">
              <div className="labelAndValue">
                {newTargeting.length > 0 && (
                  <>
                    {featureSetBody.map((el: IFeatureSetElement) => (
                      <Fragment key={`${el.orderId}_${el.setId}`}>
                        {el.element}
                      </Fragment>
                    ))}
                  </>
                )}
                {newTargeting.length < 1 && (
                  <Select
                    options={targetingDDElementsForFs}
                    placeholder="Choose"
                    onChange={(data: number) => handleTargetableOptions(data)}
                    value={null}
                    disabled={disabled || isGlobalDisabled}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {featureSetNumber !== 1 && firstFeatureNumber !== featureSetNumber && (
          <div className="audienceDefinitionElem patientHealthAttributeElem">
            <span className='pipe'></span>
            <div className="audienceDefinitionleft">
              <Select
                value={operator}
                placeholder="Choose"
                style={{ width: 110 }}
                onChange={handleOperator}
                disabled={disabled || isGlobalDisabled}
                options={operatorDDElements}
              />
            </div>
            <div className="audienceDefinitionRight">
              <div className="labelAndValue">
                {newTargeting.length > 0 && (
                  <>
                    {featureSetBody.map((el: IFeatureSetElement) => (
                      <Fragment key={`${el.orderId}_${el.setId}`}>
                        {el.element}
                      </Fragment>
                    ))}
                  </>
                )}
                {newTargeting.length < 1 && (
                  <Select
                    options={targetingDDElementsForFs}
                    placeholder="Choose"
                    onChange={(data: number) => handleTargetableOptions(data)}
                    value={null}
                    disabled={isGlobalDisabled || disabled}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DefinedFeatureSet;
