import React, { FC, useState, useEffect } from 'react';
import {
  IFeatureSetDataState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
} from '../../../../models/interfaces';
import { useFeatureSetDataHandlingStore } from '../../../../stores';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterAudienceTypeElementsStore,
  useMasterBrandElementStore,
} from '../../../../stores/masterData.store';
import {
  useNotificationMessage,
  usePostApiInterceptor,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import { PageHeader } from '../../../sharedComponent';
import { ai } from '../../../../assets/images';
import EstimateAlert from './EstimateAlert';
import {
  ActivationTable,
  AudienceDestinationModal,
  ImpressionPixelModal,
} from '../../AudienceActivation/AudienceActivationComponent';
import { Modal, Tooltip } from 'antd';

type TImpPixelBody = {
  imp_pixel_id: number;
  activation_id: number;
};

type TActivatesBody = {
  audience_id: number;
  estimate_id: number;
  partner_id: number;
  size: number | null;
  status_id: number;
};

const ActivateAudience: FC = () => {
  document.title = 'Activate Audience - BranchLab';

  const { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    );

  const [impLoading, setImpLoading] = useState(false),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [body, setBody] = useState<{
      audience_id: string;
    } | null>(null),
    [step, setStep] = useState(1),
    [estimatedSize, setEstimatedSize] = useState<string>(''),
    [activateModal, setActivateModal] = useState(false),
    [isImpressionOpen, setIsImpressionOpen] = useState(false),
    [modalToOpen, setModalToOpen] = useState(false),
    [activationBody, setActivationBody] = useState<TActivatesBody | null>(null),
    [isActivationMounted, setIsActivationMounted] = useState<boolean>(false),
    [activatedData, setActivatedData] = useState<any>([]),
    [impPixelMounted, setImpPixelMounted] = useState<boolean>(false),
    [impPixelBody, setImpPixelBody] = useState<TImpPixelBody | null>(null),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false }),
    [partner, setPartner] = useState<string | null>(null),
    [impPixel_Code, setImpPixel_Code] = useState<null | string>(null);

  const { context, destroyMessage } = useNotificationMessage(messageObj);
  const audienceTypeLookup = new Map(
    audienceTypeDDElements.map((item) => [item.value, item.label])
  );
  const agencyLookup = new Map(
    agencyDDElements.map((item) => [item.value, item.label])
  );
  const advertiserLookup = new Map(
    advertiserDDElements.map((item) => [item.value, item.label])
  );
  const brandLookup = new Map(
    brandDDElements.map((item) => [item.value, item.label])
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    if (updatedDataSet?.activation_list?.length > 0) {
      setActivatedData(updatedDataSet?.activation_list);
    }
  }, [updatedDataSet]);

  usePostApiInterceptor(
    isActivationMounted,
    activationBody,
    RoutesEnum.SAVE_ACTIVATES,
    (data: any, error: any) => {
      if (error || !data) {
        updateErrorMessage('error', 'Error occurred while saving activation');
        return;
      }
      updateErrorMessage('success', 'Audience activation started');
      setActivationBody(null);
      if (updatedDataSet.audience_id) {
        setBody({ audience_id: updatedDataSet.audience_id });
        setIsMounted(true);
      }
      setIsActivationMounted(false);
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_ACTIVATION,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data) {
        updateErrorMessage(
          'error',
          'Error occurred while getting activation data'
        );
      }
      updatedDataSet['activation_list'] = data.activation_list ?? [];
      setActivatedData(data.activation_list ?? []);
      setUpdatedDataSet(updatedDataSet);
    }
  );

  usePostApiInterceptor(
    impPixelMounted,
    impPixelBody,
    RoutesEnum.SAVE_IMP_PIXEL,
    (data: any, error: any) => {
      if (error || !data) {
        updateErrorMessage(
          'error',
          'Error occurred while saving Impression pixel'
        );
        return;
      }
      setImpPixel_Code(data.pixel_code);
      updateErrorMessage('success', 'Impression pixel saved successfully');
      setImpLoading(false);
      setImpPixelBody(null);
      setImpPixelMounted(false);
      setBody({ audience_id: updatedDataSet.audience_id });
      setIsMounted(true);
    }
  );

  const DestroyMessage = () => {
    destroyMessage('save');
    setMessageObj({ isShowing: false });
  };

  const attachPixel = (id: number) => {
    setImpLoading(true);
    DestroyMessage();
    setImpPixelBody({
      imp_pixel_id: 0,
      activation_id: id,
    });
    setImpPixelMounted(true);
  };

  const handleActive = () => {
    setActivationBody({
      audience_id: updatedDataSet.audience_id,
      estimate_id: updatedDataSet.estimated_info[0].estimate_id,
      partner_id: parseInt(partner ?? '1'),
      size: estimatedSize ? parseInt(estimatedSize) : null,
      status_id: 1,
    });
    setIsActivationMounted(true);
    setActivateModal(false);
    setEstimatedSize('');
    setStep(1);
    setPartner(null);
  };

  const getDaysFromDate = (dateString: string): number => {
    const inputDate = new Date(dateString);
    if (isNaN(inputDate.getTime())) return 0;

    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - inputDate.getTime());
    return Math.floor(timeDiff / (24 * 60 * 60 * 1000));
  };

  const checkConfirmation = () => {
    if (partner !== null) {
      setModalToOpen(true);
    } else {
      setActivateModal(false);
      setStep(1);
    }
  };

  return (
    <>
      {context}
      <PageHeader
        title="Activate Audience"
        componentSpecificClass="audienceActivationPageHeader"
        disabled={updatedDataSet?.activation_list?.length === 0}
      />
      <div className="infoElemWrap">
        <div className="chart1">
          <p className="reviewAudienceInfo">
            <span>
              <strong>Audience Name:</strong> {updatedDataSet.title}
            </span>
            <span>
              <strong>Type:</strong>{' '}
              {audienceTypeLookup.get(updatedDataSet.audience_type_id)}
            </span>
            <span>
              <strong>Agency:</strong>{' '}
              {agencyLookup.get(updatedDataSet.agency_id)}
            </span>
            <span>
              <strong>Advertiser:</strong>{' '}
              {advertiserLookup.get(updatedDataSet.advertiser_id)}
            </span>
            <span>
              <strong>Brand:</strong> {brandLookup.get(updatedDataSet.brand_id)}
            </span>
          </p>
          {updatedDataSet?.ai_summary?.length > 0 && (
            <div className="aiSummary d-flex">
              <Tooltip title="AI Summary">
                <img src={ai} alt="" className="aiIcon" />
              </Tooltip>
              <p>{updatedDataSet.ai_summary}</p>
            </div>
          )}
        </div>
      </div>
      <div className="audienceActivationTable mb-20">
        {updatedDataSet.estimate_info &&
          getDaysFromDate(updatedDataSet.estimate_info[0].status) > 45 && (
            <EstimateAlert
              className="audienceAlertInfo"
              msgBody={
                <p>
                  The audience is{' '}
                  {getDaysFromDate(updatedDataSet.estimate_info[0].status)} days
                  old
                </p>
              }
            />
          )}
        <ActivationTable
          selectedAudienceType={updatedDataSet.audience_type_id}
          isLoading={false}
          isActive={
            !updatedDataSet.estimated_info ||
            updatedDataSet.estimated_info?.length === 0
          }
          activateModal={setActivateModal}
          data={activatedData}
          destroy={DestroyMessage}
          openModal={() => setIsImpressionOpen(true)}
          disabled={
            impLoading ||
            !updatedDataSet?.estimated_info ||
            updatedDataSet?.estimated_info[0]?.status === "Regenerating" || 
            updatedDataSet?.estimated_info[0]?.status === "Pending"
          }
          attachPixel={attachPixel}
          setImpPixel_Code={setImpPixel_Code}
        />
        <AudienceDestinationModal
          modalToOpen={activateModal}
          estimatedSize={estimatedSize}
          setEstimated_Size={setEstimatedSize}
          setPartner={setPartner}
          partner={partner}
          step={step}
          setStep={setStep}
          checkConfirmation={checkConfirmation}
          selectedAudienceType={updatedDataSet.audience_type_id}
          activate={handleActive}
        />
        <Modal
          centered
          open={modalToOpen}
          onOk={() => {
            setModalToOpen(false);
            setPartner(null);
            setStep(1);
            setActivateModal(false);
          }}
          onCancel={() => {
            setModalToOpen(false);
          }}
          okText="Yes"
          cancelText="No"
          maskClosable={false}
          className="alertSpecificClass"
          width={'330px'}
          closable={false}
        >
          <div className="modalGenericContent">
            <div className="modalGenericTextContent">
              <p>Are you sure you want to cancel?</p>
            </div>
          </div>
        </Modal>
        {isImpressionOpen && (
          <ImpressionPixelModal
            isOpen={isImpressionOpen}
            cancel={() => setIsImpressionOpen(false)}
            pixelFieldId={impPixel_Code}
          />
        )}
      </div>
    </>
  );
};

export default ActivateAudience;
