import { Table, TableColumnsType } from 'antd';
import { FC } from 'react';
import { AudienceData } from './DashboardContent';
import dayjs from 'dayjs';
import { useMasterStatusElementsStore } from '../../../stores';
import { IMasterStatusDDState } from '../../../models/interfaces';
import { NavLink } from 'react-router-dom';

export interface DataType {
  key: number;
  title: string;
  status: string;
  audience_type: string;
  created_on: string;
}
const CommonAudienceTable: FC<{
  audienceData: AudienceData;
  heading: string;
  isBuild: boolean;
}> = ({ audienceData, heading, isBuild }) => {
  const { statusDDElements } = useMasterStatusElementsStore(
    (state: IMasterStatusDDState) => state
  );

  const getStatusOptionClassName = (value: string) => {
    return statusDDElements.find((eachOption) => eachOption.value === value)
      ?.className;
  };

  const getStatusColumnValueJsx = (value: string) => {
    switch (value) {
      case 'Building':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#FFF598', color: '#645A00' }}
          >
            {value}
          </span>
        );
      case 'Estimated':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#F0F9FF', color: '#026AA2' }}
          >
            {value}
          </span>
        );
      case 'Activated':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#ECFDF3', color: '#2D912B' }}
          >
            {value}
          </span>
        );
    }
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <NavLink to={'/audience/' + record.key + '/define'} title={text}>
          {text}
        </NavLink>
      )
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'audience_type',
    },
    {
      title: 'Date',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusColumnValueJsx(text),
    },
  ];

  function extractParenthesesContent(inputString: string) {
    const start = inputString.indexOf('(');
    const end = inputString.indexOf(')');
    return start !== -1 && end !== -1
      ? inputString.slice(start + 1, end)
      : inputString;
  }

  function manipulateDataArray(dataArray: any) {
    dataArray?.forEach((item: any) => {
      item.audience_type = extractParenthesesContent(item.audience_type);
    });
    return dataArray;
  }

  return (
    <div className="commonAudienceTableWrap">
      <div className="sectionHeader">
        <h2>{heading}</h2>
      </div>
      <Table
        dataSource={manipulateDataArray(
          isBuild
            ? audienceData.recent_audience_build
            : audienceData.recent_audience_created
        )}
        columns={columns}
        pagination={false}
        loading={audienceData.isLoading}
      />
    </div>
  );
};

export default CommonAudienceTable;
