/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, Fragment, useCallback } from 'react';
import DefinedFeatureSet from './DefinedFeatureSet';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {
  Button,
  Collapse,
  GetProp,
  Tooltip,
  UploadFile,
  UploadProps,
} from 'antd';
import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useCheckDependentTargetingSelectedStore,
  useCheckIfDataModifiedStore,
  useCheckSavingEnabilityStore,
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
  useMasterAudienceTypeElementsStore,
  useMasterTargetingElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import {
  IAudienceSaveMethods,
  IAudienceSetUpState,
  IAudienceState,
  ICaregiverFeatureState,
  ICheckIfDataModifiedState,
  ICustomNPIList,
  IDebounceDropdownElements,
  IDependentTargetingSelectedState,
  IEachDropdownElementsType,
  IEnableSaveState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
  IFeatureSetElement,
  IGlobalFeatureState,
  IHcpFeatureState,
  IMasterAdditionalOptionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterLanguageDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterStatusDDState,
  IMasterTargetingDDState,
  IMasterTimeFrameDDState,
  IPresignedURLRequest,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  useAudienceSaveMethods,
  useAudienceSetUp,
  useAudienceSets,
  useCareGiverFeatureSet,
  useGlobalFeatureSet,
  useHcpFeatureSet,
} from '../../../../stores/audienceSets.store';
import { useNavigate, useParams } from 'react-router-dom';
import { usePostApiInterceptor, useRedirection } from '../../../../hooks';
import type { CollapseProps } from 'antd';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import GlobalFeatureSet from './GlobalFeatureSet';
import CareGiverFeatureSet from './CareGiverFeatureSet';
import HcpFeatureSet from './HcpFeatureSet';
import apiService from '../../../../services/Api.service';
import axios from 'axios';
import AdminOptions from './AdminOptions';
import MessageFormatter from './MessageFormatter';
import {
  useMasterAdditionalOptionElementsStore,
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterGenderElementsStore,
  useMasterInsurancePlanProviderElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterLanguageElementStore,
  useMasterOperatorElementsStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterStateElementsStore,
  useMasterStatusElementsStore,
  useMasterTimeFrameElementsStore,
} from '../../../../stores/masterData.store';
import { updateAudienceData } from '../../../../utils/audience';

interface AdminOption {
  admin_option_id: number;
  is_search_for_treatments: boolean;
  drugs_count: number;
  is_distinct: boolean;
  updated_on?: string;
  is_updating?: boolean;
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
type UploadReqType = { url?: string; body?: any; isError: boolean };

function separateFeatureSetsByTargetingId(data: any): {
  withTargetingId: any;
  withoutTargetingId: any;
  withCaregiver: any;
  withHcp: any;
} {
  const withTargetingId: any = [];
  const withoutTargetingId: any = [];
  let withCaregiver: any = null;
  let withHcp: any = null;
  data.forEach((featureSet: any) => {
    if (featureSet?.is_global) {
      withTargetingId.push(featureSet);
    } else if (featureSet.is_caregiver) {
      withCaregiver = featureSet;
    } else if (featureSet.is_hcp) {
      withHcp = featureSet;
    } else {
      withoutTargetingId.push(featureSet);
    }
  });

  return { withTargetingId, withoutTargetingId, withCaregiver, withHcp };
}

const TargetableFeatureSetup: FC<{
  selectedAudienceType: number | null;
  selectedAdditionalOpts: boolean;
  pixelId: number;
  audienceId: number;
  disabled: boolean;
  selectedStatus: number | null;
  prevSetId: number;
  deletingSetId: number[] | null;
  setDeletingSetId: React.Dispatch<React.SetStateAction<number[] | null>>;
  setPrevSetId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<number | null>>;
  setSelectedAdditionalOpts: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedAudienceType: (value: number | null) => void;
  setFocusOnTitle: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;
  setAudienceId: React.Dispatch<React.SetStateAction<number>>;
  setMessageObj: React.Dispatch<React.SetStateAction<any>>;
}> = ({
  setMessageObj,
  disabled,
  setFocusOnTitle,
  selectedAudienceType,
  selectedAdditionalOpts,
  pixelId,
  prevSetId,
  deletingSetId,
  setDeletingSetId,
  setPrevSetId,
  audienceId,
  setAudienceId,
  selectedStatus,
  setSelectedStatus,
  setSelectedAudienceType,
  setSelectedAdditionalOpts,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { ifUpdated, setIsInitiatedSaving, setIfUpdated } =
      useFeatureSetDataCheckStore((state: IFeatureSetDataCheckState) => state),
    {
      isEnableSaveForEachSet,
      setIsEnableSaveForEachSet,
      setIisFeatureSetsFilled,
      isFeatureSetsFilled,
    } = useCheckSavingEnabilityStore((state: IEnableSaveState) => state),
    {
      isDependentTargetingSelectedForEachSet,
      setIsDependentTargetingSelectedForEachSet,
    } = useCheckDependentTargetingSelectedStore(
      (state: IDependentTargetingSelectedState) => state
    ),
    {
      audienceTitle,
      audienceAgency,
      audienceBrand,
      advertiser,
      setAdvertiser,
      setAudienceAgency,
      setAudienceBrand,
      setAudienceTitle,
      setAudiencePageNumber,
      isGlobalDisabled,
      setIsGlobalDisabled,
    } = useAudienceSetUp((state: IAudienceSetUpState) => state),
    { isSaveInitiated, setIsSaveInitiated } = useAudienceSaveMethods(
      (state: IAudienceSaveMethods) => state
    ),
    {
      isCancelled,
      audienceSummary,
      isDefineModified,
      defineFeatureSet,
      defineFeatureData,
      selectedSavingOpt,
      firstFeatureNumber,
      defineFeatureCount,
      setIsDataSaved,
      setIsCancelled,
      setIsDataLoading,
      setAudienceSummary,
      setDefineFeatureSet,
      setIsDefineModified,
      setDefineFeatureData,
      setSelectedSavingOpt,
      setDefineFeatureCount,
      setFirstFeatureNumber,
      setIsDefinitionUpdated,
    } = useAudienceSets((state: IAudienceState) => state),
    { updatedDataSet, savingDataSet, setUpdatedDataSet } =
      useFeatureSetDataHandlingStore((state: IFeatureSetDataState) => state),
    { userInformation } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    {
      setCaregiverFeatureSet,
      setCaregiverTargeting,
      caregiverFeatureSet,
      caregiverTargeting,
      isCaregiverModified,
      setIsCaregiverModified,
    } = useCareGiverFeatureSet((state: ICaregiverFeatureState) => state),
    {
      setGlobalFeatureSet,
      setGlobalTargeting,
      globalFeatureSet,
      globalTargeting,
      isGlobalModified,
      setIsGlobalModified,
    } = useGlobalFeatureSet((state: IGlobalFeatureState) => state),
    {
      setHcpFeatureSet,
      setHcpTargeting,
      hcpTargeting,
      hcpFeatureSet,
      isHcpModified,
      setIsHcpModified,
    } = useHcpFeatureSet((state: IHcpFeatureState) => state),
    { targetingDDElements, setTargetingDDElementsForFs } =
      useMasterTargetingElementsStore(
        (state: IMasterTargetingDDState) => state
      ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { statusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { raceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { insurancePlanTypeDDElements } = useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    ),
    { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { languageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLanguageDDState) => state
    ),
    { ifDataModified, setIfDataModified } = useCheckIfDataModifiedStore(
      (state: ICheckIfDataModifiedState) => state
    ),
    { insurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      );
  const { resetAllStates, redirectAudienceListing } = useRedirection();

  const hasAnyChanges = Object.values(isDefineModified).some((value) => value);

  const [body, setBody] = useState<any>(''),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [adminData, setAdminData] = useState<AdminOption>({
      admin_option_id: 0,
      is_search_for_treatments: false,
      drugs_count: 0,
      is_distinct: false,
    }),
    [refetchBody, setRefetchBody] = useState<any>(null),
    [refetchMounted, setRefetchMounted] = useState(false),
    [globalMsg, setGlobalMsg] = useState<string>(''),
    [isSummary, setIsSummary] = useState<boolean>(false),
    [bodySummary, setBodySummary] = useState<any>(null),
    [isLoading, setIsLoading] = useState(false),
    [pendingData, setPendingData] = useState<any>(null),
    [globalFeatureSets, setGlobalFeatureSets] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [hcpFeaturesSet, setHcpFeaturesSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    }),
    [careGiverSet, setCareGiverSet] = useState<IFeatureSetElement>({
      setId: 0,
      orderId: 0,
    });

  useEffect(() => {
    if (updatedDataSet.is_dup) setIsGlobalModified(true);
  }, []);

  useEffect(() => {
    // Only check for modifications if updatedDataSet is not empty
    if (Object.keys(updatedDataSet).length > 0) {
      const isModified =
        audienceTitle !== updatedDataSet.title ||
        audienceAgency !== updatedDataSet.agency_id ||
        audienceBrand !== updatedDataSet.brand_id ||
        advertiser !== updatedDataSet.advertiser_id ||
        selectedAudienceType !== updatedDataSet.audience_type_id ||
        selectedAdditionalOpts !== updatedDataSet.additional_option_id;
      setIfDataModified(isModified);
    } else setIfDataModified(true);
  }, [
    audienceAgency,
    audienceTitle,
    audienceBrand,
    advertiser,
    selectedAudienceType,
    selectedAdditionalOpts,
  ]);

  const isChanged =
    isGlobalModified || isCaregiverModified || isHcpModified || hasAnyChanges;

  useEffect(() => {
    if (isCancelled) {
      setDefineFeatureCount(0);
      setIsCancelled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelled]);

  const featureSetBody = (feature_sets: IFeatureSetElement[]) => {
    const { withTargetingId, withoutTargetingId } =
      separateFeatureSetsByTargetingId(feature_sets);
    let newTargetingOptions: number[] = [];
    if (feature_sets.length > 0) {
      const newFeatureSets: IFeatureSetElement[] = [];
      let largestSetId = 0;
      if (withoutTargetingId.length > 0) {
        withoutTargetingId
          .sort((a: any, b: any) => a.feature_set_rank - b.feature_set_rank)
          .forEach((el: any, index: number) => {
            newTargetingOptions.push(el.targeting[0].targeting_id);
            if (el.feature_set_id > largestSetId) {
              largestSetId = el.feature_set_id;
            }
            newFeatureSets.push({
              setId: el.feature_set_id,
              orderId: index + 1,
            });
          });
        setFirstFeatureNumber(withoutTargetingId[0].feature_set_id)
        setPrevSetId(largestSetId);
        setDefineFeatureCount(withoutTargetingId.length);
        setDefineFeatureData(
          createFeatureSet(newFeatureSets, withoutTargetingId.length)
        );
      } else if (defineFeatureCount < 1) {
        setFirstFeatureNumber(1);
        handleAddFeature();
      }
      if (withTargetingId.length) {
        setGlobalFeatureSets({
          setId: withTargetingId[0].feature_set_id,
          orderId: 1,
          element: createGlobalElem(withTargetingId[0].feature_set_id, 1),
        });
      } else if (withTargetingId.length === 0) {
        setGlobalFeatureSets({
          setId: 0,
          orderId: 1,
          element: createGlobalElem(0, 0),
        });
      }
    } else {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 1,
        element: createGlobalElem(0, 0),
      });
      if (defineFeatureCount < 1) {
        setFirstFeatureNumber(1);
        handleAddFeature();
      }
    }
    let newOptions = targetingDDElements.filter(
      (tar) =>
        !newTargetingOptions.includes(Number(tar.value)) &&
        !tar.is_hpa &&
        !tar.is_caregiver &&
        !tar.is_global
    );

    let disableValue: number | null = null;

    if (newTargetingOptions.includes(12)) {
      disableValue = 16;
    } else if (newTargetingOptions.includes(16)) {
      disableValue = 12;
    } else if (newTargetingOptions.includes(10)) {
      disableValue = 18;
    } else if (newTargetingOptions.includes(18)) {
      disableValue = 10;
    }

    newOptions = newOptions.map((opt: any) =>
      opt.value === disableValue ? { ...opt, disabled: true } : opt
    );
    setTargetingDDElementsForFs(newOptions);
  };

  const getItems: () => CollapseProps['items'] = () => [
    {
      key: '1',
      label: <div className="audienceDefinitionleft">Admin Options</div>,
      children: (
        <AdminOptions
          data={adminData}
          setData={setAdminData}
          disabled={disabled}
        />
      ),
    },
  ];

  useEffect(() => {
    if (ifUpdated && Object.keys(updatedDataSet).length !== 0) {
      const { audience_id, status_id, feature_sets, admin_options } =
        updatedDataSet;
      setAudienceId(audience_id);
      setSelectedStatus(status_id);
      if (admin_options) {
        setAdminData(admin_options);
      }
      if (updatedDataSet.feature_sets) {
        featureSetBody(feature_sets);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ifUpdated]);

  const uploadNpiList = async (audience_id: number) => {
    const uploadableNPIs = hcpFeatureSet.customNPIList.filter(
      (el: ICustomNPIList) => el.file
    );
    if (uploadableNPIs?.length) {
      const tempPresignedUrlReq: any = {
        key: uploadableNPIs[0].key,
        url: RoutesEnum.GET_PRESIGNED_URL,
        body: {
          bucket: process.env.REACT_APP_S3_FOLDER_NAME,
          key: `${process.env.REACT_APP_ENV}/audience_id=${audience_id}/${uploadableNPIs[0].name}`,
          expiration: 6000,
        },
        file: uploadableNPIs[0].file as UploadFile,
      };
      await getPresignedURLs(tempPresignedUrlReq);
    }
  };

  const uploadAllFiles = async (requests: UploadReqType) => {
    try {
      if (!requests.isError && requests.url && requests.body) {
        const response = await axios.post(requests.url, requests.body, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('uploading successful');
        return response;
      }
    } catch (error: any) {
      console.error('Upload error:', error.message);
      throw error;
    }
  };

  const getPresignedURLs = async (requests: IPresignedURLRequest) => {
    try {
      const res = await apiService.post<any>(
        process.env.REACT_APP_BASE_URL + requests.url,
        requests.body,
        {},
        true
      );

      if (res) {
        const formData = new FormData();

        // Add presigned URL fields to formData
        for (const key in res.presigned_url.fields) {
          formData.append(key, res.presigned_url.fields[key]);
        }

        // Add file to formData
        formData.append(
          'file',
          hcpFeatureSet.customNPIList[0].file as FileType
        );

        const uploadRequest: UploadReqType = {
          url: res.presigned_url.url,
          body: formData,
          isError: false,
        };

        // Wait for the upload to complete
        return await uploadAllFiles(uploadRequest);
      }
    } catch (error: any) {
      console.error('Presigned URL error:', error.message);
      throw error;
    }
  };

  usePostApiInterceptor(
    refetchMounted,
    refetchBody,
    RoutesEnum.GET_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data || (data && !data.audience_info)) {
        setIsInitiatedSaving(false);
        return;
      }
      let newCopy = JSON.parse(JSON.stringify(updatedDataSet));
      let anotherCopy: any = data.audience_info;
      anotherCopy = {
        ...anotherCopy,
        estimated_info: newCopy.estimated_info,
        activation_list: newCopy.activation_list,
      };
      setUpdatedDataSet(anotherCopy);
      setRefetchBody(null);
      setRefetchMounted(false);
      eventAfterSave(data.audience_info.audience_id);
      setIsSaveInitiated(false);
    }
  );

  const fetchAudienceInfo = (audience_id: number) => {
    setRefetchBody({ audience_id: audience_id });
    setRefetchMounted(true);
  };

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.SAVE_FEATURE_SET_DATA,
    async (data: any, error: any) => {
      setBody('');
      setIsMounted(false);
      setIsInitiatedSaving(false);

      if (data && !error) {
        setMessageObj({
          duration: 5,
          isShowing: true,
          key: 'save',
          messagecontent: 'Audience is saved',
          type: 'success',
        });
        setIsDataSaved(true);
        if (hcpTargeting.includes(7) && isHcpModified) {
          await uploadNpiList(data.audience_id);
        }
        if(id){
          fetchAudienceInfo(data.audience_id);
        }else{
          eventAfterSave(data.audience_id);
          setIsSaveInitiated(false);
        }
      } else {
        setIsInitiatedSaving(false);
        updateErrorMessage('error', 'Error occurred while saving Audience.');
      }
    }
  );

  usePostApiInterceptor(
    isSummary,
    bodySummary,
    RoutesEnum.GET_FEATURE_SET_SUMMARY,
    (data: any, error: any) => {
      setIsSummary(false);
      setBodySummary(null);
      if (data && !error) {
        setAudienceSummary(data);
      }
      setIsLoading(false);
    }
  );

  const generateMessage = () => {
    setBodySummary({ globalMsg });
    setIsSummary(true);
  };

  const handleXLSXDownload = (audienceInfo: any) => {
    const tempData: any = { ...audienceInfo },
      data: any = [];
    for (const key in tempData) {
      switch (key) {
        case 'title': {
          data.push([handler[key], tempData[key]]);
          break;
        }
        case 'status_id': {
          const formattedSelectedElements = statusDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'agency_id': {
          const formattedSelectedElements = agencyDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'advertiser_id': {
          const formattedSelectedElements = advertiserDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'brand_id': {
          const formattedSelectedElements = brandDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'audience_type_id': {
          const formattedSelectedElements = audienceTypeDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'additional_option_id': {
          let formattedSelectedElements: string = '';
          if (tempData[key]) {
            formattedSelectedElements = additionalOptionDDElements[0].label;
            data.push([handler[key], formattedSelectedElements]);
          }
          break;
        }
        case 'ai_summary': {
          data.push([handler[key], tempData[key]]);
          break;
        }
        case 'pixel_name': {
          if (tempData[key]) {
            data.push([handler[key], tempData[key]]);
          }
          break;
        }
        case 'created_on': {
          data.unshift([
            handler[key],
            dayjs(tempData[key]).format('MM/DD/YYYY'),
          ]);
          break;
        }
        case 'feature_sets': {
          if (tempData[key]?.length) {
            data.push([]);
            const featureSetData = tempData[key].sort((a: any, b: any) => {
              // Handle caregiver priority (should be first)
              if (a.is_caregiver && !b.is_caregiver) return -1;
              if (!a.is_caregiver && b.is_caregiver) return 1;

              // Handle HCP priority (should be second)
              if (a.is_hcp && !b.is_hcp) return -1;
              if (!a.is_hcp && b.is_hcp) return 1;

              // Handle global priority (should be third)
              if (a.is_global && !b.is_global) return -1;
              if (!a.is_global && b.is_global) return 1;

              // If none of the special cases match, fall back to feature_set_rank
              return a.feature_set_rank - b.feature_set_rank;
            });

            data.push(
              ...formatFeatureSetData(
                featureSetData,
                genderDDElements,
                raceAndEthnicityDDElements,
                operatorDDElements,
                stateDDElements,
                insurancePlanTypeDDElements,
                timeFrameDDElements,
                additionalOptionDDElements,
                providerSpecialtyDDElements,
                insurancePlanProviderDDElements,
                languageDDElements
              )
            );
          }
          break;
        }
        default:
        // none
      }
    }

    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(data);

    data.forEach((el: any, i: number) => {
      if (el?.length && el[0] === 'Description:') {
        ws['!merges'] = ws['!merges'] || [];
        ws['!merges'].push({ s: { r: i, c: 1 }, e: { r: i, c: 3 } });
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const timestamp = Date.now();
    XLSX.writeFile(
      wb,
      `${audienceInfo.title}_${audienceInfo.audience_id}_${dayjs(
        timestamp
      ).format('YYYY-MM-DD HH:mm')}.xlsx`
    );
  };

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      duration: 5,
      isShowing: true,
      key: 'save',
      messagecontent,
      type,
    });
  };

  const createFeatureSet = (
    existingFeatureSets: IFeatureSetElement[] = [],
    targetFeatureCount: number = defineFeatureCount
  ): IFeatureSetElement[] => {
    const featureSets = existingFeatureSets.length
      ? existingFeatureSets
      : defineFeatureData;
    const newFeatureSets = featureSets
      .map(({ setId, orderId }, index) => {
        if (index === 0 && !firstFeatureNumber) {
          setFirstFeatureNumber(setId);
        }
        return { setId, orderId, element: createNewElem(setId, orderId) };
      });

    if (targetFeatureCount > featureSets.length) {
      const newSetId = prevSetId + 1;
      setPrevSetId(newSetId);
      newFeatureSets.push({
        setId: newSetId,
        orderId: targetFeatureCount,
        element: createNewElem(newSetId, targetFeatureCount),
      });
    }

    return newFeatureSets;
  };

  useEffect(() => {
    if (deletingSetId?.length) {
      const tempIsEnableSaveForEachSet = { ...isEnableSaveForEachSet },
        tempIsDependentTargetingSelectedForEachSet = {
          ...isDependentTargetingSelectedForEachSet,
        };
      delete isFeatureSetsFilled[`${deletingSetId[0]}`];
      delete tempIsEnableSaveForEachSet[`${deletingSetId[0]}`];
      delete tempIsDependentTargetingSelectedForEachSet[`${deletingSetId[0]}`];
      setIsEnableSaveForEachSet(tempIsEnableSaveForEachSet);
      setIsDependentTargetingSelectedForEachSet(
        tempIsDependentTargetingSelectedForEachSet
      );
      setIisFeatureSetsFilled(isFeatureSetsFilled);
      delete defineFeatureSet[deletingSetId[0]];
      setDefineFeatureSet(defineFeatureSet);
      setDefineFeatureCount(defineFeatureCount - 1);
      const tempFeatureSets = defineFeatureData
        .filter((el: IFeatureSetElement) => !deletingSetId.includes(el.setId))
        .map((el: IFeatureSetElement, index: number) => {
          if (index === 0) setFirstFeatureNumber(el.setId);
          return {
            setId: el.setId,
            orderId: index + 1,
            element: createNewElem(el.setId, index + 1),
          };
        });

      setDefineFeatureData(tempFeatureSets);
      setDeletingSetId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletingSetId]);

  const resetAllLocalStates = () => {
    setAudienceId(0);
    setSelectedAudienceType(null);
    setAudienceTitle('');
    setSelectedStatus(1);
    setSelectedAdditionalOpts(false);
    setDefineFeatureCount(0);
    setGlobalMsg('');
    setIsHcpModified(false);
    setIsCaregiverModified(false);
    setIsGlobalModified(false);
    setIsDefineModified({
      targeting: false,
      featureSetValues: false,
      operator: false,
    });
    setDefineFeatureData([]);
    setDefineFeatureSet({});
    setAudienceSummary('');
    setAudienceAgency(null);
    setAudienceBrand(null);
    setAdvertiser(null);
    setIisFeatureSetsFilled({});
    setIsEnableSaveForEachSet({});
    setAudiencePageNumber(1);
    setFirstFeatureNumber(0);
    setHcpTargeting([]);
    setHcpFeatureSet({
      selectedProviderSpecialty: [],
      customNPIList: [],
      selectedProviderTaxonomy: [],
    });
    setCaregiverTargeting([]);
    setCaregiverFeatureSet({
      caregiverGender: null,
      caregiverAge: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
    });
    setGlobalTargeting([]);
    setGlobalFeatureSet({
      timeFrame: null,
      dateRange: null,
      patientGender: null,
      selectedAge: [],
      selectedRaceAndEthnicity: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
      language: [],
    });
    setDeletingSetId(null);
    setTargetingDDElementsForFs([]);
  };

  const resetForHcp = () => {
    setIsCaregiverModified(false);
    setIsGlobalModified(false);
    setIsDefineModified({
      targeting: false,
      featureSetValues: false,
      operator: false,
    });
    setCaregiverTargeting([]);
    setCaregiverFeatureSet({
      caregiverGender: null,
      caregiverAge: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
    });
    setGlobalTargeting([]);
    setGlobalFeatureSet({
      timeFrame: null,
      dateRange: null,
      patientGender: null,
      selectedAge: [],
      selectedRaceAndEthnicity: [],
      selectedGeographyDmas: [],
      selectedGeographyStates: [],
      language: [],
    });
    Object.entries(defineFeatureSet).forEach(([key, value]: [string, any]) => {
      if (value.targeting.length > 0) {
        setDefineFeatureData([
          {
            setId: 1,
            orderId: 1,
            element: createNewElem(1, 1),
          },
        ]);
        setDefineFeatureSet({
          targeting: [],
          featureSetValues: {
            genericDrug: [],
            selectedDiagnosis: [],
            selectedDrugs: [],
            selectedProcedures: [],
            selectedInsurancePlanType: [],
            selectedInsuranceProviders: [],
            selectedAdditionalOpts: [],
            additionalInfo: '',
          },
          operator: 5,
        });
        setDefineFeatureCount(1);
        let newOptions = targetingDDElements.filter(
          (tar) => !tar.is_hpa && !tar.is_caregiver && !tar.is_global
        );
        setTargetingDDElementsForFs(newOptions);
      }
    });
  };

  useEffect(() => {
    if (hcpTargeting.includes(7)) {
      setIsGlobalDisabled(true);
      resetForHcp();
    } else {
      setIsGlobalDisabled(false);
    }
  }, [hcpTargeting]);

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      isEnableValues?.length &&
      !isEnableValues.includes(1) &&
      isEnableValues.includes(2) &&
      isEnableValues.find((el) => el !== 0)
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingDataSet]);

  useEffect(() => {
    const isEnableValues = Object.values(isEnableSaveForEachSet);
    if (
      !(defineFeatureData?.length && isEnableValues.find((el) => el !== 0)) &&
      !isEnableValues.includes(1) &&
      isSaveInitiated
    ) {
      getOverAllData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveInitiated]);

  // logic only for first time audience creation
  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0 && defineFeatureCount < 1) {
      setGlobalFeatureSets({
        setId: 0,
        orderId: 0,
        element: createGlobalElem(0, 0),
      });
      setFirstFeatureNumber(1);
      handleAddFeature();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0) {
      if (selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: 98,
          orderId: 98,
          element: createHcpElem(98, 98),
        });
      } else {
        setHcpFeaturesSet({
          setId: 0,
          orderId: 0,
        });
        setHcpTargeting([]);
        setHcpFeatureSet({
          selectedProviderSpecialty: [],
          customNPIList: [],
          selectedProviderTaxonomy: [],
        });
      }
    }
  }, [selectedAudienceType]);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length !== 0) {
      const { withHcp } = separateFeatureSetsByTargetingId(
        updatedDataSet.feature_sets
      );
      if (withHcp && selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: withHcp.feature_set_id,
          orderId: 98,
          element: createHcpElem(withHcp.feature_set_id, 98),
        });
      } else if (!withHcp && selectedAudienceType === 2) {
        setHcpFeaturesSet({
          setId: 98,
          orderId: 98,
          element: createHcpElem(98, 98),
        });
      } else {
        setHcpFeaturesSet({
          setId: 0,
          orderId: 0,
        });
        setHcpTargeting([]);
        setHcpFeatureSet({
          selectedProviderSpecialty: [],
          customNPIList: [],
          selectedProviderTaxonomy: [],
        });
      }
    }
  }, [selectedAudienceType]);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length === 0) {
      if (selectedAdditionalOpts) {
        setCareGiverSet({
          setId: 99,
          orderId: 99,
          element: createCareGiverElem(99, 99),
        });
      } else {
        setCareGiverSet({
          setId: 0,
          orderId: 0,
        });
        setCaregiverTargeting([]);
        setCaregiverFeatureSet({
          caregiverGender: null,
          caregiverAge: [],
          selectedGeographyDmas: [],
          selectedGeographyStates: [],
        });
      }
    }
  }, [selectedAdditionalOpts]);

  useEffect(() => {
    if (Object.keys(updatedDataSet).length !== 0) {
      const { withCaregiver } = separateFeatureSetsByTargetingId(
        updatedDataSet.feature_sets
      );
      if (withCaregiver && selectedAdditionalOpts) {
        setCareGiverSet({
          setId: withCaregiver.feature_set_id,
          orderId: withCaregiver.order_id,
          element: createCareGiverElem(
            withCaregiver.feature_set_id,
            withCaregiver.order_id
          ),
        });
      } else if (!withCaregiver && selectedAdditionalOpts) {
        setCareGiverSet({
          setId: 99,
          orderId: 99,
          element: createCareGiverElem(99, 99),
        });
      } else {
        setCareGiverSet({
          setId: 0,
          orderId: 0,
        });
        setCaregiverTargeting([]);
        setCaregiverFeatureSet({
          caregiverGender: null,
          caregiverAge: [],
          selectedGeographyDmas: [],
          selectedGeographyStates: [],
        });
      }
    }
  }, [selectedAdditionalOpts]);

  const createCareGiverElem = (setId: number, orderId: number) => {
    return (
      <CareGiverFeatureSet
        featureSetNumber={setId}
        selectedAdditionalOpts={selectedAdditionalOpts}
        disabled={disabled}
      />
    );
  };

  const createHcpElem = (setId: number, orderId: number) => {
    return (
      <HcpFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        disabled={disabled}
      />
    );
  };

  const createGlobalElem = (setId: number, orderId: number) => {
    return (
      <GlobalFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        disabled={disabled}
      />
    );
  };

  const createNewElem = (setId: number, orderId: number) => {
    return (
      <DefinedFeatureSet
        featureSetNumber={setId}
        featureSetOrderNumber={orderId}
        disabled={disabled}
        setDeletingSetId={setDeletingSetId}
      />
    );
  };

  const getOverAllData = useCallback(() => {
    setMessageObj({ isShowing: false });
    const newFeatureSets = Object.values(savingDataSet);
    const isEnableValues = Object.values(isEnableSaveForEachSet);

    const shouldPrepareData =
      ((newFeatureSets?.length ||
        !(
          defineFeatureData?.length &&
          isEnableValues.some((value) => value !== 0)
        )) &&
        !selectedAdditionalOpts &&
        isSaveInitiated) ||
      ((newFeatureSets?.length || selectedAdditionalOpts) &&
        isSaveInitiated &&
        isEnableValues.some((value) => value !== 0));

    if (!shouldPrepareData) {
      return;
    }

    const featureSetDetails = newFeatureSets.filter(
      (el: any) => el.targeting?.length
    );

    adminData['is_updating'] = adminData.admin_option_id !== 0;

    const overAllData = {
      audience_id: audienceId,
      user_id: userInformation.user_id,
      audience_type_id: selectedAudienceType ?? 0,
      title: audienceTitle,
      agency_id: audienceAgency,
      brand_id: audienceBrand,
      advertiser_id: advertiser,
      status_id: selectedStatus,
      admin_options: adminData,
      ai_summary:
        audienceSummary.length === 0
          ? updatedDataSet.ai_summary
          : audienceSummary,
      pixel_id: pixelId === 0 ? null : pixelId,
      additional_option_id: selectedAdditionalOpts,
      feature_sets: featureSetDetails?.length ? featureSetDetails : [],
      is_edited: ifDataModified || disabled ? 1 : 0,
    };

    if (isChanged) {
      setIsDefinitionUpdated(true);
      setIsLoading(true);
      setPendingData(overAllData);
      generateMessage();
    } else {
      setBody(overAllData);
      setIsMounted(true);
    }

    const hasEstimations = updatedDataSet.estimated_info?.length > 0;

    if (selectedSavingOpt === '3' && !isChanged && !ifDataModified) {
      setIsSaveInitiated(false);
      navigate(`/audience/${id}/review`);
      setSelectedSavingOpt('');
      setIsSaveInitiated(false);
      return;
    }

    if (selectedSavingOpt === '4' && hasEstimations) {
      setIsSaveInitiated(false);
      navigate(`/audience/${id}/activate`);
      setSelectedSavingOpt('');
    }
  }, [
    savingDataSet,
    defineFeatureData?.length,
    selectedAdditionalOpts,
    isSaveInitiated,
    adminData,
    selectedAudienceType,
    audienceTitle,
    audienceAgency,
    audienceBrand,
    advertiser,
    selectedStatus,
    audienceSummary,
    pixelId,
    selectedSavingOpt,
  ]);

  useEffect(() => {
    if (!isLoading && pendingData) {
      pendingData['ai_summary'] = audienceSummary;
      setBody(pendingData);
      setIsMounted(true);
      setPendingData(null);
    }
  }, [isLoading, pendingData]);

  const resetIsDataSavedState = () => {
    setTimeout(() => {
      setIsDataSaved(false);
    }, 500);
  };

  const eventAfterSave = (audienceId: number) => {
    const tempDdElem = [...targetingDDElements],
      usableDdElem = tempDdElem.filter((el: any) => !el.is_global);

    usableDdElem.sort((a: any, b: any) => a.order_id - b.order_id);
    const usableSelectedSavingOpt = selectedSavingOpt;
    switch (usableSelectedSavingOpt) {
      case '1': {
        if (!updatedDataSet.audience_id) {
          resetAllStates();
          setIfUpdated(false);
          resetAllLocalStates();
          resetIsDataSavedState();
          setFocusOnTitle(1);
          setSelectedSavingOpt('');
          setIsDataLoading(true);
          navigate(`/audience/${audienceId}/define`);
        }
        setIsHcpModified(false);
        setIsCaregiverModified(false);
        setIsGlobalModified(false);
        setIsDefineModified({
          targeting: false,
          featureSetValues: false,
          operator: false,
        });
        break;
      }
      case '2': {
        resetAllStates();
        setIfUpdated(false);
        resetAllLocalStates();
        resetIsDataSavedState();
        redirectAudienceListing();
        setFocusOnTitle(1);
        setSelectedSavingOpt('');
        break;
      }
      case '3': {
        setSelectedSavingOpt('');
        navigate(`/audience/${audienceId}/review`);
        break;
      }
    }
  };

  const handleAddFeature = () => {
    setDefineFeatureCount(defineFeatureCount + 1);
    setDefineFeatureData(createFeatureSet([], defineFeatureCount + 1));
  };

  return (
    <div className="featureSetWholeWrapBlock">
      <div className="audienceDefinitionWrap">
        <div className="audienceDefinitionHeader">
          <h3>Audience Definition</h3>
          {Object.keys(updatedDataSet).length > 0 && (
            <Tooltip title="Export Audience Details">
              <button
                disabled={Object.keys(updatedDataSet).length === 0}
                onClick={() => handleXLSXDownload(updatedDataSet)}
              >
                Export Audience Details
              </button>
            </Tooltip>
          )}
        </div>
        {isChanged && (
          <MessageFormatter
            definedData={defineFeatureSet}
            globalTargeting={globalTargeting}
            globalFeatureSet={globalFeatureSet}
            hcpTargeting={hcpTargeting}
            hcpFeatureSet={hcpFeatureSet}
            caregiverTargeting={caregiverTargeting}
            caregiverFeatureSet={caregiverFeatureSet}
            setGlobalMsg={setGlobalMsg}
            audienceType={selectedAudienceType}
            isCaregiver={selectedAdditionalOpts}
          />
        )}
        {userInformation.role === 4 && (
          <div className="featureSetWhole pixelUrlSec adminoptionAccordion">
            <Collapse
              bordered={false}
              defaultActiveKey={['']}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition="end"
              items={getItems()}
            />
          </div>
        )}
        <div>{careGiverSet.element}</div>
        <div>{hcpFeaturesSet.element}</div>
        <div>{globalFeatureSets.element}</div>

        {defineFeatureCount > 0 &&
          defineFeatureData.map((el: IFeatureSetElement) => (
            <Fragment key={el.setId}>{el.element}</Fragment>
          ))}
      </div>
      {defineFeatureCount > 0 && (
        <div className="addNewFeaturesetbtn addPatientHealthAttribute">
          <Button
            onClick={handleAddFeature}
            className="secondaryBtn"
            disabled={defineFeatureCount >= 4 || disabled || isGlobalDisabled}
          >
            <PlusOutlined />
            <span className='pipe'></span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default TargetableFeatureSetup;

const handler: any = {
  created_on: 'Date:',
  title: 'Title:',
  status_id: 'Status:',
  advertiser_id: 'Advertiser:',
  agency_id: 'Agency:',
  brand_id: 'Brand:',
  audience_type_id: 'Audience Type:',
  feature_set_rank: 'FeatureSet',
  condition_id: 'OPERATOR',
  pixel_name: 'Pixel',
  additional_option_id: 'Additional Options:',
  ai_summary: 'Description:',
  1: 'Timeframe',
  2: 'Patient Gender',
  3: 'Patient Age',
  4: 'Race & Ethnicity',
  5: 'State',
  6: 'Provider Specialty',
  7: 'Custom NPI List',
  8: 'Hospital',
  9: 'Diagnosis',
  10: 'Drug',
  11: 'Procedure',
  12: 'Payer Type',
  13: 'Additional Option',
  14: 'Additional Information',
  15: 'DMA',
  16: 'Insurance Provider',
  18: 'Generic Drug',
  19: 'Language',
  20: 'Provider Taxonomy',
  21: 'Caregiver Gender',
  22: 'Caregiver Age',
};

const formatFeatureSetData = (
  fsd: any,
  gd: IEachDropdownElementsType[],
  rad: IEachDropdownElementsType[],
  od: IEachDropdownElementsType[],
  sd: IEachDropdownElementsType[],
  ipd: IEachDropdownElementsType[],
  tfd: IEachDropdownElementsType[],
  aod: IEachDropdownElementsType[],
  psd: IEachDropdownElementsType[],
  ip: IEachDropdownElementsType[],
  ld: IEachDropdownElementsType[]
) => {
  const data: any = [];
  let featureCount = 0;
  fsd.forEach((el: any) => {
    if (el['condition_id'] && featureCount !== 0) {
      const formattedSelectedElements = od.find(
        (fe: IEachDropdownElementsType) => fe.value === el['condition_id']
      )?.label;

      el['condition_id'] &&
        data.push([], [handler['condition_id'], formattedSelectedElements]);
    }

    for (const featureKey in el) {
      if (featureCount === 0) {
        featureCount += 1;
      }
      switch (featureKey) {
        case 'feature_set_rank': {
          // Helper function to determine profile type
          const getProfileType = (element: any) => {
            if (element.is_global) return 'Patient Profile';
            if (element.is_caregiver) return 'Caregivers Profile';
            if (element.is_hcp) return 'Healthcare Professional Attributes';
            return element[featureKey];
          };

          data.push([], [handler[featureKey], getProfileType(el)]);
          break;
        }
        case 'targeting': {
          const targetingDataSet = el[featureKey].sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );
          data.push(['Features']);

          data.push(
            ...formatTargetingDataSet(
              targetingDataSet,
              gd,
              rad,
              sd,
              ipd,
              tfd,
              aod,
              psd,
              ip,
              ld
            )
          );
          break;
        }
        default:
        // None
      }
    }
  });

  return data;
};

const formatTargetingDataSet = (
  td: any,
  gd: IEachDropdownElementsType[],
  rad: IEachDropdownElementsType[],
  sd: IEachDropdownElementsType[],
  ipd: IEachDropdownElementsType[],
  tfd: IEachDropdownElementsType[],
  aod: IEachDropdownElementsType[],
  psd: IEachDropdownElementsType[],
  ip: IEachDropdownElementsType[],
  ld: IEachDropdownElementsType[]
) => {
  const sortedTd = td.sort(
      (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
    ),
    rearrangedTd = getRearrangedArray(sortedTd);

  let data: any = [];

  rearrangedTd.forEach((el: any) => {
    switch (el.targeting_id) {
      case 1: {
        const formattedSelectedElements = tfd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          Number(el.targeting_value) === 3
            ? ['', handler[`${el.targeting_id}`]]
            : ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 21:
      case 2: {
        const formattedSelectedElements = gd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 22:
      case 3: {
        data = [
          ...data,
          [
            '',
            handler[`${el.targeting_id}`],
            JSON.parse(el.targeting_value).join(' to '),
          ],
        ];
        break;
      }
      case 4: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                rad.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 5: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                sd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', 'Geography'],
          ['', '', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 7: {
        data = [
          ...data,
          [
            '',
            handler[`${el.targeting_id}`],
            JSON.parse(el.targeting_value)
              .map((el: any) => el.displayName)
              .join(', '),
          ],
        ];
        break;
      }
      case 6: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                psd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }

      case 9:
      case 10:
      case 18:
      case 20:
      case 11: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: IDebounceDropdownElements) =>
                [String(el.value)?.split('~')[0]] +
                ': ' +
                String(el.label)?.replaceAll('~', ' ')
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 12: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                ipd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 13: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                aod.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 14: {
        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], el.targeting_value],
        ];
        break;
      }
      case 15: {
        let newDmsList: IEachDropdownElementsType[] = [];
        sd.forEach((el: any) => {
          newDmsList = [...newDmsList, ...el.dma];
        });

        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                newDmsList.find(
                  (fe: IEachDropdownElementsType) => fe.value === el
                )?.label
            )
            .join(', ');

        if (!rearrangedTd.find((el: any) => el.targeting_id === 5)) {
          data = [
            ...data,
            ['', 'Geography'],
            ['', '', handler[`${el.targeting_id}`], formattedSelectedElements],
          ];
        } else {
          data = [
            ...data,
            ['', '', handler[`${el.targeting_id}`], formattedSelectedElements],
          ];
        }
        break;
      }
      case 16: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                ip.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 17: {
        const tempDateRange = JSON.parse(el.targeting_value),
          formattedDateRange = [
            dayjs(tempDateRange[0]).format('MM/DD/YYYY'),
            dayjs(tempDateRange[1]).format('MM/DD/YYYY'),
          ];

        data[data.length - 1] = [
          ...data[data.length - 1],
          formattedDateRange.join(' to '),
        ];
        break;
      }
      case 19: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                ld.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      default:
      // none
    }
  });

  return data;
};

const getRearrangedArray = (td: any) => {
  const tempTd = [...td],
    index5 = tempTd.findIndex((el: any) => el.targeting_id === 5),
    index15 = tempTd.findIndex((el: any) => el.targeting_id === 15);

  if (index5 !== -1 && index15 !== -1 && index5 !== index15 - 1) {
    if (index5 < index15) {
      const [item15] = tempTd.splice(index15, 1);
      tempTd.splice(index5 + 1, 0, item15);
    } else {
      const [item5] = tempTd.splice(index5, 1);
      tempTd.splice(index15, 0, item5);
    }
  }

  return tempTd;
};
