import { Table } from 'antd'
import { FC } from 'react'

const CommonTable:FC<{
    heading:string;
    columns: any;
    data: any;
    isLoading: boolean;
}> = ({ heading, columns, data, isLoading }) => {
  return (
    <div className="commonAudienceTableWrap">
      <div className="sectionHeader">
        <h2>{heading}</h2>
      </div>
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        loading={isLoading}
      />
    </div>
  )
}

export default CommonTable