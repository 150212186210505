import { FC, ReactNode, useEffect, useState } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { useParams } from 'react-router-dom';
import {
  useFeatureSetDataCheckStore,
  useFeatureSetDataHandlingStore,
} from '../../../stores';
import {
  IAudienceState,
  IFeatureSetDataCheckState,
  IFeatureSetDataState,
} from '../../../models/interfaces';
import { usePostApiInterceptor } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import AudienceSetupFieldsWrap from './AudienceSetupComponents/AudienceSetupFieldsWrap';
import { useAudienceSets } from '../../../stores/audienceSets.store';

const AudienceSetupPage: FC<{ children: ReactNode }> = ({ children }) => {
  const { id } = useParams();

  const [body, setBody] = useState<{ audience_id: string } | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [isReviewMounted, setIsReviewMounted] = useState(false),
    [isReviewBody, setIsReviewBody] = useState<{ audience_id: number } | null>(
      null
    );

  const { setIfUpdated } = useFeatureSetDataCheckStore(
      (state: IFeatureSetDataCheckState) => state
    ),
    { setIsDataLoading, isDataLoading } = useAudienceSets(
      (state: IAudienceState) => state
    ),
    { updatedDataSet, setUpdatedDataSet } = useFeatureSetDataHandlingStore(
      (state: IFeatureSetDataState) => state
    );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GET_FEATURE_SET_DATA,
    (data: any, error: any) => {
      setIsMounted(false);
      if (error || !data || (data && !data.audience_info)) {
        setIsDataLoading(false);
        return;
      }
      setUpdatedDataSet(data.audience_info);
      setIsReviewBody({ audience_id: parseInt(id ?? '1') });
      setIsReviewMounted(true);
    }
  );

  usePostApiInterceptor(
    isReviewMounted,
    isReviewBody,
    RoutesEnum.GET_ACTIVATION,
    (data: any, error: any) => {
      setIsReviewMounted(false);
      setIsReviewBody(null);
      if (error || !data) {
        return;
      }
      if (data.estimated_info) {
        updatedDataSet['estimated_info'] = data.estimated_info;
      }
      updatedDataSet['activation_list'] = data.activation_list ?? [];
      setUpdatedDataSet(updatedDataSet);
      setIsDataLoading(false);
    }
  );

  useEffect(() => {
    if (id) {
      setUpdatedDataSet({});
      setIsDataLoading(true);
      setBody((prev) => ({ ...prev, audience_id: id }));
      setIsMounted(true);
      setIfUpdated(true);
    } else {
      setIsDataLoading(false);
    }
  }, [id]);

  return (
    <div className="audienceSetupPage">
      <LeftPanel defaultSelectedKeys={['3', '31']} />
      {!isDataLoading && (
        <AudienceSetupFieldsWrap>{children}</AudienceSetupFieldsWrap>
      )}
    </div>
  );
};

export default AudienceSetupPage;
