import React, { FC, useEffect, useRef } from 'react';
import anychart from 'anychart';
import { AudienceData } from './DashboardContent';
import { TooltipContext } from '../../../models/interfaces';

type TAudience = { date: string; total_audience: number };

const getTotalAudiences = (data: TAudience[]): number => {
  return data.reduce((sum, entry) => sum + entry.total_audience, 0);
};

const CreatedAudience: FC<{
  audienceData: AudienceData;
  heading: string;
}> = ({ audienceData, heading }) => {
  const chartContainer = useRef(null);
  useEffect(() => {
    if (chartContainer.current) {
      const chart = anychart.column();

      const tooltip = chart.tooltip();

      tooltip.titleFormat(function (this: TooltipContext): string {
        try {
          // Convert x-value to date
          const date = new Date(this.x);
          // Check if valid date
          if (!isNaN(date.getTime())) {
            return date.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            });
          }
          return String(this.x);
        } catch (e) {
          return String(this.x);
        }
      });

      tooltip.format(function (this: TooltipContext): string {
        return 'Audiences : ' + this.value;
      });

      tooltip.background({
        fill: '#272727'
      });

      chart.title(false);

      const xAxis = chart.xAxis();
      xAxis.enabled(false);

      const yAxis = chart.yAxis();
      yAxis.enabled(false);

      chart.xGrid().enabled(false);
      chart.yGrid().enabled(false);
      chart.palette(['var(--bl-primary)']);
      if (chart.legend) {
        chart.legend().enabled(false);
      }

      chart.margin(0);

      const series = chart.getSeriesAt(0);
      if (series) {
        series.labels().enabled(false);
      }

      chart.data(audienceData.recent_audience_30);
      chart.background().enabled(false);
      chart.container(chartContainer.current);
      chart.draw();
    }
  }, [audienceData.recent_audience_30]);

  return (
    <div className="histogram">
      <div ref={chartContainer} className='histogramElem'/>
      <p>
        In the last thirty days
        {' '}
        <strong>
          {getTotalAudiences(audienceData.recent_audience_30)}
        </strong>{' '}
        audiences have been created.
      </p>
    </div>
  );
};

export default CreatedAudience;
