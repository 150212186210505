import { Route } from 'react-router-dom';
import { AuthGuard } from '../guards';
import {
  ActiveAudienceListingPage,
  AudienceActivationPage,
  AudienceListingPage,
  AudienceSetupPage,
  PixelListingPage,
  PixelSetupPage,
  ResearchListingPage,
} from '../components/pages';
import AddResearch from '../components/pages/Research/AddResearch';
import ViewAudiencePage from '../components/pages/ViewAudience/ViewAudiencePage';
import { AddAccount } from '../components/pages/AddAccount';
import { AccountManagement } from '../components/pages/AccountManagement';
import { AccountSettings } from '../components/pages/AccountSettings';
import { ProfilePage } from '../components/pages/Profile';
import { UserSettings } from '../components/pages/UserSettings';
import { OrganisationSettings } from '../components/pages/OrganisationSettings';
import { AuditSettings } from '../components/pages/AuditSettings';
import ReviewAudience from '../components/pages/AudienceSetup/AudienceSetupComponents/ReviewAudience';
import ActivateAudience from '../components/pages/AudienceSetup/AudienceSetupComponents/ActivateAudience';
import DefineAudience from '../components/pages/AudienceSetup/AudienceSetupComponents/DefineAudience';
import { UpdateAudiencePage } from '../components/pages/UpdateAudience';
import Dashboard from '../components/pages/Dashboard/Dashboard';

const AuthRoutes = [
  <Route
    key="Audience Listing"
    path="/audiences"
    element={<AuthGuard component={<AudienceListingPage />} />}
  />,
  <Route
    key="Create Audience"
    path="/audience/create"
    element={
      <AuthGuard
        component={
          <AudienceSetupPage>
            <DefineAudience />
          </AudienceSetupPage>
        }
      />
    }
  />,
  <Route
    key="Define Audience"
    path="/audience/:id/define"
    element={
      <AuthGuard
        component={
          <AudienceSetupPage>
            <DefineAudience />
          </AudienceSetupPage>
        }
      />
    }
  />,
  <Route
    key="Review Audience"
    path="/audience/:id/review"
    element={
      <AuthGuard
        component={
          <AudienceSetupPage>
            <ReviewAudience />
          </AudienceSetupPage>
        }
      />
    }
  />,
  <Route
    key="Activate Audience"
    path="/audience/:id/activate"
    element={
      <AuthGuard
        component={
          <AudienceSetupPage>
            <ActivateAudience />
          </AudienceSetupPage>
        }
      />
    }
  />,
  <Route
    key="Update Audience"
    path='/update-audience/:id'
    element={<AuthGuard component={<UpdateAudiencePage />} />}
  />,
  <Route
    key="Activation"
    path='/audience-activation/:audId'
    element={<AuthGuard component={<AudienceActivationPage />} />}
  />,
  <Route
    key="View Audience"
    path="/view-audience/:id"
    element={<AuthGuard component={<ViewAudiencePage />} />}
  />,
  <Route
    key="Audience Activation"
    path="/activations"
    element={<AuthGuard component={<ActiveAudienceListingPage />} />}
  />,
  <Route
    key="Pixel Listing"
    path="/pixel-listing"
    element={<AuthGuard component={<PixelListingPage />} />}
  />,
  <Route
    key="Create Pixel Listing"
    path="/create-Pixel"
    element={<AuthGuard component={<PixelSetupPage />} />}
  />,
  <Route
    key="Update Pixel Listing"
    path="/update-pixel/:id"
    element={<AuthGuard component={<PixelSetupPage />} />}
  />,
  <Route
    key="Research"
    path="/research"
    element={<AuthGuard component={<ResearchListingPage />} />}
  />,
  <Route
    key="Create Research Listing"
    path="/add-research"
    element={<AuthGuard component={<AddResearch />} />}
  />,
  <Route
    key="Edit Research Listing"
    path="/update-research/:id"
    element={<AuthGuard component={<AddResearch />} />}
  />,
  <Route
    key="Add Account"
    path="/add-account"
    element={<AuthGuard component={<AddAccount />} />}
  />,
  <Route
    key="Account Listing"
    path="/accounts"
    element={<AuthGuard component={<AccountManagement />} />}
  />,
  <Route
    key="Update Account"
    path="/add-account/:id"
    element={<AuthGuard component={<AddAccount />} />}
  />,
  <Route
    key="Account Settings"
    path="/account-settings"
    element={<AuthGuard component={<AccountSettings />} />}
  />,
  <Route
    key="Settings-Account"
    path="/settings/account"
    element={<AuthGuard component={<AccountSettings />} />}
  />,
  <Route
    key="Settings-User"
    path="/settings/user"
    element={<AuthGuard component={<UserSettings />} />}
  />,
  <Route
    key="Settings-Audit"
    path="/settings/audit"
    element={<AuthGuard component={<AuditSettings />} />}
  />,
  <Route
    key="Settings-Organization"
    path="/settings/organization"
    element={<AuthGuard component={<OrganisationSettings />} />}
  />,
  <Route
    key="Profile"
    path="/profile"
    element={<AuthGuard component={<ProfilePage />} />}
  />,
  <Route
    key="Dashboard"
    path="/"
    element={<AuthGuard component={<Dashboard/>} />}
  />,
];

export default AuthRoutes;
