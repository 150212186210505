import { Col, Row, TableColumnsType } from 'antd';
import { DonutPieChart } from '../../../../sharedComponent/ChartTypes/DonutPieChart';
import BarChartType from '../../../../sharedComponent/ChartTypes/BarChartType';
import CustomIndexChart from '../../../../sharedComponent/ChartTypes/CustomIndexChart';
import { usePostApiInterceptor } from '../../../../../hooks';
import { FC, useEffect, useState } from 'react';
import { RoutesEnum } from '../../../../../models/enums/apiRoutes';
import { CommonTable } from '../../../../sharedComponent/CommonTable';
import { usaStateIds } from '../../../../../demoData/baseData';
import BoxWhiskerChartType from '../../../../sharedComponent/ChartTypes/BoxWhiskerChartType';
import USAZip3Map from '../../../../sharedComponent/ChartTypes/UsaZipCodeMap';

interface IDmaType {
  DMA: string;
  city: string;
  patient_percentage: number;
}

interface IState10Type {
  DMA: string;
  city: string;
  patient_percentage: number;
}

type DataItem = {
  x: string;
  value: number;
  avg_claims_per_patient: number
};

type OriginalDataItem = DataItem & Record<string, unknown>;

const DmaColumns: TableColumnsType<IDmaType> = [
  {
    title: 'City',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Percentage',
    dataIndex: 'patient_percentage',
    key: 'patient_percentage',
    render: (text) => <>{text}%</>,
  },
];

const State10Columns: TableColumnsType<IState10Type> = [
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    render: (text) => <>{usaStateIds.find((item) => item.id === text)?.name}</>,
  },
  {
    title: 'Percentage',
    dataIndex: 'patient_percentage',
    key: 'patient_percentage',
    render: (text) => <>{text}%</>,
  },
];

const formatValues = (data: OriginalDataItem[]):any => {
  return data.map(({ x, avg_claims_per_patient }) => {
    return {
      x,
      value : avg_claims_per_patient,
    }
  })
}

const formatStateNames = (stateData) => {
  return stateData.map(({ x, value }) => {
    const state = usaStateIds.find((item) => item.id === x);
    return {
      x: state ? state.name : 'Unknown',
      value,
    };
  });
};

const AudienceInsightsWrap: FC<{
  audience_id: number;
  job_id: string;
}> = ({ audience_id, job_id }) => {
  const [body, setBody] = useState<any>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [ageData, setAgeData] = useState({
      index_data: [],
      data: [],
      isLoading: true,
    }),
    [geoData, setGeoData] = useState({
      DMATop10: [],
      States: [],
      StatesTop10: [],
      States_index: [],
      Zip5Map: [],
      isLoading: true,
    }),
    [climateData, setClimateData] = useState({
      index_data: [],
      data: [],
      isLoading: true,
    }),
    [healthCareData, setHealthCareData] = useState({
      overall: [],
      factors: [],
      isLoading: true,
    }),
    [urbanizationData, setUrbanizationData] = useState({
      index_data: [],
      data: [],
      isLoading: true,
    }),
    [genderData, setGenderData] = useState({
      index_data: [],
      data: [],
      formatted: [],
      isLoading: true,
    });

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GENDER_CLICKHOUSE,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      }
      setGenderData({
        index_data: data?.gender_index ?? [],
        data: data?.gender_patients ?? [],
        formatted: formatValues(data?.gender_patients) ?? [],
        isLoading: false,
      });
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.GEOGRAPHY_CLICKHOUSE,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      }
      setGeoData({
        DMATop10: data?.DMATop10 ?? [],
        States: data?.States ?? [],
        StatesTop10: data?.StatesTop10 ?? [],
        States_index: data?.States_index ?? [],
        Zip5Map: data?.Zip5Map ?? [],
        isLoading: false,
      });
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.URBANIZATION_CLICKHOUSE,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      }
      setUrbanizationData({
        index_data: data?.geo_urbanization_index ?? [],
        data: data?.geo_urbanization ?? [],
        isLoading: false,
      });
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.CLIMATE_CLICKHOUSE,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      }
      setClimateData({
        index_data: data?.geo_climate_index ?? [],
        data: data?.geo_climate ?? [],
        isLoading: false,
      });
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.AGE_CLICKHOUSE,
    (data: any, error: any) => {
      setAgeData({
        index_data: data?.age_group_index ?? [],
        data: data?.age_group_patients ?? [],
        isLoading: false,
      });
      if (error || !data) {
        return;
      }
      // setBody(null);
      // setIsMounted(false);
    }
  );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.HEALTHCARE_CLICKHOUSE,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      }
      setHealthCareData({
        overall: data?.overall_healthcare ?? [],
        factors: data?.healthcare_factors ?? [],
        isLoading: false,
      });
    }
  );

  useEffect(() => {
    setBody({
      audience_id: audience_id,
      job_id: job_id,
    });
    setIsMounted(true);
  }, []);

  const IsEmpty =
    (!genderData.isLoading && !genderData.data.length) ||
    (!ageData.isLoading && !ageData.data.length) ||
    (!healthCareData.isLoading && !healthCareData.overall.length) ||
    (!climateData.isLoading && !climateData.data.length) ||
    (!urbanizationData.isLoading && !urbanizationData.data.length) ||
    (!geoData.isLoading && !geoData.States.length);

  return (
    <div className="visualizationUIWrapElement">
      <div className="sectionHeader">
        <h2>Audience Visualization</h2>
      </div>
      {!IsEmpty ? (
        <>
          {!genderData.isLoading && (
            <div className="pieChartRow">
              <span className="visualizationUIHeaderTag">Gender</span>
              <Row gutter={16}>
                <Col span={12}>
                  <DonutPieChart
                    data={genderData.data}
                    heading="Gender by Percentage"
                  />
                </Col>
                <Col span={12}>
                  <BarChartType
                    addOnText="claims per person"
                    Data={genderData.formatted}
                    heading="Average Claims by Gender"
                  />
                </Col>
              </Row>
              <div className="visualizationTableWrap">
                <CustomIndexChart
                  dimension="Gender"
                  heading="Gender (vs. Population)"
                  index_data={genderData.index_data}
                  query_data={genderData.data}
                  dynamic_classname="visualizationTable"
                />
              </div>
            </div>
          )}
          {!ageData.isLoading && (
            <div className="pieChartRow">
              <span className="visualizationUIHeaderTag">Age</span>
              <Row gutter={16}>
                <Col span={12}>
                  <DonutPieChart
                    data={ageData.data}
                    heading="Age Groups by Percentage"
                  />
                </Col>
                <Col span={12}>
                  <BoxWhiskerChartType
                    data={genderData.data}
                    heading="Patient Age by Gender"
                  />
                </Col>
              </Row>
              <div className="visualizationTableWrap">
                <CustomIndexChart
                  dimension="Age Groups"
                  heading="Age Groups (vs. Population)"
                  index_data={ageData.index_data}
                  query_data={ageData.data}
                  dynamic_classname="visualizationTable"
                />
              </div>
            </div>
          )}
          {!geoData.isLoading && (
            <div className="pieChartRow">
              <span className="visualizationUIHeaderTag">Geography</span>
              <USAZip3Map
                zipData={geoData.Zip5Map}
                heading="Patients by Location"
              />
              <Row gutter={16}>
                <Col span={12}>
                  <div className="visualizationTableWrap">
                    <CommonTable
                      heading="Top 10 States by Patients"
                      isLoading={geoData.isLoading}
                      data={geoData.StatesTop10}
                      columns={State10Columns}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="visualizationTableWrap">
                    <CommonTable
                      heading="Top 10 DMAs by Patients"
                      isLoading={geoData.isLoading}
                      data={geoData.DMATop10}
                      columns={DmaColumns}
                    />
                  </div>
                </Col>
              </Row>
              <div className="visualizationTableWrap">
                <CustomIndexChart
                  dimension="State"
                  heading="Patients by State (vs. Population)"
                  index_data={geoData.States_index}
                  query_data={formatStateNames(geoData.States)}
                  dynamic_classname="visualizationTable"
                />
              </div>
            </div>
          )}
          {!climateData.isLoading && (
            <div className="pieChartRow">
              <span className="visualizationUIHeaderTag">
                Social Determinants of health
              </span>
              <p className="visualizationUIHeaderSubTag">Climate</p>
              <Row gutter={16}>
                <Col span={12}>
                  <DonutPieChart
                    data={climateData.data}
                    heading="Patients by Climate"
                  />
                </Col>
                <Col span={12}>
                  <div className="visualizationTableWrap">
                    <CustomIndexChart
                      dimension="Climate Zone"
                      heading="Patients by Climate (vs. Population)"
                      index_data={climateData.index_data}
                      query_data={climateData.data}
                      dynamic_classname="visualizationTable"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {!urbanizationData.isLoading && (
            <div className="pieChartRow">
              <p className="visualizationUIHeaderSubTag">Urbanization</p>
              <Row gutter={16}>
                <Col span={12}>
                  <BarChartType
                    Data={urbanizationData.data}
                    heading="Patients by Urbanicity"
                  />
                </Col>
                <Col span={12}>
                  <div className="visualizationTableWrap">
                    <CustomIndexChart
                      dimension="Urbanicity Group"
                      heading="Patients by Urbanicity (vs. Population)"
                      index_data={urbanizationData.index_data}
                      query_data={urbanizationData.data}
                      dynamic_classname="visualizationTable"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
          {!healthCareData.isLoading && (
            <div className="pieChartRow">
              <p className="visualizationUIHeaderSubTag">Healthcare Access</p>
              <Row gutter={16}>
                <Col span={12}>
                  <BarChartType
                    Data={healthCareData.overall}
                    heading="Overall Health of Patients"
                  />
                </Col>
                <Col span={12}>
                  <BarChartType
                    Data={healthCareData.factors}
                    heading="SDoH Factor Impact of Patients"
                  />
                </Col>
              </Row>
            </div>
          )}
        </>
      ) : (
        <p style={{
          color: '#80807c',
          marginTop : 0   
        }}>Visualizations are not available for this audience</p>
      )}
    </div>
  );
};

export default AudienceInsightsWrap;
