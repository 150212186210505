import React, { useEffect, useState } from 'react';
import { LeftPanel } from '../LeftNavPanel';
import { useNotificationMessage, usePostApiInterceptor } from '../../../hooks';
import { GlobalFilterModal, PageHeader } from '../../sharedComponent';
import {
  Button,
  ConfigProvider,
  Table,
  TableColumnsType,
  TableProps,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { IAuditListingParam } from '../../../models/interfaces';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { SortOrder } from 'antd/es/table/interface';
import { filter } from '../../../assets/images';
import { FilterMessage } from '../../sharedComponent/FilterMessage';
import { NavLink } from 'react-router-dom';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;

interface DataType {
  key: number;
  user: string;
  id: number;
  item: number;
  type: string;
  action_name: string;
  created_on: string;
  title: string;
  is_deleted: boolean;
}

const baseListingParam = {
  timeframe: null,
  dateRange: null,
  title: null,
  username: null,
  action_name: null,
  type: null,
  agency: null,
  brand: null,
  advertiser: null,
  filter_option: 1,
  sortColumn: 'updated_on',
  sortOrder: 'DESC',
  pageNumber: 1,
  rowsPerPage: 100,
};

const convertFiltersToNumberArray = (filters: any): IAuditListingParam => {
  const result: Partial<IAuditListingParam> = {
    sortOrder: baseListingParam.sortOrder,
    sortColumn: baseListingParam.sortColumn,
    pageNumber: baseListingParam.pageNumber,
    rowsPerPage: baseListingParam.rowsPerPage,
  };

  // Handle global filters
  if (filters.global) {
    switch (true) {
      case filters.global.agency !== undefined: {
        result.agency = convertToNumberArray(filters.global.agency);
        break;
      }
      case filters.global.advertiser !== undefined: {
        result.advertiser = convertToNumberArray(filters.global.advertiser);
        break;
      }
      case filters.global.brand !== undefined: {
        result.brand = convertToNumberArray(filters.global.brand);
        break;
      }
      case filters.global.dateRange !== undefined: {
        result.dateRange = filters.global.dateRange;
        break;
      }
      case filters.global.timeframe !== undefined: {
        result.timeframe = filters.global.timeframe;
        break;
      }
      default: // No Code
    }
  }

  // Handle activation filters
  if (filters.audit) {
    if (filters.audit.username) result.username = filters.audit.username;
    if (filters.audit.filter_option)
      result.filter_option = filters.audit.filter_option;
    if (filters.audit.action_name)
      result.action_name = convertToNumberArray(filters.audit.action_name);
    if (filters.audit.type) result.type = filters.audit.type.split(',');
  }

  return result as IAuditListingParam;
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const AuditSettings = () => {
  document.title = 'Audit Trail - BranchLab';
  const filters = JSON.parse(localStorage.getItem('Filters') ?? '{}');
  const [isLoading, setIsLoading] = useState(false),
    [body, setBody] = useState<any>(null),
    [isMounted, setIsMounted] = useState(false),
    [auditData, setAuditData] = useState<any>(false),
    [isFilterModalOpen, setIsFilterModalOpen] = useState(false),
    [messageObj, setMessageObj] = useState<any>({ isShowing: false });

  const { context, destroyMessage } = useNotificationMessage(messageObj);

  const [auditListingParam, setAuditListingParam] =
    useState<IAuditListingParam>(
      Object.keys(filters).length > 0
        ? convertFiltersToNumberArray(filters)
        : baseListingParam
    );

  usePostApiInterceptor(
    isMounted,
    body,
    RoutesEnum.AUDIT_LISTING,
    (data: any, error: any) => {
      setIsLoading(false);
      setBody(null);
      setIsMounted(false);
      if (data && !error) {
        setAuditData(data);
        setBody(null);
      } else {
        updateErrorMessage(
          'error',
          'Error occurred while getting audit information.'
        );
        setAuditData(null);
      }
    }
  );

  const updateErrorMessage = (type: string, messagecontent: string) => {
    setMessageObj({
      key: 'save',
      isShowing: true,
      type,
      messagecontent,
      duration: 5,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    setBody({
      time_frame: auditListingParam.timeframe,
      end_date: auditListingParam.dateRange?.length
        ? auditListingParam.dateRange[1]
        : null,
      start_date: auditListingParam.dateRange?.length
        ? auditListingParam.dateRange[0]
        : null,
      title: auditListingParam.title ?? null,
      username: auditListingParam.username ?? null,
      action_id: auditListingParam.action_name?.length
        ? auditListingParam.action_name.toString()
        : null,
      type: auditListingParam.type?.length
        ? auditListingParam.type.toString()
        : null,
      brand_id: auditListingParam.brand?.length
        ? auditListingParam.brand.toString()
        : null,
      agency_id: auditListingParam.agency?.length
        ? auditListingParam.agency.toString()
        : null,
      advertiser_id: auditListingParam.advertiser?.length
        ? auditListingParam.advertiser.toString()
        : null,
      filter_option: auditListingParam.filter_option,
      order_column: auditListingParam.sortColumn,
      order_direction: auditListingParam.sortOrder,
      off_set:
        (auditListingParam.pageNumber - 1) * auditListingParam.rowsPerPage,
      limit: auditListingParam.rowsPerPage,
    });
    setIsMounted(true);
  }, [auditListingParam]);

  const columns: TableColumnsType<DataType> = [
    {
      title: 'Item',
      dataIndex: 'title',
      key: 'title',
      width: '150px',
      sorter: true,
      render: (text, record) => (
        <>
          {(record.action_name !== "Archive") ? (
            <NavLink
              to={(() => {
                switch (record.type) {
                  case 'Activation':
                    return '/audience/' + record.id + '/activate';
                  case 'Audience':
                    return '/audience/' + record.id + '/define';
                  case 'Pixel':
                    return '/update-pixel/' + record.id;
                  case 'Account':
                    return '/add-account/' + record.id;
                  default:
                    return '/update-research/' + record.id;
                }
              })()}
              title={record.title}
            >
              {record.title}
            </NavLink>
          ) : (
            <>{record.title}</>
          )}
        </>
      ),
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Date',
      dataIndex: 'updated_on',
      key: 'updated_on',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY H:mm A'),
      width: '100px',
      sorter: true,
      defaultSortOrder: 'descend',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'User',
      dataIndex: 'username',
      key: 'username',
      width: '100px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Item Type',
      dataIndex: 'type',
      key: 'type',
      width: '100px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Action',
      dataIndex: 'action_name',
      key: 'action_name',
      width: '50px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
  ];

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    destroyMessage('save');
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      auditListingParam;

    setAuditListingParam({
      ...auditListingParam,
      sortOrder:
        sorter?.order === undefined ? sortOrder : getSortOrder(sorter.order),
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    return null;
  };

  const applyFilters = (data: any) => {
    let filterData = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      status_id: data.status?.length ? data.status.toString() : null,
      audience_type: data.audienceType?.length
        ? data.audienceType.toString()
        : null,
      type: data.type?.length ? data.type.toString() : null,
      username: data.username,
      end_date: data.dateRange?.length ? data.dateRange[1] : null,
      start_date: data.dateRange?.length ? data.dateRange[0] : null,
      title: data.title,
      action_name: data.action_name ? data.action_name.toString() : null,
      filter_option: data.filter_option,
      limit: auditListingParam.rowsPerPage,
      timeframe: data.timeFrame,
      off_set:
        (auditListingParam.pageNumber - 1) * auditListingParam.rowsPerPage,
      order_column: auditListingParam.sortColumn,
      order_direction: auditListingParam.sortOrder,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') ?? '{}'),
      global: {
        agency: filterData.agency_id,
        advertiser: filterData.advertiser_id,
        brand: filterData.brand_id,
        dateRange:
          filterData.start_date && filterData.end_date
            ? [filterData.start_date, filterData.end_date]
            : null,
        timeframe: filterData.timeframe,
      },
      audit: {
        action_name: filterData.action_name,
        username: filterData.username,
        filter_option: filterData.filter_option,
        type: filterData.type,
      },
    };
    setAuditListingParam({
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
      username: data.username,
      action_name: data.action_name?.length ? data.action_name : null,
      title: data.title,
      filter_option: data.filter_option,
      rowsPerPage: baseListingParam.rowsPerPage,
      timeframe: data.timeframe ?? null,
      pageNumber: baseListingParam.pageNumber,
      sortColumn: baseListingParam.sortColumn,
      sortOrder: baseListingParam.sortOrder,
      dateRange: data.dateRange?.length
        ? [data.dateRange[0], data.dateRange[1]]
        : null,
      type: data.type?.length ? data.type : null,
    });
    setBody(filterData);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setIsFilterModalOpen(false);
  };

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <div>
      {context}
      <LeftPanel defaultSelectedKeys={['7', '74']} />
      <div className="audienceCommonWrap">
        <PageHeader title="Audit Trail" />
        <div className="audienceListingPageContent">
          <div className="filterWrap">
            <div className="filterBtnAndText">
              <ConfigProvider wave={{ disabled: true }}>
                <Tooltip title="Filter Results">
                <Button onClick={() => setIsFilterModalOpen(true)}>
                  <img src={filter} alt="" />
                </Button>
                </Tooltip>
              </ConfigProvider>
              {(auditListingParam.agency ||
                auditListingParam.advertiser ||
                auditListingParam.brand ||
                auditListingParam.timeframe ||
                auditListingParam.dateRange ||
                auditListingParam.type ||
                auditListingParam.action_name ||
                auditListingParam.username) && (
                <FilterMessage type="audit" data={auditListingParam} />
              )}
            </div>
            <GlobalFilterModal
              isOpen={isFilterModalOpen}
              filterData={auditListingParam}
              type="audit"
              handleOk={applyFilters}
              handleCancel={handleCancel}
            />
          </div>
          <Table
            columns={columns}
            dataSource={auditData?.rows ?? []}
            onChange={handleChange}
            className={`antTableElement audienceListTable${
              isLoading ? ' hideNoDataLabel' : ''
            }`}
            loading={isLoading}
            pagination={{
              current: auditListingParam.pageNumber,
              total: auditData?.total_row_count ?? 0,
              showSizeChanger: false,
              pageSize: auditListingParam.rowsPerPage,
            }}
            sortDirections={['ascend', 'descend', 'ascend']}
          />
        </div>
      </div>
    </div>
  );
};

export default AuditSettings;
