import { Button, Col, ConfigProvider, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { filter } from '../../../assets/images';
import { GlobalFilterModal, PageHeader } from '../../sharedComponent';
import AudienceChart from './AudienceChart';
import CommonAudienceTable from './CommonAudienceTable';
import CommonActivationTable from './CommonActivationTable';
import { usePostApiInterceptor, useRedirection } from '../../../hooks';
import { RoutesEnum } from '../../../models/enums/apiRoutes';
import { IDashboardListingParam } from '../../../models/interfaces/models.interface';
import { FilterMessage } from '../../sharedComponent/FilterMessage';
import ActivationChart from './ActivationChart';

type TActivationData = { date: string; total_activation: number };

export type AudienceData = {
  total_audience: number;
  dtc_count: number;
  hcp_count: number;
  building_percent: number;
  estimate_percent: number;
  activate_percent: number;
  recent_audience_created: any;
  recent_audience_build: any;
  recent_audience_30: any;
  isLoading: boolean;
};

export type ActivateData = {
  total_activation: number;
  recent_estimate: any;
  recent_activate: any;
  partner_percent: any;
  activate_data: TActivationData[];
  audience_member_data : any;
  isLoading: boolean;
};

const baseListingParam = {
  agency: null,
  brand: null,
  advertiser: null,
};

const convertToNumberArray = (value: string | null): number[] | null => {
  if (!value) return null;
  return value
    .split(',')
    .map(Number)
    .filter((n) => !isNaN(n));
};

const convertFiltersToNumberArray = (filters: any): IDashboardListingParam => {
  const result: Partial<IDashboardListingParam> = {};

  // Handle dashboard filters
  if (filters.dashboard) {
    if (filters.dashboard.agency)
      result.agency = convertToNumberArray(filters.dashboard.agency);
    if (filters.dashboard.advertiser)
      result.advertiser = convertToNumberArray(filters.dashboard.advertiser);
    if (filters.dashboard.brand)
      result.brand = convertToNumberArray(filters.dashboard.brand);
  }

  return result as IDashboardListingParam;
};

const DashboardContent = () => {
  const filters = JSON.parse(localStorage.getItem('Filters') ?? '{}');
  const [audienceBody, setAudienceBody] = useState<any>(null),
    [audienceIsMounted, setAudienceIsMounted] = useState(false),
    [activationBody, setActivationBody] = useState<any>(null),
    [activationIsMounted, setActivationIsMounted] = useState(false),
    [audienceData, setAudienceData] = useState<AudienceData>({
      total_audience: 0,
      dtc_count: 0,
      hcp_count: 0,
      building_percent: 0,
      estimate_percent: 0,
      activate_percent: 0,
      recent_audience_created: [],
      recent_audience_build: [],
      recent_audience_30: [],
      isLoading: true,
    }),
    [activationData, setActivationData] = useState<ActivateData>({
      total_activation: 0,
      recent_estimate: [],
      recent_activate: [],
      partner_percent: [],
      audience_member_data : [],
      activate_data: [],
      isLoading: true,
    }),
    [dashboardListingParam, setDashboardListingParam] =
      useState<IDashboardListingParam>(
        Object.keys(filters).length > 0
          ? convertFiltersToNumberArray(filters)
          : baseListingParam
      ),
    [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const {
    redirectAudienceSetup,
    redirectAudienceListing,
    redirectAudienceActivations,
  } = useRedirection();

  usePostApiInterceptor(
    audienceIsMounted,
    audienceBody,
    RoutesEnum.AUDIENCE_OVERVIEW,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      } else {
        setAudienceData((prev: any) => ({
          ...prev,
          total_audience: data.total_audience_count,
          dtc_count: data.dtc_count ?? 0,
          hcp_count: data.hcp_count ?? 0,
          building_percent: data.bulid_percentage ?? 0,
          estimate_percent: data.estimate_percentage ?? 0,
          activate_percent: data.activate_percentage ?? 0,
          recent_audience_created: data.recent_audience ?? [],
          recent_audience_build: data.recent_build ?? [],
          recent_audience_30: data.audience_data ?? [],
          isLoading: false,
        }));
      }
      setAudienceBody(null);
      setAudienceIsMounted(false);
    }
  );

  usePostApiInterceptor(
    activationIsMounted,
    activationBody,
    RoutesEnum.ACTIVATE_OVERVIEW,
    (data: any, error: any) => {
      if (error || !data) {
        return;
      } else {
        setActivationData((prev: any) => ({
          ...prev,
          total_activation: data.activation_count,
          recent_estimate: data.recent_estimate ?? [],
          recent_activate: data.recent_activate ?? [],
          partner_percent: data.partner_percentages ?? [],
          activate_data: data.activation_data ?? [],
          audience_member_data : data.audience_member_data ?? [],
          isLoading: false,
        }));
      }
      setActivationBody(null);
      setActivationIsMounted(false);
    }
  );

  const applyFilters = (data: any) => {
    let filterData = {
      agency_id: data.agency?.length ? data.agency.toString() : null,
      advertiser_id: data.advertiser?.length
        ? data.advertiser.toString()
        : null,
      brand_id: data.brand?.length ? data.brand.toString() : null,
      status_id: data.status?.length ? data.status.toString() : null,
    };
    let filterStorage = {
      ...JSON.parse(localStorage.getItem('Filters') ?? '{}'),
      dashboard: {
        agency: filterData.agency_id,
        advertiser: filterData.advertiser_id,
        brand: filterData.brand_id,
      },
    };
    setDashboardListingParam({
      agency: data.agency?.length ? data.agency : null,
      brand: data.brand?.length ? data.brand : null,
      advertiser: data.advertiser?.length ? data.advertiser : null,
    });
    setActivationBody(filterData);
    setAudienceBody(filterData);
    localStorage.setItem('Filters', JSON.stringify(filterStorage));
    setIsFilterModalOpen(false);
  };

  useEffect(() => {
    setAudienceBody({
      agency_id: dashboardListingParam.agency?.length
        ? dashboardListingParam.agency.toString()
        : null,
      advertiser_id: dashboardListingParam.advertiser?.length
        ? dashboardListingParam.advertiser.toString()
        : null,
      brand_id: dashboardListingParam.brand?.length
        ? dashboardListingParam.brand.toString()
        : null,
    });
    setAudienceIsMounted(true);
    setActivationBody({
      agency_id: dashboardListingParam.agency?.length
        ? dashboardListingParam.agency.toString()
        : null,
      advertiser_id: dashboardListingParam.advertiser?.length
        ? dashboardListingParam.advertiser.toString()
        : null,
      brand_id: dashboardListingParam.brand?.length
        ? dashboardListingParam.brand.toString()
        : null,
    });
    setActivationIsMounted(true);
    setAudienceData((prev: any) => ({
      ...prev,
      isLoading: true,
    }));
    setActivationData((prev: any) => ({
      ...prev,
      isLoading: true,
    }));
  }, [dashboardListingParam]);

  const handleCancel = () => {
    setIsFilterModalOpen(false);
  };

  return (
    <div className="audienceCommonWrap dashboardContent">
      <PageHeader title="Dashboard" />
      {!activationData.isLoading && !audienceData.isLoading && (
        <>
          <div className="filterWrap">
            <ConfigProvider wave={{ disabled: true }}>
              <Tooltip title="Filter Results">
                <Button onClick={() => setIsFilterModalOpen(true)}>
                  <img src={filter} alt="" />
                </Button>
              </Tooltip>
              {(dashboardListingParam.agency ||
                dashboardListingParam.advertiser ||
                dashboardListingParam.brand) && (
                <FilterMessage type="audience" data={dashboardListingParam} />
              )}
            </ConfigProvider>
            <GlobalFilterModal
              isOpen={isFilterModalOpen}
              filterData={dashboardListingParam}
              type="dashboard"
              handleOk={applyFilters}
              handleCancel={handleCancel}
            />
          </div>
          <div>
            <Row gutter={20} className='pieChartRow'>
              <Col span={12}>
                <div className="aboveTableAction dashboardCTAWrap">
                  <p>Audiences</p>
                  <div className="labelAndCTA">
                    <button
                      className="noBorderBtn"
                      onClick={redirectAudienceListing}
                    >
                      View All
                    </button>
                    <button
                      className="primaryBtn"
                      onClick={() => redirectAudienceSetup()}
                    >
                      New
                    </button>
                  </div>
                </div>
                {!audienceData.isLoading && (
                  <AudienceChart audienceData={audienceData} />
                )}
              </Col>
              <Col span={12}>
                <div className="aboveTableAction dashboardCTAWrap">
                  <p>Activations</p>
                  <div className="labelAndCTA">
                    <button
                      className="noBorderBtn"
                      onClick={redirectAudienceActivations}
                    >
                      View All
                    </button>
                  </div>
                </div>
                {!activationData.isLoading && (
                  <ActivationChart activationData={activationData} />
                )}
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <CommonAudienceTable
                  heading="Recently Created"
                  isBuild={false}
                  audienceData={audienceData}
                />
              </Col>
              <Col span={12}>
                <CommonActivationTable
                  is_activate={true}
                  heading="Recent Activations"
                  activationData={activationData}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <CommonAudienceTable
                  heading="Ready to be Estimated"
                  audienceData={audienceData}
                  isBuild={true}
                />
              </Col>
              <Col span={12}>
                <CommonActivationTable
                  is_activate={false}
                  heading="Ready to be Activated"
                  activationData={activationData}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardContent;
