import React, { FC, Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Dropdown,
  Menu,
  MenuProps,
  Table,
  type TableColumnsType,
  type TableProps,
} from 'antd';
import { SortOrder } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import {
  IAudienceListingParam,
  IDebounceDropdownElements,
  IEachDropdownElementsType,
  ILoaderState,
  IMasterAdditionalOptionDDState,
  IMasterAdvertiserDDState,
  IMasterAgencyDDState,
  IMasterAudienceTypeDDState,
  IMasterBrandDDState,
  IMasterGenderDDState,
  IMasterInsurancePlanProviderDDState,
  IMasterInsurancePlanTypeDDState,
  IMasterLanguageDDState,
  IMasterOperatorDDState,
  IMasterProviderSpecialtyDDState,
  IMasterRaceAndEthnicityDDState,
  IMasterStateDDState,
  IMasterStatusDDState,
  IMasterTimeFrameDDState,
  IUserInformationState,
} from '../../../../models/interfaces';
import {
  useNotificationMessage,
  usePostApiInterceptor,
  useRedirection,
} from '../../../../hooks';
import { RoutesEnum } from '../../../../models/enums/apiRoutes';
import {
  useLoaderStore,
  useMasterStatusElementsStore,
  useMasterAudienceTypeElementsStore,
  useMasterGenderElementsStore,
  useMasterRaceAndEthnicityElementsStore,
  useMasterOperatorElementsStore,
  useMasterStateElementsStore,
  useMasterInsurancePlanTypeElementsStore,
  useMasterTimeFrameElementsStore,
  useMasterAdditionalOptionElementsStore,
  useMasterProviderSpecialtyElementsStore,
  useMasterInsurancePlanProviderElementsStore,
  useUserInformationStore,
} from '../../../../stores';
import { moreIconVertical } from '../../../../assets/images';
import { RbacSettings } from '../../../../models/enums/rbacSetting';
import {
  useMasterAdvertiserElementStore,
  useMasterAgencyElementStore,
  useMasterBrandElementStore,
  useMasterLanguageElementStore,
} from '../../../../stores/masterData.store';
import careGiverIcon from '../../../../assets/images/caregiver.svg';
import { EventType } from '@testing-library/react';

type OnChange = NonNullable<TableProps<DataType>['onChange']>;
type OperationType = 'edit' | 'delete' | 'download' | 'archive';

interface IBody {
  audience_id: number;
  is_archive?: number;
}
export interface DataType {
  key: number;
  title: string;
  status: string;
  audience_type: string;
  audience_id: number;
  created_on: string;
  updated_on: string;
  additional_option_id: boolean;
  archived: number;
}

const messageContents = {
  edit: 'Error occurred while fetching Audience Information',
  delete: 'Error occurred while deleting Audience Information',
  download: 'Error occurred while downloading Audience Information',
  clone: 'Error occurred while cloning Audience Information',
};

const defineItemsArchived: MenuProps['items'] = [
  {
    key: '1',
    label: 'Export',
  },
  {
    key: '2',
    label: 'Duplicate',
  },
  {
    key: '3',
    label: 'View',
  },
  {
    key: '4',
    label: 'Archive',
  },
];

const defineItemsUnArchived: MenuProps['items'] = [
  {
    key: '1',
    label: 'Unarchive',
  },
];

const activatedItems: MenuProps['items'] = [
  {
    key: '1',
    label: 'Activate',
  },
  {
    key: '2',
    label: 'Export',
  },
  {
    key: '3',
    label: 'Duplicate',
  },
  {
    key: '4',
    label: 'View',
  },
  {
    key: '5',
    label: 'Insights',
  },
];

const estimatedItemsArchived: MenuProps['items'] = [
  {
    key: '1',
    label: 'Activate',
  },
  {
    key: '2',
    label: 'Export',
  },
  {
    key: '3',
    label: 'Duplicate',
  },
  {
    key: '4',
    label: 'View',
  },
  {
    key: '5',
    label: 'Insights',
  },
  {
    key: '6',
    label: 'Archive',
  },
];

const estimatedItemsUnArchived: MenuProps['items'] = [
  {
    key: '1',
    label: 'Unarchive',
  },
];

const items2: MenuProps['items'] = [
  {
    key: '1',
    label: 'Export',
  },
  {
    key: '2',
    label: 'Duplicate',
  },
  {
    key: '3',
    label: 'Delete',
  },
  {
    key: '4',
    label: 'View',
  },
];

const ListingTable: FC<{
  audData: any;
  isLoading: boolean;
  checkedList: string[];
  paginationAndSortingData: IAudienceListingParam;
  setPaginationAndSortingData: React.Dispatch<
    React.SetStateAction<IAudienceListingParam>
  >;
}> = ({
  audData,
  isLoading,
  checkedList,
  paginationAndSortingData,
  setPaginationAndSortingData,
}) => {
  const [typeOfOperation, setTypeOfOperation] = useState<OperationType>('edit'),
    [body, setBody] = useState<IBody | null>(null),
    [isMounted, setIsMounted] = useState<boolean>(false),
    [endPoint, setEndPoint] = useState<string>(''),
    [notificationObj, setNotificationObj] = useState<any>({ isShowing: false });

  const { setShowPageLoaderState } = useLoaderStore(
      (state: ILoaderState) => state
    ),
    { audienceTypeDDElements } = useMasterAudienceTypeElementsStore(
      (state: IMasterAudienceTypeDDState) => state
    ),
    { agencyDDElements } = useMasterAgencyElementStore(
      (state: IMasterAgencyDDState) => state
    ),
    { brandDDElements } = useMasterBrandElementStore(
      (state: IMasterBrandDDState) => state
    ),
    { advertiserDDElements } = useMasterAdvertiserElementStore(
      (state: IMasterAdvertiserDDState) => state
    ),
    { statusDDElements } = useMasterStatusElementsStore(
      (state: IMasterStatusDDState) => state
    ),
    { genderDDElements } = useMasterGenderElementsStore(
      (state: IMasterGenderDDState) => state
    ),
    { raceAndEthnicityDDElements } = useMasterRaceAndEthnicityElementsStore(
      (state: IMasterRaceAndEthnicityDDState) => state
    ),
    { operatorDDElements } = useMasterOperatorElementsStore(
      (state: IMasterOperatorDDState) => state
    ),
    { stateDDElements } = useMasterStateElementsStore(
      (state: IMasterStateDDState) => state
    ),
    { insurancePlanTypeDDElements } = useMasterInsurancePlanTypeElementsStore(
      (state: IMasterInsurancePlanTypeDDState) => state
    ),
    { timeFrameDDElements } = useMasterTimeFrameElementsStore(
      (state: IMasterTimeFrameDDState) => state
    ),
    { additionalOptionDDElements } = useMasterAdditionalOptionElementsStore(
      (state: IMasterAdditionalOptionDDState) => state
    ),
    { providerSpecialtyDDElements } = useMasterProviderSpecialtyElementsStore(
      (state: IMasterProviderSpecialtyDDState) => state
    ),
    { privilegeSetDDElements } = useUserInformationStore(
      (state: IUserInformationState) => state
    ),
    { languageDDElements } = useMasterLanguageElementStore(
      (state: IMasterLanguageDDState) => state
    ),
    { insurancePlanProviderDDElements } =
      useMasterInsurancePlanProviderElementsStore(
        (state: IMasterInsurancePlanProviderDDState) => state
      );

  const {
    redirectAudienceSetup,
    redirectAudienceActivationSetup,
    redirectAudienceView,
    redirectAudienceInsights,
  } = useRedirection();

  usePostApiInterceptor(isMounted, body, endPoint, (data: any, error: any) => {
    setIsMounted(false);
    setNotificationObj({ isShowing: false });
    if (typeOfOperation === 'download' && !error && data) {
      handleXLSXDownload(data.audience_info);
    } else if (typeOfOperation === 'delete' && !error) {
      if (audData?.rows.length === 1) {
        setPaginationAndSortingData({
          ...paginationAndSortingData,
          pageNumber:
            paginationAndSortingData.pageNumber === 1
              ? 1
              : paginationAndSortingData.pageNumber - 1,
        });
      } else {
        setPaginationAndSortingData({ ...paginationAndSortingData });
      }
      setShowPageLoaderState(false);
    } else if (typeOfOperation === 'archive' && !error) {
      setNotificationObj({
        isShowing: true,
        type: 'success',
        key: 'archive',
        messagecontent: `Audience ${
          body?.is_archive ? 'archived' : 'unarchived'
        } successfully`,
      });
      setShowPageLoaderState(false);
    }else {
      updateErrorMessage(typeOfOperation);
      setShowPageLoaderState(false);
    }
    setPaginationAndSortingData({ ...paginationAndSortingData });
  });

  const { context, destroyMessage } = useNotificationMessage(notificationObj);

  const handleChange: OnChange = (pagination, filter, sorter: any) => {
    const { sortOrder, sortColumn, pageNumber, rowsPerPage } =
      paginationAndSortingData;
    setPaginationAndSortingData({
      ...paginationAndSortingData,
      sortOrder:
      sorter ? getSortOrder(sorter.order) : sortOrder,
      sortColumn: sorter?.columnKey ?? sortColumn,
      pageNumber: pagination?.current ?? pageNumber,
      rowsPerPage: pagination?.pageSize ?? rowsPerPage,
    });
  };

  const getSortOrder = (order: SortOrder) => {
    if (order === 'ascend') return 'ASC';
    if (order === 'descend') return 'DESC';
    else return order
  };

  const getStatusOptionClassName = (value: string) => {
    return statusDDElements.find((eachOption) => eachOption.value === value)
      ?.className;
  };

  const getStatusColumnValueJsx = (value: string) => {
    switch (value) {
      case 'Building':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#FFF598', color: '#645A00' }}
          >
            {value}
          </span>
        );
      case 'Estimated':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#F0F9FF', color: '#026AA2' }}
          >
            {value}
          </span>
        );
      case 'Activated':
        return (
          <span
            className={`statusChip ${getStatusOptionClassName(
              value?.toLowerCase()
            )}`}
            style={{ backgroundColor: '#ECFDF3', color: '#2D912B' }}
          >
            {value}
          </span>
        );
    }
  };

  const editOrDeleteFeatureSets = (
    audienceId: number,
    type: OperationType = 'edit'
  ) => {
    if (type === 'edit') {
      redirectAudienceSetup(audienceId);
      return;
    }
    setNotificationObj({ isShowing: false });
    destroyMessage(typeOfOperation);
    setShowPageLoaderState(true);
    setTypeOfOperation(type);
    if (type === 'download') {
      setEndPoint(RoutesEnum.GET_FEATURE_SET_DATA);
      setBody({ audience_id: audienceId });
    } else {
      setEndPoint(RoutesEnum.DELETE_FEATURE_SET_DATA);
      setBody({
        audience_id: audienceId,
      });
    }
    setIsMounted(true);
  };

  const handleXLSXDownload = (audienceInfo: any) => {
    const tempData: any = { ...audienceInfo },
      data: any = [];
    for (const key in tempData) {
      switch (key) {
        case 'title': {
          data.push([handler[key], tempData[key]]);
          break;
        }
        case 'status_id': {
          const formattedSelectedElements = statusDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'agency_id': {
          const formattedSelectedElements = agencyDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'advertiser_id': {
          const formattedSelectedElements = advertiserDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'brand_id': {
          const formattedSelectedElements = brandDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'audience_type_id': {
          const formattedSelectedElements = audienceTypeDDElements.find(
            (fe: IEachDropdownElementsType) => fe.value === tempData[key]
          )?.label;

          data.push([handler[key], formattedSelectedElements]);
          break;
        }
        case 'additional_option_id': {
          let formattedSelectedElements: string = '';
          if (tempData[key]) {
            formattedSelectedElements = additionalOptionDDElements[0].label;
            data.push([handler[key], formattedSelectedElements]);
          }
          break;
        }
        case 'ai_summary': {
          data.push([handler[key], tempData[key]]);
          break;
        }
        case 'pixel_name': {
          if (tempData[key]) {
            data.push([handler[key], tempData[key]]);
          }
          break;
        }
        case 'created_on': {
          data.unshift([
            handler[key],
            dayjs(tempData[key]).format('MM/DD/YYYY'),
          ]);
          break;
        }
        case 'feature_sets': {
          if (tempData[key]?.length) {
            data.push([]);
            const featureSetData = tempData[key].sort((a: any, b: any) => {
              // Handle caregiver priority (should be first)
              if (a.is_caregiver && !b.is_caregiver) return -1;
              if (!a.is_caregiver && b.is_caregiver) return 1;

              // Handle HCP priority (should be second)
              if (a.is_hcp && !b.is_hcp) return -1;
              if (!a.is_hcp && b.is_hcp) return 1;

              // Handle global priority (should be third)
              if (a.is_global && !b.is_global) return -1;
              if (!a.is_global && b.is_global) return 1;

              // If none of the special cases match, fall back to feature_set_rank
              return a.feature_set_rank - b.feature_set_rank;
            });

            data.push(
              ...formatFeatureSetData(
                featureSetData,
                genderDDElements,
                raceAndEthnicityDDElements,
                operatorDDElements,
                stateDDElements,
                insurancePlanTypeDDElements,
                timeFrameDDElements,
                additionalOptionDDElements,
                providerSpecialtyDDElements,
                insurancePlanProviderDDElements,
                languageDDElements
              )
            );
          }
          break;
        }
        default:
        // none
      }
    }

    setShowPageLoaderState(false);

    const wb = XLSX.utils.book_new(),
      ws = XLSX.utils.aoa_to_sheet(data);

    data.forEach((el: any, i: number) => {
      if (el?.length && el[0] === 'Description:') {
        ws['!merges'] = ws['!merges'] || [];
        ws['!merges'].push({ s: { r: i, c: 1 }, e: { r: i, c: 3 } });
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const timestamp = Date.now();
    XLSX.writeFile(
      wb,
      `${audienceInfo.title}_${audienceInfo.audience_id}_${dayjs(
        timestamp
      ).format('YYYY-MM-DD HH:mm')}.xlsx`
    );
  };

  const createDuplicateAudience = (audience_id: number) => {
    setNotificationObj({ isShowing: false });
    setShowPageLoaderState(true);

    setEndPoint(RoutesEnum.DUPLICATE_AUDIENCE);
    setBody({ audience_id });
    setNotificationObj({
      isShowing: true,
      type: 'success',
      messagecontent: 'Audience Duplicated',
    });
    setIsMounted(true);
  };

  const archiveAudience = (audience_id: number, archived: number) => {
    setNotificationObj({ isShowing: false });
    destroyMessage('archive')
    setShowPageLoaderState(true);
    setEndPoint(RoutesEnum.ARCHIVE_AUDIENCE);
    setBody({ audience_id: audience_id, is_archive: archived });
    setTypeOfOperation('archive')
    setIsMounted(true);
  };

  const handleActions = (e: any, data: any) => {
    if (e.key === '1' && data.archived === 1) {
      archiveAudience(data.key, 0);
    } else if (e.key === '1' && data.archived !== 1) {
      editOrDeleteFeatureSets(data.key, 'download');
    }else if (e.key === '2') {
      createDuplicateAudience(data.key);
    } else if (e.key === '3') {
      redirectAudienceView(data.key);
    } else if (data.archived === 1) {
      archiveAudience(data.key, 0);
    } else if (data.archived !== 1) {
      archiveAudience(data.key, 1);
    }
  };

  const handleActivateActions = (e: any, data: any) => {
    if (e.key === '1') {
      redirectAudienceActivationSetup(data.key);
    } else if (e.key === '2') {
      editOrDeleteFeatureSets(data.key, 'download');
    } else if (e.key === '3') {
      createDuplicateAudience(data.key);
    } else if (e.key === '4') {
      redirectAudienceView(data.key);
    } else {
      redirectAudienceInsights(data.key);
    }
  };

  const handleEstimatedActions = (e: any, data: any) => {
    if (e.key === '1' && data.archived === 1) {
      archiveAudience(data.key, 0);
    } else if (e.key === '1' && data.archived !== 1) {
      redirectAudienceActivationSetup(data.key);
    }else if (e.key === '2') {
      editOrDeleteFeatureSets(data.key, 'download');
    } else if (e.key === '3') {
      createDuplicateAudience(data.key);
    } else if (e.key === '4') {
      redirectAudienceView(data.key);
    } else if (e.key === '5') {
      redirectAudienceInsights(data.key);
    } else if (data.archived === 1) {
      archiveAudience(data.key, 0);
    } else if (data.archived !== 1) {
      archiveAudience(data.key, 1);
    }
  };

  const getMenuItems = (record: DataType) => {
    if (!privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD)) {
      return items2;
    }

    const statusItemsMap: any = {
      Activated: activatedItems,
      Estimated:
        record.archived === 1
          ? estimatedItemsUnArchived
          : estimatedItemsArchived,
    };

    return (
      statusItemsMap[record.status] ||
      (record.archived === 1 ? defineItemsUnArchived : defineItemsArchived)
    );
  };

  const handleAction = (e: any, record: DataType) => {
    if (!privilegeSetDDElements.includes(RbacSettings.ACTIVATION_ADD)) {
      return handleActions(e, record);
    }

    const actionHandlers: Record<
      string,
      (e: EventType, record: DataType) => void
    > = {
      Activated: handleActivateActions,
      Estimated: handleEstimatedActions,
    };
    return (actionHandlers[record.status] || handleActions)(e, record);
  };

  const columns: TableColumnsType<DataType> = [
    {
      title: 'ID',
      dataIndex: 'key',
      key: 'key',
      render: (text) => <>{'#' + text}</>,
      className: 'genericTableID',
      width: '100px',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <>
          {paginationAndSortingData.is_archived && record.archived ? (
            <p className="archivedText">[ARCHIVED] </p>
          ) : (
            ''
          )}
          <NavLink
            className={`textHyperLink ${
              record.archived === 1 ? 'disabledElement' : ''
            }`}
            to={record.archived === 1 ? '#' : `/audience/${record.key}/define`}
            title={text}
            onClick={(e) => record.archived === 1 && e.preventDefault()}
          >
            {text}
          </NavLink>
        </>
      ),
      width: '250px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Agency',
      dataIndex: 'agency',
      key: 'agency',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Advertiser',
      dataIndex: 'advertiser',
      key: 'advertiser',
      width: 'auto',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      width: '130px',
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => getStatusColumnValueJsx(text),
      width: '150px',
      sorter: true,
      showSorterTooltip: false,
    },
    {
      title: 'Type',
      dataIndex: 'audience_type',
      key: 'audience_type',
      width: 'auto',
      render: (text, record) => (
        <div className="audience_type">
          {text}
          {record.additional_option_id && (
            <img
              title="Caregiver"
              className="careGiverIcon"
              src={careGiverIcon}
              alt=""
            />
          )}
        </div>
      ),
      sorter: true,
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Created',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => dayjs.utc(text).local().format('M/D/YYYY'),
      width: 'auto',
      sorter: true,
      defaultSortOrder: 'descend',
      ellipsis: true,
      showSorterTooltip: false,
    },
    {
      title: 'Modified',
      dataIndex: 'updated_on',
      key: 'updated_on',
      render: (text) => (
        <>{text ? dayjs.utc(text).local().format('M/D/YYYY HH:mm') : '---'}</>
      ),
      width: 'auto',
      sorter: true,
      ellipsis: true,
      // sortDirections:['ascend'],
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      width: '50px',
      sorter: false,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Dropdown
            trigger={['click']}
            menu={{
              items: getMenuItems(record),
              onClick: (e) => handleAction(e, record),
            }}
            placement="bottomRight"
            overlayClassName="actionMenu"
          >
            <img src={moreIconVertical} className="actionMenuIcon" alt="more" />
          </Dropdown>
        );
      },
    },
  ];

  const updateErrorMessage = (messageKey: OperationType) => {
    setNotificationObj({
      key: messageKey,
      isShowing: true,
      type: 'error',
      messagecontent: messageContents[messageKey],
    });
  };

  function extractParenthesesContent(inputString: string) {
    const start = inputString.indexOf('(');
    const end = inputString.indexOf(')');
    return start !== -1 && end !== -1
      ? inputString.slice(start + 1, end)
      : inputString;
  }

  function manipulateDataArray(dataArray: any) {
    dataArray?.forEach((item: any) => {
      item.audience_type = extractParenthesesContent(item.audience_type);
    });
    return dataArray;
  }

  function CreateNewColumns() {
    let newColumns = columns.map((item) => ({
      ...item,
      hidden: !checkedList.includes(item.key as string),
      defaultSortOrder:
        item.key === paginationAndSortingData.sortColumn
          ? (paginationAndSortingData.sortOrder as SortOrder)
          : undefined,
    }));
    return checkedList.length > 0 ? newColumns : columns;
  }

  return (
    <Fragment>
      {context}
      <Table
        columns={CreateNewColumns()}
        dataSource={manipulateDataArray(audData?.rows) ?? []}
        onChange={handleChange}
        className={`antTableElement audienceListTable${
          isLoading ? ' hideNoDataLabel' : ''
        }`}
        pagination={{
          current: paginationAndSortingData.pageNumber,
          total: audData?.total_row_count ?? 0,
          showSizeChanger: true,
          pageSize: paginationAndSortingData.rowsPerPage,
          locale: {
            items_per_page: 'per page',
          },
        }}
        loading={isLoading}
        sortDirections={['descend', 'ascend']}
      />
    </Fragment>
  );
};

export default ListingTable;

const handler: any = {
  created_on: 'Date:',
  title: 'Title:',
  status_id: 'Status:',
  advertiser_id: 'Advertiser:',
  agency_id: 'Agency:',
  brand_id: 'Brand:',
  audience_type_id: 'Audience Type:',
  feature_set_rank: 'FeatureSet',
  condition_id: 'OPERATOR',
  pixel_name: 'Pixel',
  additional_option_id: 'Additional Options:',
  ai_summary: 'Description:',
  1: 'Timeframe',
  2: 'Patient Gender',
  3: 'Patient Age',
  4: 'Race & Ethnicity',
  5: 'State',
  6: 'Provider Specialty',
  7: 'Custom NPI List',
  8: 'Hospital',
  9: 'Diagnosis',
  10: 'Drug',
  11: 'Procedure',
  12: 'Payer Type',
  13: 'Additional Option',
  14: 'Additional Information',
  15: 'DMA',
  16: 'Insurance Provider',
  18: 'Generic Drug',
  19: 'Language',
  20: 'Provider Taxonomy',
  21: 'Caregiver Gender',
  22: 'Caregiver Age',
};

const formatFeatureSetData = (
  fsd: any,
  gd: IEachDropdownElementsType[],
  rad: IEachDropdownElementsType[],
  od: IEachDropdownElementsType[],
  sd: IEachDropdownElementsType[],
  ipd: IEachDropdownElementsType[],
  tfd: IEachDropdownElementsType[],
  aod: IEachDropdownElementsType[],
  psd: IEachDropdownElementsType[],
  ip: IEachDropdownElementsType[],
  ld: IEachDropdownElementsType[]
) => {
  const data: any = [];
  let featureCount = 0;
  fsd.forEach((el: any) => {
    if (el['condition_id'] && featureCount !== 0) {
      const formattedSelectedElements = od.find(
        (fe: IEachDropdownElementsType) => fe.value === el['condition_id']
      )?.label;

      el['condition_id'] &&
        data.push([], [handler['condition_id'], formattedSelectedElements]);
    }

    for (const featureKey in el) {
      if (featureCount === 0) {
        featureCount += 1;
      }
      switch (featureKey) {
        case 'feature_set_rank': {
          // Helper function to determine profile type
          const getProfileType = (element: any) => {
            if (element.is_global) return 'Patient Profile';
            if (element.is_caregiver) return 'Caregivers Profile';
            if (element.is_hcp) return 'Healthcare Professional Attributes';
            return element[featureKey];
          };

          data.push([], [handler[featureKey], getProfileType(el)]);
          break;
        }
        case 'targeting': {
          const targetingDataSet = el[featureKey].sort(
            (a: any, b: any) => a.targeting_id - b.targeting_id
          );
          data.push(['Features']);

          data.push(
            ...formatTargetingDataSet(
              targetingDataSet,
              gd,
              rad,
              sd,
              ipd,
              tfd,
              aod,
              psd,
              ip,
              ld
            )
          );
          break;
        }
        default:
        // None
      }
    }
  });

  return data;
};

const formatTargetingDataSet = (
  td: any,
  gd: IEachDropdownElementsType[],
  rad: IEachDropdownElementsType[],
  sd: IEachDropdownElementsType[],
  ipd: IEachDropdownElementsType[],
  tfd: IEachDropdownElementsType[],
  aod: IEachDropdownElementsType[],
  psd: IEachDropdownElementsType[],
  ip: IEachDropdownElementsType[],
  ld: IEachDropdownElementsType[]
) => {
  const sortedTd = td.sort(
      (a: any, b: any) => a.targeting_set_rank - b.targeting_set_rank
    ),
    rearrangedTd = getRearrangedArray(sortedTd);

  let data: any = [];

  rearrangedTd.forEach((el: any) => {
    switch (el.targeting_id) {
      case 1: {
        const formattedSelectedElements = tfd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          Number(el.targeting_value) === 3
            ? ['', handler[`${el.targeting_id}`]]
            : ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 21:
      case 2: {
        const formattedSelectedElements = gd.find(
          (fe: IEachDropdownElementsType) =>
            fe.value === Number(el.targeting_value)
        )?.label;

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 22:
      case 3: {
        data = [
          ...data,
          [
            '',
            handler[`${el.targeting_id}`],
            JSON.parse(el.targeting_value).join(' to '),
          ],
        ];
        break;
      }
      case 4: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                rad.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 5: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                sd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', 'Geography'],
          ['', '', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 7: {
        data = [
          ...data,
          [
            '',
            handler[`${el.targeting_id}`],
            JSON.parse(el.targeting_value)
              .map((el: any) => el.displayName)
              .join(', '),
          ],
        ];
        break;
      }
      case 6: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                psd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 9:
      case 10:
      case 18:
      case 20:
      case 11: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: IDebounceDropdownElements) =>
                [String(el.value)?.split('~')[0]] +
                ': ' +
                String(el.label)?.replaceAll('~', ' ')
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 12: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                ipd.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 13: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                aod.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 14: {
        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], el.targeting_value],
        ];
        break;
      }
      case 15: {
        let newDmsList: IEachDropdownElementsType[] = [];
        sd.forEach((el: any) => {
          newDmsList = [...newDmsList, ...el.dma];
        });

        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                newDmsList.find(
                  (fe: IEachDropdownElementsType) => fe.value === el
                )?.label
            )
            .join(', ');

        if (!rearrangedTd.find((el: any) => el.targeting_id === 5)) {
          data = [
            ...data,
            ['', 'Geography'],
            ['', '', handler[`${el.targeting_id}`], formattedSelectedElements],
          ];
        } else {
          data = [
            ...data,
            ['', '', handler[`${el.targeting_id}`], formattedSelectedElements],
          ];
        }
        break;
      }
      case 16: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                ip.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      case 17: {
        const tempDateRange = JSON.parse(el.targeting_value),
          formattedDateRange = [
            dayjs(tempDateRange[0]).format('MM/DD/YYYY'),
            dayjs(tempDateRange[1]).format('MM/DD/YYYY'),
          ];

        data[data.length - 1] = [
          ...data[data.length - 1],
          formattedDateRange.join(' to '),
        ];
        break;
      }
      case 19: {
        const selectedElement = JSON.parse(el.targeting_value),
          formattedSelectedElements = selectedElement
            .map(
              (el: number) =>
                ld.find((fe: IEachDropdownElementsType) => fe.value === el)
                  ?.label
            )
            .join(', ');

        data = [
          ...data,
          ['', handler[`${el.targeting_id}`], formattedSelectedElements],
        ];
        break;
      }
      default:
      // none
    }
  });

  return data;
};

const getRearrangedArray = (td: any) => {
  const tempTd = [...td],
    index5 = tempTd.findIndex((el: any) => el.targeting_id === 5),
    index15 = tempTd.findIndex((el: any) => el.targeting_id === 15);

  if (index5 !== -1 && index15 !== -1 && index5 !== index15 - 1) {
    if (index5 < index15) {
      const [item15] = tempTd.splice(index15, 1);
      tempTd.splice(index5 + 1, 0, item15);
    } else {
      const [item5] = tempTd.splice(index5, 1);
      tempTd.splice(index15, 0, item5);
    }
  }

  return tempTd;
};
