import React, { FC, useEffect, useRef } from 'react';
import anychart from 'anychart';

// Define the shape of the data item
interface DonutChartDataItem {
  x: string;  // Category or label
  value: number;  // Numerical value
}

// Props interface for type safety
interface DonutPieChartProps {
  data: any;
  heading: string;
}

export const DonutPieChart: FC<DonutPieChartProps> = ({ data, heading }) => {
  const chartContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartContainer.current) {
      // Proceed with chart creation if data is valid
      const chart = anychart.pie(data);

      const tooltip = chart.tooltip();

      tooltip.format(function (this) {
        // Type assertion for tooltip context
        const context = this as { value: number };
        return `${context.value.toFixed(1)}%`;
      });

      tooltip.background({
        fill: '#272727',
      });

      chart.innerRadius('75%');
      chart.labels(false);
      chart.explode(0);

      // Predefined color palette
      const COLOR_PALETTE = [
        '#1B58D0',
        '#9AB9F6',
        '#42824F',
        '#B1D1B7',
        '#620093',
        '#C29DD2',
        '#890189',
        '#FFCCFF',
        '#588C00',
        '#C1F666',
      ];
      chart.palette(COLOR_PALETTE);

      chart.interactivity().selectionMode(false);
      chart.background().enabled(false);

      // Configure legend
      chart.legend(true);
      chart
        .legend()
        .position('right')
        .itemsLayout('vertical')
        .align('center')
        .itemsFormatter((items) => 
          items.map((item) => ({
            text: item.text,
            iconType: item.iconType,
            iconStroke: item.iconStroke,
            iconFill: item.iconFill,
          }))
        );

      chart.legend().fontColor('#555552');
      chart.legend().title().enabled(false);
      chart.legend().paginator().enabled(false);

      chart.padding().bottom(50);

      chart.container(chartContainer.current);
      chart.draw();

      // Cleanup function
      return () => {
        chart.dispose();
      };
    }
  }, [data]);

  return (
    <div className="chartWrapper genericChartWrap" style={{ height: '100%' }}>
      <p>{heading}</p>
      <div ref={chartContainer} style={{ height: '100%' }} />
    </div>
  );
};